import { Fragment, Component } from "react";
import Navbar from "./static/navbar/navbarMui";
import Footer from "./static/footer";
import Review from "./static/Review";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import GuestDetails from "./static/GuestDetails";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import { LinearProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { _serverurl } from "../keys/URLS";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckOutSteps from "./static/checkoutSteps";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import tick from "../assets/tick.png";
// import { Snackbar, IconButton } from "@material-ui/core";
import Logo from "../assets/logo.png";
import Booking from "./static/booking";
import { pdfsenderndownload } from "./static/renderpdf";
import { Snackbar, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { Divider } from "@material-ui/core";
// import {
//   date,
//   update_available,
//   mixedGuestDetails,
//   femaleGuestDetails,
//   mixedTempDetails,
//   femaleTempDetails,
//   booking
// } from "../actions";
import * as actions from "../actions";
import { connect } from "react-redux";
import axios from "../apis/axios";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserContext } from "../providers/UserProvider";
import { auth } from "../firebase";
// import history from "../helpers/history";
import { _razor_key } from "../keys/keys";

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

let user,
  userdata = {
    name: "",
    email: "",
    contact: "",
  };
class Summary extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      mixed_count: 0,
      //female_count: 0,
      mixed_temp_details: [],
      //female_temp_details: [],
      initial_mixed_details: [],
      //initial_female_details: [],
      coupon_code: "",
      coupon_discount: 0,
      partial: false,
      partial_amount: 0,
      redirect: false,
      popup: false,
      booking_id: "",
      pnr: "",
      open: false,
      open2: false,
      onprocess: false,
      openEdit: false,
      currency: "INR",
      snackmessage: "",
      opensnack: false,
      severity: "error",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePay = this.handlePay.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClose2 = this.handleClose2.bind(this);
  }
  onsnackClose = () => {
    this.setState({
      snackmessage: "",
      opensnack: false,
      severity: "error",
    });
  };
  onDownloadReciept = async (data) => {
    const res = await pdfsenderndownload("email", data, null);
    if (res === "success") {
      this.setState({
        snackmessage: "You will recieve Reciept shortly in your mail",
        opensnack: true,
      });
    }
  };

  handleClickOpen(event) {
    this.setState({ open: true });
  }

  handleClose(event) {
    this.props.history.push("/");
    this.setState({ open: false, open2: true });
  }

  handleClickOpenEdit = () => {
    this.setState({ openEdit: true });
  };

  handleCloseEdit = () => {
    this.setState({ openEdit: false });
  };

  handleClose2(event) {
    this.props.history.push("/booknow");
    // if(auth.currentUser===null){
    //   this.props.history.push("/login")
    //   return
    // }
    // else{
    //   if(auth.currentUser.displayName===null){
    //     this.props.history.push("/setupprofile/"+auth.currentUser.uid)
    //     return
    //   }
    // }
    this.setState({ open: false, open2: true });
  }

  componentDidMount() {
    axios.get("/booking/partial").then((res) => {
      let d1 = new Date(localStorage.getItem("check_in"));
      let d2 = new Date();
      let diff = Math.abs(d2 - d1);
      let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (diffDays >= res.data.days) {
        // console.log("insid");
        this.setState({ partial: true, partial_amount: res.data.payPercent });
      }
    });
    if (
      this.props.mixed_guest_details.length === 0 //&&
      //this.props.female_guest_details.length === 0
    ) {
      this.props.history.push("/booknow");
    }
    let temp = [];
    for (let i = 0; i < this.props.mixed_guest_details.length; i++) {
      temp.push(this.props.mixed_guest_details[i]);
    }
    this.setState({
      mixed_temp_details: temp,
      mixed_count: this.props.mixed_guest_details.length,
      initial_mixed_details: temp,
    });

    temp = [];
    // for (let i = 0; i < this.props.female_guest_details.length; i++) {
    //   temp.push(this.props.female_guest_details[i]);
    // }
    // this.setState({
    //   female_temp_details: temp,
    //   female_count: this.props.female_guest_details.length,
    //   initial_female_details: temp,
    // });
    localStorage.getItem("currency") &&
      this.setState({ currency: localStorage.getItem("currency") });
  }

  componentDidUpdate() {
    localStorage.getItem("currency") &&
      this.state.currency !== localStorage.getItem("currency") &&
      this.setState({ currency: localStorage.getItem("currency") });
  }

  handleSubmit(event) {
    // console.log(this.state.mixed_details);
    event.preventDefault();
    this.setState({ openEdit: false });
    // document.querySelector(".btn-close").click();
    this.props.mixedGuestDetails(this.state.mixed_temp_details);
    //this.props.femaleGuestDetails(this.state.female_temp_details);
  }

  handleChange(event) {
    let arr = event.target.name.split("_");
    let tempp = [];
    tempp = this.state.mixed_temp_details;
    // arr[0] === "mixed"
    //   ? (tempp = this.state.mixed_temp_details):;
    //  : (tempp = this.state.female_temp_details);
    if (arr[1] === "name" || arr[1] === "age") {
      tempp[parseInt(arr[2])][arr[1]] = event.target.value;
      if (arr[0] === "mixed") this.setState({ mixed_temp_details: tempp });
     // else this.setState({ female_temp_details: tempp });
    } else {
      let arr2 = event.target.id.split("_");
      if (event.target.value === "M") {
        tempp[parseInt(arr2[1])].gender = "M";
      } else {
        tempp[parseInt(arr2[1])].gender = "F";
      }
      this.setState({ mixed_temp_details: tempp });
    }
  }

  totalAmount(type) {
    const date1 = new Date(localStorage.getItem("check_in"));
    const date2 = new Date(localStorage.getItem("check_out"));
    const diffTime = Math.abs(date2 - date1);
    // console.log(diffTime);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    diffDays = diffDays;
    if (this.state.currency === "INR") {
      if (type === "mixed")
        return (
          this.props.mixed_guest_details.length *
          diffDays *
          parseInt(localStorage.getItem("mixed_dorm_price"))
        );
      // else if (type === "female")
      //   return (
      //    this.props.female_guest_details.length *
      //     diffDays *
      //     parseInt(localStorage.getItem("female_dorm_price"))
      //   );
    } else if (this.state.currency === "USD") {
      if (type === "mixed")
        return (
          this.props.mixed_guest_details.length *
          diffDays *
          parseInt(localStorage.getItem("mixed_dorm_price_D"))
        );
      // else if (type === "female")
      //   return (
      //     this.props.female_guest_details.length *
      //     diffDays *
      //     parseInt(localStorage.getItem("female_dorm_price_D"))
      //   );
    } else if (this.state.currency === "EUR") {
      if (type === "mixed")
        return (
          this.props.mixed_guest_details.length *
          diffDays *
          parseInt(localStorage.getItem("mixed_dorm_price_E"))
        );
      // else if (type === "female")
      //   return (
      //     this.props.female_guest_details.length *
      //     diffDays *
      //     parseInt(localStorage.getItem("female_dorm_price_E"))
      //   );
    }
  }

  daysCount() {
    const date1 = new Date(localStorage.getItem("check_in"));
    const date2 = new Date(localStorage.getItem("check_out"));
    const diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  handlePay = async (event, partial) => {
    event.preventDefault();
    // console.log(this.props);

    this.setState({ onprocess: true });
    if (auth.currentUser === null) {
      this.setState({
        onprocess: false,
        opensnack: true,
        snackmessage: "Please Login To continue Booking",
        severity: "error",
      });
      return setTimeout(() => {
        this.props.history.push({
          pathname: "/login",
          state: {
            next: "booknow",
            prev: "booknow",
          },
        });
      }, 3000);
    }
    var resUser = null;
    if (!this.props.user.hasOwnProperty("profile")) {
      // console.log("pushong");
      var IDToken;
     await auth.currentUser.getIdToken().then(async (id) => {
        console.log(id);
        // IDToken = id;
        resUser = await this.props.userProfile(id);
        if (resUser.hasOwnProperty("error")) {
          // alert(res.error);
          this.setState({ onprocess: false });
          return this.setState({
            onprocess: false,
            snack_message: resUser.error,
            opensnackbar: true,
            severity: "error",
          });
        }
      });
      // console.log(IDToken);
    } else {
      resUser = this.props.user.profile;
    }
    console.log(resUser, this.props.user);
    const userdata = {
      name: resUser.name || "",
      email: resUser.email || "",
      contact: resUser.phone || "",
    };
    //const locationid = localStorage.getItem("location_id");
    const booking_pdf = {
      mixed_dorm_price: localStorage.getItem("mixed_dorm_price"),
     // female_dorm_price: localStorage.getItem("female_dorm_price"),
    };

    // console.log(userdata.contact)
    const rzwindow = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    //console.log(rzwindow);
    if (!rzwindow) {
      this.setState({
        onprocess: false,
        opensnack: true,
        snackmessage: "Failed to open Payment Window. Network Error",
        severity: "error",
      });
      return;
    }
    // creating order_id by sending a reciept and currency and amount
    let booking_details = {
      location_id: localStorage.getItem("location_id"),
      days_count: this.daysCount(),
      mixed_guest_number: this.props.mixed_guest_details.length,
      //female_guest_number: this.props.female_guest_details.length,
      partial: false,
      partial_amount: this.state.partial_amount,
      coupon_discount: this.state.coupon_discount,
    };
    if (partial === "partial") {
      booking_details = {
        ...booking_details,
        partial: true,
      };
    }
    let pay_amount=this.totalAmount("mixed");
  
    if (partial === "partial") {
      pay_amount=(this.state.partial_amount * pay_amount)/100;
    }
    pay_amount=Math.ceil(pay_amount);
    console.log("pay_amount"+pay_amount);
    const newDate = new Date().toJSON().split("-");
    const order = {
      receipt:
        "IN" +
        newDate[2].slice(0, 2) +
        newDate[1] +
        newDate[0].slice(2) +
        localStorage.getItem("location_id").slice(0, 3) +
        Math.random().toString(36).slice(-4).toLocaleUpperCase(),
      currency: this.state.currency,
    orderamount: pay_amount,// this.totalAmount("mixed"), 
    //+ this.totalAmount("female")
      userId: auth.currentUser.uid,
    };

    // console.log("Sending booking ==>");
    const res = await this.props.booking(order, booking_details);
    // console.log("receving data", res);
    // this.setState({ onprocess: false });
    if (!res) {
      this.setState({
        onprocess: false,
        opensnack: true,
        snackmessage: "Network Error! Failed to fetch Payment Details",
        severity: "error",
      });
      return this.setState({ onprocess: false });
    }

    //id is important that a payment instance can be created
    const { amount, id, currency } = res.data;
    // console.log(amount, id, currency);
    let mixed_dorm_count = this.props.mixed_guest_details.length;
    //let female_dorm_count = this.props.female_guest_details.length;
    let mixed_guest_details = this.props.mixed_guest_details;
    //let female_guest_details = this.props.female_guest_details;
    let paid_percent = this.state.partial_amount;
    let coupon_discount = this.state.coupon_discount;
    let coupon_code = this.state.coupon_code;
    let j = false,
      booking_id = "",
      pnr = "";
    const options = {
      key: _razor_key, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "bunkbread",
      description: "Booking Dorms",
      order_id: id,
      image: { Logo },
      handler: async function (response) {
        // alert(response.razorpay_payment_id);
        const data = {
          orderCreationId: id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          useruid: auth.currentUser.uid,
          receipt: order.receipt,
        };
        // sending signatures and id,payid to backened to check the if payment was successfull

        const result = await axios.post(
          _serverurl + "payment/payments/success",
          data
        );
        //check if successfull or not

        if (result.data.message === "success") {
          // console.log(result);
          // console.log(event.target.id);
          const booking = {
            name: userdata.name,
            gst: localStorage.getItem("tax"),
            location_id: localStorage.getItem("location_id"),
            booked_at: new Date().toUTCString(),
            check_in: localStorage.getItem("check_in"),
            check_out: localStorage.getItem("check_out"),
            currency: options.currency,
            mixed_dorm_count,
            //female_dorm_count,
            mixed_guest_details,
            //female_guest_details,
            user_id: user.uid,
            orderId: result.data.orderId,
            paymentId: result.data.paymentId,
            email: userdata.email,
            receipt: order.receipt,
            amount: order.orderamount,
            coupon_discount:booking_details.coupon_discount,
            coupon_code:this.state.coupon_code,
            review: false,
            paid_amount: order.orderamount,
            booking_type: "hostel",
            phone_no:userdata.contact,
            ...booking_pdf,
          };
          if (partial === "partial") {
            const book_status = await axios.post(_serverurl + "booking", {
              ...booking,
              paid_percent: paid_percent,
              partial: true,
            });
            if (book_status.data.message === "booked") {
              // alert(`Congratulations, your booking has been completed.\nYour booking id is : ${book_status.data.booking_id}.\nYour PNR Number is ${book_status.data.pnr}.\nYour order id is ${result.data.orderId}.\nYour payment id is ${result.data.paymentId}`)
              booking_id = result.data.orderId;
              pnr = book_status.data.pnr;
              // if(partial!=="partial")
              var par = partial === "partail" ? true : false;
              pdfsenderndownload(
                "email",
                booking,
                par,
                userdata.contact,
                "confirm"
              );
              j = true;
            } else {
              this.setState({
                onprocess: false,
                opensnack: true,
                snackmessage: book_status.data.message,
                severity: "error",
              });
            }
          } else {
            const book_status = await axios.post(_serverurl + "booking", {
              ...booking,
              paid_percent: 100,
            });
            if (book_status.data.message === "booked") {
              // alert(`Congratulations, your booking has been completed.\nYour booking id is : ${book_status.data.booking_id}.\nYour PNR Number is ${book_status.data.pnr}.\nYour order id is ${result.data.orderId}.\nYour payment id is ${result.data.paymentId}`)
              booking_id = result.data.orderId;
              pnr = book_status.data.pnr;
              pdfsenderndownload("email", booking, 100, userdata.contact);
              //onDownloadReciept()
              j = true;
            } else {
              this.setState({
                onprocess: false,
                opensnack: true,
                snackmessage: book_status.data.message,
                severity: "error",
              });
            }
          }
        } else {
          this.setState({
            onprocess: false,
            opensnack: true,
            snackmessage: "Payment failed",
            severity: "error",
          });
        }
      },
      modal: {
        ondismiss: () => {
          // console.log("Checkout form Closed");
          const v = document.getElementsByClassName("razorpay-container");
          // console.log(v);
          v[v.length - 1].style.display = "none";
          this.setState({ onprocess: false });
        },
      },
      prefill: {
        name: userdata.name,
        email: userdata.email,
        contact: userdata.contact,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#ffde17",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //////////////////////////////////////////////// for test

    paymentObject.on("payment.failed", function (response) {
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
      // rzwindow = null
      // const v = document.getElementsByClassName("razorpay-container")
    });

    //////////////////////
    paymentObject.open();

    setInterval(() => {
      if (j == true) {
        if (this.state.open === true || this.state.popup === true) {
          this.setState({ onprocess: false });
        } else {
          this.setState({ popup: true, booking_id, pnr, open: true });
          j = false;
        }
      }
    }, 1000);
  };

  couponCheck = async (event) => {
    event.preventDefault();
    let res = await axios.post("/booking/coupon", {
      coupon_code: this.state.coupon_code,
    });
    if (res.data.discount === 0) {
      // alert("Invalid Coupon Code");
      this.setState({
        onprocess: false,
        opensnack: true,
        snackmessage: "Invalid Coupon Code",
        severity: "error",
        coupon_discount: 0,
      });
      // this.setState({ coupon_discount: 0 });
    } else {
      // console.log(res.data.discount);
      this.setState({ coupon_discount: res.data.discount });
    }
  };

  loadScript = async (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  render() {
    // console.log(this.state.currency);
    user = this.context;
    const { classes } = this.props;
    return (
      <Fragment>
        <Navbar />
        {/* <LinearProgress
          hidden={!this.state.onprocess}
          style={{
            position: "sticky",
           // top: "10vh",
            backgroundColor: "#ffde17",
            zIndex:"999"
          }}
        /> */}
        <Backdrop className={classes.backdrop} open={this.state.onprocess}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          // severity="danger"
          open={this.state.opensnack}
          onClose={this.onsnackClose}
          //TransitionComponent="Grow"
          style={{
            //display: "none",
            marginBottom: "4rem",
          }}
        >
          <Alert
            variant="filled"
            elevation={6}
            style={{ width: "90vw" }}
            severity={this.state.severity}
            action={
              <IconButton
                aria-label="close"
                //color="inherit"
                size="small"
                onClick={this.onsnackClose}
              >
                <CloseIcon fontSize="inherit" style={{ color: "white" }} />
              </IconButton>
            }
          >
            {this.state.snackmessage}
          </Alert>
        </Snackbar>
        {this.state.popup ? (
          <div>
            <Dialog
              className="PaymentSuccess"
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <img style={{ width: "120px", margin: "auto" }} src={tick} />

              <DialogTitle
                style={{ fontSize: "25px", padding: "0", textAlign: "center" }}
                id="alert-dialog-title"
              >
                Congratulations
              </DialogTitle>
              <DialogTitle
                className="paymentText"
                style={{ textAlign: "center" }}
                id="alert-dialog-title"
                style={{ padding: "0", textAlign: "center" }}
              >
                Your booking has been completed
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  style={{
                    fontSize: "12px",
                    padding: "0",
                    textAlign: "center",
                    margin: "15px 0 0",
                  }}
                  id="alert-dialog-description"
                >
                  <span style={{ fontSize: "14px", fontWeight: "600" }}>
                    Order id:&nbsp;
                  </span>
                  {this.state.booking_id}
                  <br />
                  <span style={{ fontSize: "14px", fontWeight: "600" }}>
                    Pnr:&nbsp;
                  </span>
                  {this.state.pnr}
                  <br />
                  You will recieve a reciept in your email shortly
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{ display: "flex" }}
                className="after_button"
              >
                <Button
                  style={{
                    width: "50%",
                    padding: "8px 0",
                    fontFamily: "Poppins",
                    background: "#ffde17",
                    color: "black",
                  }}
                  variant="contained"
                  onClick={this.handleClose}
                  color="secondary"
                >
                  <Link to="/">Home</Link>
                </Button>
                <Button
                  style={{
                    width: "50%",
                    padding: "8px 0",
                    fontFamily: "Poppins",
                    background: "#ffde17",
                    color: "black",
                  }}
                  variant="contained"
                  onClick={this.handleClose2}
                  color="secondary"
                >
                  Another Booking
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          ""
        )}

        <div>
          <Dialog
            className="GuestDetails"
            open={this.state.open2}
            // onClose={this.handleClose}
          >
            <DialogTitle id="alert-dialog-title">
              {"Enter booking details."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Booking
                  fixed={true}
                  btnVisible={true}
                  error={this.error}
                  landing={true}
                />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>

        {/* <div className="summary_main"> */}
        {/* <div className="summary_edit"> */}
        {this.state.popup ? (
          <CheckOutSteps step1 step1C step2 step2C step3 step3C />
        ) : (
          <CheckOutSteps step1 step1C step2 />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "15px 8px 15px",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              fontSize: "25px",
              margin: "5px 0 0",
              fontFamily: "Poppins",
            }}
          >
            Review Details
          </h3>
          <Button
            // id="edit_details_button"
            // type="button"
            style={{
              background: "#ffde17",
              fontFamily: "Poppins",
              fontSize: "10px",
            }}
            variant="contained"
            onClick={this.handleClickOpenEdit}
            disabled={this.state.onprocess}
          >
            Edit Details
          </Button>
        </div>
        <Divider />
        <Dialog
          className="GuestDetails"
          // onClose={this.handleCloseEdit}
          aria-labelledby="simple-dialog-title"
          open={this.state.openEdit}
        >
          <DialogTitle id="simple-dialog-title">Edit Details</DialogTitle>
          {/* <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  id="#guest_edit_modal"
                  className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"
                >
                  <div className="modal-content"> */}
          {/* <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">

                </h5> */}
          {/* <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> */}
          {/* </div> */}
          <DialogContent>
            {/* <div className="modal-body"> */}
            <form onSubmit={this.handleSubmit}>
              {/* <div className="guest_edit"> */}
              {/* <div className="guest_edit_main"> */}

              {this.props.mixed_guest_details.length > 0 && (
                <DialogContentText id="modal-details">
                  Mixed Dorm
                </DialogContentText>
              )}

              {/* </div> */}
              {this.state.mixed_temp_details.map((detail, key) => (
                <div
                  // style={{ flexWrap: "wrap" }}
                  key={key}
                  className={`guest_details mixed_details`}
                >
                  <TextField
                    style={{ width: "63%", margin: "10px 5px 0 0" }}
                    id={`mixed_name_${key}`}
                    name={`mixed_name_${key}`}
                    value={detail.name}
                    onChange={this.handleChange}
                    label="Name"
                    type="text"
                    required
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    style={{ width: "25%", margin: "10px 10px 0 0" }}
                    id={`mixed_age_${key}`}
                    name={`mixed_age_${key}`}
                    value={detail.age}
                    onChange={this.handleChange}
                    label="Age"
                    type="number"
                    required
                    variant="outlined"
                    size="small"
                  />

                  <FormControl
                    className={`currency`}
                    style={{ margin: "10px 10px 0 0", width: "12%" }}
                  >
                    {/* <InputLabel id="gender_label">Age</InputLabel> */}
                    <Select
                      labelId="gender_label"
                      id={`mixed_${key}`}
                      value={detail.gender === "M" ? "M" : "F"}
                      onChange={(event) => {
                        let temp = this.state.mixed_temp_details;
                        temp[key].gender = event.target.value;
                        this.setState({ mixed_temp_details: temp });
                      }}
                    >
                      <MenuItem value="M">M</MenuItem>
                      <MenuItem value="F">F</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ))}
              {/* </div> */}

              {/* <div className="guest_edit"> */}
              {/* <div className="guest_edit_main"> */}

              {/* {this.props.female_guest_details.length > 0 && (
                <DialogContentText
                  style={{ marginTop: "15px" }}
                  id="modal-details"
                >
                  Female Dorm
                </DialogContentText>
              )}

              //</div>
              {this.state.female_temp_details.map((detail, key) => (
                <div
                  key={key}
                  className={
                    this.props.type === "mixed"
                      ? `guest_details mixed_details`
                      : `guest_details female_details`
                  }
                >
                  <TextField
                    style={{
                      marginRight: "5px",
                      marginTop: "10px",
                      width: "63%",
                    }}
                    id={`female_name_${key}`}
                    name={`female_name_${key}`}
                    value={detail.name}
                    onChange={this.handleChange}
                    label="Name"
                    type="text"
                    required
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    style={{ margin: "10px 10px 0 0", width: "25%" }}
                    id={`female_age_${key}`}
                    name={`female_age_${key}`}
                    value={detail.age}
                    onChange={this.handleChange}
                    label="Age"
                    type="number"
                    required
                    variant="outlined"
                    size="small"
                  />
                  <FormControl
                    className={`currency`}
                    style={{ margin: "10px 10px 0 0", width: "12%" }}
                  >
                    <InputLabel id="gender_label">Gender</InputLabel>
                    <Select
                      labelId="gender_label"
                      id={`mixed_${key}`}
                      value="F"
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value="F">F</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ))} */}
              {/* </div> */}

              {/* <GuestEdit  type="mixed" />
                                            <GuestEdit  type="female" /> */}
              <div style={{ margin: "15px 0" }} className="guest_submit">
                <Button
                  style={{
                    width: "100%",
                    fontFamily: "Poppins",
                    background: "#ffde17",
                  }}
                  variant="contained"
                  type="submit"
                >
                  Done
                </Button>
              </div>
            </form>
          </DialogContent>
          {/* </div> */}
          {/* </div>

                </div>
              </div> */}
        </Dialog>
        {/* </div> */}
        <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            Destination :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            {localStorage.getItem("destination")}
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            Check In Date :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            {localStorage.getItem("check_in")}
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            Check Out Date :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                           {localStorage.getItem("check_out")}
                      </Typography>
                    </Grid>
                    </Grid>
        {/* <div style={{ margin: "8px" }} className="booking_details">
          <div style={{ fontSize: "14px", fontFamily: "Poppins" }}>
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              Destination:&nbsp;
            </span>
            {localStorage.getItem("destination")}
          </div>
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              margin: "8px 0",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              Check In Date:&nbsp;
            </span>
            {localStorage.getItem("check_in")}
          </div>
          <div style={{ fontSize: "14px", fontFamily: "Poppins" }}>
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              Check Out Date:&nbsp;
            </span>
            {localStorage.getItem("check_out")}
          </div>
        </div> */}

        {this.props.mixed_guest_details.length !== 0 && (
          <Paper style={{ margin: "10px 8px", padding: "0 0 20px" }}>
            {this.props.mixed_guest_details.length !== 0 && (
              <Review
                type="mixed"
                beds_count={this.props.mixed_guest_details.length}
                tariff={`${localStorage.getItem("mixed_dorm_price")} INR`}
                total={`${this.totalAmount("mixed")} INR`}
              />
            )}
            {this.state.mixed_temp_details.length !== 0 && (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "none",
                          paddingBottom: "0",
                          width: "50%",
                          fontWeight: "600",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          paddingBottom: "0",
                          width: "25%",
                          fontWeight: "600",
                        }}
                        align="left"
                      >
                        Age
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          paddingBottom: "0",
                          fontWeight: "600",
                        }}
                        align="left"
                      >
                        Gender
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            )}
            {this.state.initial_mixed_details.map((detailss, key) => (
              <GuestDetails
                key={key}
                name={detailss.name}
                age={detailss.age}
                gender={detailss.gender}
              />
            ))}
          </Paper>
        )}

        {/* {this.state.female_temp_details.length !== 0 && (
          <Paper style={{ margin: "10px 8px", padding: "0 0 20px" }}>
            {this.state.female_temp_details.length !== 0 && (
              <Review
                type="female"
                beds_count={this.props.female_guest_details.length}
                tariff={`${localStorage.getItem("female_dorm_price")} INR`}
                total={`${this.totalAmount("female")} INR`}
              />
            )}
            {this.props.female_guest_details.length !== 0 && (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableCell
                      style={{
                        border: "none",
                        paddingBottom: "0",
                        width: "50%",
                        fontWeight: "600",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        paddingBottom: "0",
                        width: "25%",
                        fontWeight: "600",
                      }}
                      align="left"
                    >
                      Age
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        paddingBottom: "0",
                        fontWeight: "600",
                      }}
                      align="left"
                    >
                      Gender
                    </TableCell>
                  </TableHead>
                </Table>
              </TableContainer>
            )}
            {this.state.initial_female_details.map((detail, key) => (
              <GuestDetails
                key={key}
                name={detail.name}
                age={detail.age}
                gender="F"
              />
            ))}
          </Paper>
        )} */}

        <h2
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            fontFamily: "Poppins",
          }}
        >
          Total :
          {`${
            this.totalAmount("mixed") 
          }`}{" "}
          {this.state.currency}
          {/* <span style={{ fontSize: "12px" }}>
            &nbsp;
            {`${
              this.totalAmount("mixed") + this.totalAmount("female")
            }`}
          </span> */}
          {this.state.coupon_discount > 0
            ? ` - ${this.state.coupon_discount}{this.state.currency} = ${
                this.totalAmount("mixed") +
                //this.totalAmount("female") +
                (localStorage.getItem("tax") *
                  (this.totalAmount("mixed") /*+ this.totalAmount("female")*/)) /
                  100 -
                this.state.coupon_discount
              }`
            : ""}
        </h2>
        <form
          className="coupon_form"
          onSubmit={this.couponCheck}
          autoComplete="off"
        >
          <TextField
            required
            onChange={(e) => {
              this.setState({ coupon_code: e.target.value });
            }}
            name="coupon_code"
            id="coupon"
            label="Coupon Code"
            variant="outlined"
            size="small"
          />
          <Button
            style={{
              background: "#ffde17",
              fontFamily: "Poppins",
            }}
            variant="contained"
            type="submit"
          >
            Check
          </Button>
        </form>
        <div style={{ textAlign: "center", margin: "15px 0 30px" }}>
          <Button
            variant="contained"
            style={{
              background: "#ffde17",
              fontFamily: "Poppins",
              width: "95%",
            }}
            onClick={this.handlePay}
            className="summary_pay"
            disabled={this.state.onprocess}
          >
            PAY
          </Button>
        </div>

        {this.state.partial && (
          <div style={{ textAlign: "center", margin: "15px 0 30px" }}>
            <Button
              variant="contained"
              style={{
                background: "#ffde17",
                fontFamily: "Poppins",
                width: "95%",
              }}
              onClick={(event) => this.handlePay(event, "partial")}
              id="partial"
              className="summary_pay"
              disabled={this.state.onprocess}
            >
              PAY {this.state.partial_amount}%
            </Button>
          </div>
        )}
        {/* </div> */}
        <Footer Workpad_none />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    avail: state.availReducer,
    availableNow: state.availableNow,
    mixed_guest_details: state.mixedGuestDetails,
    //female_guest_details: state.femaleGuestDetails,
    auths: state.authreducer,
    user: state.userProfile,
    destination: state.availableDestinations,
  };
};

// const mapDispatchToProps = () => {
//   return {
//     date,
//     update_available,
//     mixedGuestDetails,
//     femaleGuestDetails,
//     mixedTempDetails,
//     femaleTempDetails,
//     booking
//   };
// };

const styles = withStyles(useStyles)(Summary);

export default connect(mapStateToProps, actions)(styles);
