import React, { useContext } from "react";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UserContext } from "../providers/UserProvider";
import {
  Button,
  LinearProgress,
  Box,
  Snackbar,
  IconButton,
  FormControl,
  FormGroup,
  TextField,
  
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { auth } from "../firebase";
import { setupuser, sendemailotp } from "../actions";
import Navbar from "./static/navbar/navbarMui";
let user;
//import history from "../helpers/history"
class SetupProfile extends React.Component {
  static contextType = useContext;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      verify: false,
      invalidauth: false,
      otpfield: false,
      oocode: "",
      onprocess: false,
      opensnackbar: false,
      error_message: "",
      nameError: false,
      emailError: false,
      severity: "error",
    };
  }
  autofill = () => {
    try {
      if (this.props.location.state.name !== undefined) {
        this.setState({ name: this.props.location.state.name });
      }
      if (this.props.location.state.primary === "phone") {
        return this.setState({ email: this.props.location.state.secondary });
      } else if (this.props.location.state.primary === "email") {
        return this.setState({ phone: this.props.location.state.secondary });
      }
    } catch (error) {
      this.setState({
        email: "",
        phone: "",
        name: "",
      });
    }
  };
  // handelsnackclose = () => {
  //   //event.preventDefault();
  //   console.log(this.props.location);
  //   this.props.location.state = undefined;
  //   this.setState({
  //     opensnackbar: false,
  //     error_message: "",
  //     // nameError: false,
  //     // emailError: false,
  //     // severity:"error"
  //   });
  // };
  componentDidMount() {
    this.autofill();
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.hasOwnProperty("prev") &&
      this.props.location.state.prev === "login"
    ) {
      this.setState({
        opensnackbar: true,
        severity: "success",
        error_message: "Log-in Successful",
      });
    }
  }
  static contextType = UserContext;
  handelsnackclose = () => {
    //event.preventDefault();
    // console.log("snackclose");
    this.setState({
      opensnackbar: false,
      error_message: "",
      severity:"",
      nameError: false,
      emailError: false,
    });
  };

  setupstartunverfied = async (e) => {
    e.preventDefault();
    this.setState({ onprocess: true });
    // console.log("hi==>unverified", this.state);
    ///
    if (this.state.name === "" || this.state.name.length < 5) {
      this.setState({
        onprocess: false,
        opensnackbar: true,
        error_message: "Empty Name Field",
        nameError: true,
        severity: "error",
      });

      return;
    }
    if (this.state.email === "" && this.state.phone === "") {
      this.setState({
        onprocess: false,
        opensnackbar: true,
        error_message: "Empty Email/Phone Field",
        emailError: true,
        severity: "error",
      });
      return;
    }
    ///
    ///name and email/phone
    // console.log(this.state);
    // send to backend to update
    if (this.state.phone !== "") {
      //phone update
      var phone;
      // console.log("Adding Phone And Name");
      if (this.state.phone[0] !== "+") {
        phone = "+" + this.state.phone;
      } else {
        phone = this.state.phone;
      }

      const data = {
        update: "phone",
        name: this.state.name,
        phone: phone,
        uid: auth.currentUser.uid,
        verify: this.state.verify,
      };
      if (
        this.props.location.state !== undefined &&
        this.props.location.state.hasOwnProperty("prev") &&
        this.props.location.state.prev === "login"
      ) {
        data.from = "login";
      }
      const res = await this.props.setupuser(data);
      // console.log(res);
      auth.currentUser.reload();
      if (res === "success") {
        auth.currentUser.reload();
        // this.setState({ onprocess: !this.state.onprocess });
        // this.setState({
        //   opensnackbar: true,
        //   error_message: "Setup Successfull",
        //   severity: "success",
        // });
        // show success tag
        // this.props.history.push("/")
        setTimeout(() => {
          if (
            this.props.location.state !== undefined &&
            this.props.location.state.hasOwnProperty("next") &&
            typeof this.props.location.state.next === "string"
          ) {
            // console.log("pushing data");
            this.props.history.push({
              pathname: "/" + this.props.location.state.next,
              state: {
                proccedBook: true,
                prev: "setupprofile",
              },
            });
          } else {
           return this.props.history.push({
              pathname: "/" ,
              state: {
                proccedBook: true,
                prev: "setupprofile",
              },
            });
            
          }
        }, 4000);
      } else {
        // auth.currentUser.reload();

        this.setState({
          opensnackbar: true,
          error_message: "Setup Unsuccessfull",
        });
        this.setState({ otpfield: false });
        this.setState({ onprocess: !this.state.onprocess });
        return;
        // show error
      }
    } else if (this.state.email !== "") {
      const data = {
        update: "email",
        name: this.state.name,
        email: this.state.email,
        verify: this.state.verify,
        uid: auth.currentUser.uid,
      };
      // console.log("Adding Email And Name");
      if (
        this.props.location.state !== undefined &&
        this.props.location.state.hasOwnProperty("prev") &&
        this.props.location.state.prev === "login"
      ) {
        data.from = "login";
      }

      // console.log(data);
      const res = await this.props.setupuser(data);
      auth.currentUser.reload();
      if (res === "success") {
        // this.setState({ onprocess: !this.state.onprocess });
        auth.currentUser.getIdToken(true);
        // this.setState({
        //   opensnackbar: true,
        //   error_message: "Successfully Updated!",
        //   severity: "success",
        // });
        setTimeout(() => {
          if (
            this.props.location.state !== undefined &&
            this.props.location.state.hasOwnProperty("next") &&
            typeof this.props.location.state.next === "string"
          ) {
            // console.log("pushing data");
            return this.props.history.push({
              pathname: "/" + this.props.location.state.next,
              state: {
                proccedBook: true,
                prev: "setupprofile",
              },
            });
          } else {
            return this.props.history.push({
              pathname: "/",
              state: {
                proccedBook: true,
                prev: "setupprofile",
              },
            });
          }
        }, 4000);
      } else {
        //auth.currentUser.reload();
        //this.setState({ otpfield: false });
        // alert("Failed");
        this.setState({
          onprocess: !this.state.onprocess,
          opensnackbar: true,
          error_message: res,
          severity: "error",
        });
      }
      return;
    }
  };

  inputfield = () => {
    if (
      this.props.location.state !== undefined &&
      this.props.match.params.userId === auth.currentUser.uid
    ) {
      // console.log("HII");
      if (this.props.location.state.primary === "email") {
        // console.log("email verified");
        return (
          <FormControl fullWidth>
            <PhoneInput
              disabled={this.state.onprocess}
              country="in"
              // disableDropdown="false"
              value={this.state.phone}
              //defaultValue={this.props.location.state.sec}
              onChange={(phone) => {
                this.setState({ phone });
              }}
              inputProps={{
                name: "tel",
                required: true,
              }}
              inputStyle={{
                width: "100%",
              }}
            ></PhoneInput>
            <p style={{ fontSize: "small", fontFamily: "Poppins" }}>
              This number will be used for booking correspondence and reminders.
            </p>
          </FormControl>
        );
      } else if (this.props.location.state.primary === "phone") {
        return (
          <FormControl>
            <TextField
              //margin="dense"
              name="email"
              variant="outlined"
              size="small"
              value={this.state.email}
              error={this.state.emailError}
              disabled={this.state.onprocess}
              placeholder="Email"
              //style={{ height: "35px" }}
              onChange={(e) => this.setState({ email: e.target.value })}
            ></TextField>
            <p style={{ fontSize: "small", fontFamily: "Poppins" }}>
              This email address will be used for booking details and reminders.
            </p>
          </FormControl>
        );
      }
    } else
      return (
        <>
          {" "}
          <h1>Error</h1>
          {this.props.history.push("/404")}
        </>
      );
  };
  // paintrecap = () => {
  //   recaptcha = new firebase.auth.RecaptchaVerifier("captcha", {
  //     size: "invisible",
  //     callback: (response) => {
  //       console.log("captcha solved");
  //     },
  //     "expired-callback": () => {
  //       alert("captcha error-- Reload the page");
  //       this.setState({
  //         opensnackbar: true,
  //         error_message: "Captcha Error ! Reload the Page",
  //       });
  //     },
  //   });
  //   recaptcha.render().catch((e) => {
  //     alert("Network error,Capchta Not rendered..Refresh the page");
  //     this.setState({
  //       opensnackbar: true,
  //       error_message: "Captcha Error ! Reload the Page",
  //     });
  //   });
  // };
  displayLogintext = () => {
    //e.preventDefault();
    //console.log("event check");
    if (this.props.location.state !== undefined) {
      if (
        this.props.location.state.hasOwnProperty("prev") &&
        this.props.location.state.prev === "login"
      ) {
        //alert("Login success")
        return (
          <span style={{ color: "#18c45f", fontFamily: "Poppins" }}>
            Welcome!
          </span>
        );
      } else {
        // alert("Complete");
        return "Complete Profile";
      }
    } else return "Complete Profile";
  };
  render() {
    user = this.context;
    console.log(this.props.location.state);
    return (
      <>
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.opensnackbar}
          onClose={this.handelsnackclose}
          style={{
            //display: "none",
            marginBottom: "4rem",
          }}
        >
          <Alert
            variant="filled"
            elevation={6}
            style={{ width: "90vw" }}
            severity={this.state.severity}
            action={
              <IconButton
                aria-label="close"
                //color="inherit"
                onClick={this.handelsnackclose}
                size="small"
                style={{ color: "inherit" }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {this.state.error_message}
          </Alert>
        </Snackbar>

        <Navbar />
        <LinearProgress
          hidden={!this.state.onprocess}
          style={{
            position: "sticky",
            //top: "10vh",
            backgroundColor: "#ffde17",
            zIndex: "999",
          }}
        />
        {/* <FormGroup>
          <Collapse in={this.state.opensnackbar}>
            <Alert
              variant="filled"
              elevation={6}
              severity="info"
              style={{ margin: "1rem 0rem 1rem 0rem" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.handelsnackclose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {this.state.error_message}
            </Alert>
          </Collapse>
        </FormGroup> */}
        <div style={{ display: "block", marginTop: "60px" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "10px",
              width: "95%",
            }}
            // width={1 / 1.5}
          >
            <>
              <p style={{ fontWeight: "800", fontFamily: "Poppins" }}>
                {this.displayLogintext()}
              </p>
              <p style={{ fontSize: "small", fontFamily: "Poppins" }}>
                Complete profile details to make
                bookings
              </p>
            </>

            {user === null ? (
              <center>
                <LinearProgress
                  style={{
                    backgroundColor: "#18c45f",
                    zIndex: "999",
                  }}
                />
                {/* <Skeleton animation="wave"  height={300}/> */}
              </center>
            ) : (
              <FormGroup>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Name"
                    error={this.state.nameError}
                    value={this.state.name}
                    // defaultValue={this.state.name}
                    disabled={this.state.onprocess}
                    // helperText={this.state.nameError?this.state.error_message:""}
                    variant="outlined"
                    // variant="outlined"
                    type="text"
                    name="name"
                    //margin="dense"
                    size="small"
                    //style={{height:"35px"}}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  ></TextField>
                </FormControl>
                <br />
                {this.inputfield()}

                {/* <Checkbox checked={this.state.verify} size="small" onChange={e=>this.setState({verify:e.target.checked})}></Checkbox> */}
                <Button
                  type="submit"
                  disabled={this.state.onprocess}
                  onClick={this.setupstartunverfied}
                  variant="contained"
                  style={{
                    backgroundColor: "#FFDE17",
                    marginTop: "1rem",
                  }}
                >
                  Continue
                </Button>
                <Button
                  disabled={this.state.onprocess}
                  onClick={(e) => this.props.history.push("/")}
                  variant="contained"
                  style={{ marginTop: "1em" }}
                >
                  Skip to home
                </Button>
              </FormGroup>
            )}

            <div id="captcha"></div>
          </Box>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps, { setupuser, sendemailotp })(
  SetupProfile
);
