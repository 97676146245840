import React from "react";
import Navbar from "./navbar/navbarMui";
import Paper from "@material-ui/core/Paper";
import contactIMG from "../../assets/contact1.jpg";
import { Link } from "react-router-dom";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Footer from "./footer";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
class Contactus extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Link to="/">
          <CloseRoundedIcon
          className="crossIcon"
            fontSize="large"
            style={{
              color: "#ffde17",
              position: "absolute",
              right: "10px",
              marginTop: "10px",
              opacity: "1",
              zIndex: "1",
            }}
          />
        </Link>
        <Paper style={{ height: "260px", margin: "10px" }}>
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <img style={{ }} src={contactIMG} />
          </div>
          <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                            fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            Phone :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            +91-6206653586, +91-9475750985
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                            fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            E-Mail :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            teambunkbread@gmail.com
                      </Typography>
                    </Grid>
                    </Grid>
         
          <h5
            style={{
              textAlign: "left",
              fontSize: "13px",
              fontFamily: "Poppins",
              margin: 0,
              padding: "5px",
            }}
          >
            {/* <span style={{ fontWeight: "600" }}>ADDRESS:</span> C-22, Himanchal */}
            {/* Vihar, Near Passport Seva Kendra, Siliguri, West bengal, 734010 */}
          </h5>
        </Paper>
        <Footer bottom />
      </React.Fragment>
    );
  }
}

export default Contactus;
