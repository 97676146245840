import jspdf from "jspdf";
// import html2canvas from "html2canvas";
import axios from "../../apis/axios";
import { renderToStaticMarkup } from "react-dom/server";
// import { Snackbar, IconButton } from "@material-ui/core";
// import { Alert } from "@material-ui/lab";
// import { Close as CloseIcon } from "@material-ui/icons";
//import {CloseIcon }

import Logo from "../../assets/logo3.png";
import Sign from "../../assets/sign.png";
import Logoin from "../../assets/fav1.png";
import "../../assets/css/pdfrender.css";
// import { ta } from "date-fns/locale";
// import booking from "./booking";
//import { Link } from "react-router-dom";
var th_val = ["", "THOUSAND AND", "MILLION AND", "BILLION AND", "TRILLION AND"];
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];
var grossAmount;
var discount_obamount = 0;
var netamount;
var dg_val = [
  "ZERO",
  "ONE",
  "TWO",
  "THREE",
  "FOUR",
  "FIVE",
  "SIX",
  "SEVEN",
  "EIGHT",
  "NINE",
];
var tn_val = [
  "TEN",
  "ELEVEN",
  "TWELVE",
  "THIRTEEN",
  "FOURTEEN",
  "FIFTEEN",
  "SIXTEEN",
  "SEVENTEEN",
  "EIGHTEEN",
  "NINETEEN",
];
var tw_val = [
  "TWENTY",
  "THIRTY",
  "FORTY",
  "FIFTY",
  "SIXTY",
  "SEVENTY",
  "EIGHTY",
  "NINETY",
];
// html2canvas method is costly as image size is to large
function toWordsconver(s) {
  // console.log(s);
  console.warn = () => {};
  s = s.toString();
  s = s.replace(/[\, ]/g, "");
  if (s != parseFloat(s)) return "not a number ";
  var x_val = s.indexOf(".");
  if (x_val == -1) x_val = s.length;
  if (x_val > 15) return "too big";
  var n_val = s.split("");
  var str_val = "";
  var sk_val = 0;
  for (var i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == "1") {
        str_val += tn_val[Number(n_val[i + 1])] + " ";
        i++;
        sk_val = 1;
      } else if (n_val[i] != 0) {
        str_val += tw_val[n_val[i] - 2] + " ";
        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      str_val += dg_val[n_val[i]] + " ";
      if ((x_val - i) % 3 == 0) str_val += "HUNDRED AND ";
      sk_val = 1;
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + " ";
      sk_val = 0;
    }
  }
  if (x_val != s.length) {
    var y_val = s.length;
    str_val += "POINT ";
    for (var i = x_val + 1; i < y_val; i++) str_val += dg_val[n_val[i]] + " ";
  }
  return str_val.replace(/\s+/g, " ");
}

const noofdays = (check_in, check_out) => {
  // console.log(check_in,check_out)
  const date1 = new Date(check_in);
  const date2 = new Date(check_out);
  // console.log(date1,date2)
  const diffTime = Math.abs(date2 - date1);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // console.log(diffDays);
  return parseInt(diffDays);
};

function displayDiscount(dOB, d, amount) {
  console.warn(dOB);
  if (dOB > 0) {
    return dOB;
  } else {
    return parseFloat(d.substring(0, d.length - 1)) * (amount / 100);
  }
}
export const yourpdf = (booking_details, paid_percent) => {
  console.warn = () => {};
  // console.log(booking_details);
  if (booking_details.booking_type !== "hotspot") {
    // console.log("here");
    booking_details.bed_type = ["female_guest_details", "mixed_guest_details"];
    var female_dorms = booking_details.female_guest_details;
    var mixed_dorms = booking_details.mixed_guest_details;
    booking_details.guest_details = { female_dorms, mixed_dorms };
    booking_details.price = {
      female_dorms: parseInt(booking_details.female_dorm_price),
      mixed_dorms: parseInt(booking_details.mixed_dorm_price),
    };
  }
  let currencywords = "INR";
  var price = booking_details.price;
  switch (booking_details.currency) {
    case "INR":
      // booking_details.currency = "Rs. ";
      currencywords = "RUPEES";
      // price = booking_details.price;
      break;
    case "USD":
      currencywords = "DOLLORS";
      // price = booking_details.price_USD;

      break;
    case "EUR":
      currencywords = "EUROS";
      // price = booking_details.price_EUR;
      break;
    default:
      currencywords = currencywords;
      // price = booking_details.price;
      break;
  }
  //diff coupon discount amount vs percentage
  // var discount_obamount = 0;
  if (
    booking_details.coupon_code !== "" &&
    typeof booking_details.coupon_discount === "object" &&
    Object.keys(booking_details.coupon_discount).length > 0
  ) {
    // var cur = booking_details.currency
    discount_obamount =
      booking_details.coupon_discount[booking_details.currency];
    // grossAmount = grossAmount - discount_obamount;
    // } else {
    //   discount_obamount = 0.0;
  }

  //formatting booking date
  var dateBook = new Date(booking_details.booked_at);
  var bookedDate = `${dateBook.getDate()}/${
    dateBook.getMonth() + 1
  }/${dateBook.getFullYear()}`;
  // console.log(currencywords);
  // debugger;

  /// workpad or hostels
  // console.log(booking_details);
  var discount = 0;
  // console.log(booking_details.coupon_discount.length);
  if (
    booking_details.hasOwnProperty("coupon_code") &&
    booking_details.coupon_code !== "" &&
    discount_obamount === 0
  ) {
    var rp = booking_details.coupon_discount.substring(
      0,
      booking_details.coupon_discount.length - 1
    );
    console.log(rp);
    discount = parseFloat(rp);
  } else {
    discount = 0;
  }
  // console.log(discount);
  discount = parseFloat(discount);
  var gst = parseFloat(booking_details.gst).toFixed(2);
  gst = parseFloat(gst);
  var cgst = gst / 2;

  return (
    <div
      style={{
        width: "50mm",
        marginLeft: "2mm",
        marginTop: "3mm",
        marginRight: "2mm",
        fontSize: "1mm",
      }}
    >
      <img
        src={Logo}
        alt="bunkbread logo"
        // width="13.23mm"
        style={{ width: "15mm", marginBottom: "1.5mm" }}
      />

      <div
        style={{
          display: "flex",
          // alignItems: "flex-start",
          alignItems: "center",
          justifyContent: "space-between",
          fontWeight: "bold",
          marginBottom: "1.5mm",
          fontSize: "1.1mm",
        }}
      >
        <div
          style={{
            border: "0.1mm solid #565555",
            borderRadius: "2%",
            padding: "2mm 1mm",
            width: "45%",
            // height:"2mm"
          }}
        >
          FROM
          <br />
          <p
            style={{
              fontWeight: "bolder",
              fontSize: "1.4mm",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={Logoin} style={{ width: "1.5mm", marginRight: ".5mm" }} />
            bunkbread
          </p>
          <p
            style={{
              marginTop: "2mm",
              marginTop: "2mm",
              paddingTop: "2mm",
              marginBottom: ".5mm",
            }}
          >
            teambunkbread@gmail.com
            <br />
            +916206653586
          </p>
        </div>
        <div
          style={{
            border: "0.1mm solid #565555",
            borderRadius: "2%",
            padding: "2mm 1mm",
            width: "45%",
          }}
        >
          TO
          <br />
          <p
            style={{
              fontWeight: "bolder",
              fontSize: "1.4mm",
              marginBottom: "2.7mm",
              marginTop: "2mm",
            }}
          >
            {booking_details.name}
          </p>
          <p
            style={{
              marginTop: "2mm",
              paddingTop: "2mm",
              marginBottom: ".5mm",
            }}
          >
            {booking_details.email}
            <br />
            {booking_details.phone_no}
          </p>
        </div>
      </div>
      <div style={{ fontSize: "1mm" }}>
        <span style={{ fontWeight: "bolder" }}>Invoice No: </span>
        {booking_details.receipt}
      </div>
      <div style={{ fontSize: "1mm", marginTop: "1mm", marginBottom: "1mm" }}>
        <span style={{ fontWeight: "bolder" }}>Invoice Date: </span>
        {bookedDate}
      </div>
      <table
        style={{ width: "100%", textAlign: "center", fontSize: "1.2mm" }}
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          <tr style={{ background: "#c5c5c5", marginBottom: "2mm" }}>
            <th>Items</th>
            <th>QTY*</th>
            <th>Rate</th>
            <th>Disc%</th>
            <th>Net</th>
            <th>Tax%</th>
            <th>Amount</th>
          </tr>
          {populateTable(
            booking_details.guest_details,
            price,
            booking_details.currency,
            // booking_details.coupon_discount,
            // booking_details.coupon_discount || "",
            parseFloat(discount),

            booking_details.gst,
            booking_details.check_in,
            booking_details.check_out
          )}
        </tbody>
      </table>
      <hr
        style={{
          borderLeft: "none",
          borderTop: ".1mm solid #707070",
          borderBottom: "none",
          borderRight: "none",
        }}
      />
      <div style={{ display: "flex", width: "100%", marginTop: "1mm" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "57%",
            // border: ".1px solid black",
          }}
        >
          {booking_details.hasOwnProperty("coupon_code") &&
          booking_details.coupon_code !== "" ? (
            <div
              style={{
                marginLeft: "auto",
                border: ".1mm solid #565555",
                padding: ".2mm 2mm",
                borderRadius: ".2mm",
              }}
            >
              {booking_details.coupon_code}
            </div>
          ) : (
            <div></div>
          )}

          <div style={{ marginTop: "8mm", fontSize: "1.1mm" }}>
            Amount in words :
          </div>
          <div>
            {toWordsconver(booking_details.paid_amount)}
            {""}
            {currencywords}&nbsp;
            {" ONLY"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "40%",
            // border: ".1px solid black",
            marginLeft: "auto",
          }}
        >
          <div style={{ padding: ".4mm" }}>
            Discount :
            <span
              style={{
                float: "right",
                // marginRight: "2mm"
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {booking_details.currency}
              </span>{" "}
              {booking_details.hasOwnProperty("coupon_code") &&
              booking_details.coupon_code !== ""
                ? displayDiscount(
                    discount_obamount,
                    booking_details.coupon_discount,
                    booking_details.amount
                  )
                : "0.00"}
            </span>
          </div>
          <div
            style={{
              background: "#cecece",
              textAlign: "center",
              margin: "1mm 0",
              padding: ".5mm",
              fontWeight: "bolder",
            }}
          >
            Invoice Summary
          </div>
          <div>
            Gross Amount :
            <span style={{ float: "right" }}>
              <span style={{ fontWeight: "bold" }}>
                {booking_details.currency}
              </span>{" "}
              {grossAmount}.00
            </span>
          </div>
          <div style={{ marginTop: "1mm" }}>
            CGST({parseFloat(booking_details.gst) / 2}%) :
            <span style={{ float: "right" }}>
              <span style={{ fontWeight: "bold" }}>
                {booking_details.currency}
              </span>{" "}
              {parseFloat(grossAmount * (cgst / 100)).toFixed(2)}
            </span>
          </div>
          <div style={{ marginTop: "1mm" }}>
            SGST({parseFloat(booking_details.gst) / 2}%) :
            <span style={{ float: "right" }}>
              <span style={{ fontWeight: "bold" }}>
                {booking_details.currency}
              </span>{" "}
              {parseFloat(grossAmount * (cgst / 100)).toFixed(2)}
            </span>
          </div>
          <div style={{ marginTop: "2mm" }}>
            Discount :
            <span style={{ float: "right" }}>
              <span style={{ fontWeight: "bold" }}>
                {booking_details.currency}
              </span>{" "}
              {parseFloat(netamount - booking_details.paid_amount).toFixed(2) -
                parseFloat(discount_obamount).toFixed()}
            </span>
          </div>
          <hr
            style={{
              borderLeft: "none",
              borderTop: ".1mm solid #707070",
              borderBottom: "none",
              borderRight: "none",
              width: "100%",
            }}
          />
          <div style={{ fontSize: "1.5mm", fontWeight: "bold" }}>
            <span>Net Payable:</span>
            <span style={{ float: "right" }}>
              <span style={{ fontWeight: "bold" }}>
                {booking_details.currency}
              </span>{" "}
              {booking_details.paid_amount}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "4mm",
          alignItems: "center",
          fontSize: "1.3mm",
        }}
      >
        <div style={{ width: "57%", textAlign: "right" }}>
          Thanks For Staying With Us.
        </div>
        {/* <div style={{ marginLeft: "10mm" }}>
          <div>From bunkbread</div>
          <div>
            <img src={Sign} style={{ width: "50%" }} />
          </div>
          <div>Authorised Signature</div>
        </div> */}
      </div>
      <div style={{ marginTop: "1mm" }}>
        *-(# of individuals x # of nights)
        <br />-<span style={{ color: "blue" }}>Terms and Conditions</span>
      </div>
    </div>
  );
};

const populateTable = (
  guests,
  price,
  currency,
  discount,
  tax,
  check_in,
  check_out
) => {
  var tabledata = [];
  grossAmount = 0;
  // if(discount_obamount!==0)
  netamount = 0;
  for (var key in guests) {
    if (guests[key].length !== 0) {
      // console.log(key);
      var bed = "bed in " + key;
      // console.log(bed);
      var quant = 0;
      quant += guests[key].length;
      var non = noofdays(check_in, check_out);
      var quanty = quant.toString() + "X" + non; // no of nights
      // console.log(quanty);
      var rate = price[key];
      // console.log(rate);
      // console.log(typeof coupon);

      // console.log(discount);
      var gamount = quant * non * rate;
      // console.log(gamount);
      var gamount = parseFloat(gamount - gamount * (discount / 100));
      // console.log(tax)
      grossAmount += gamount;
      var gst = parseFloat(tax).toFixed(2);
      gst = parseFloat(gst);
      // console.log(tax)
      var amount = gamount + gamount * (gst / 100);
      netamount += amount;
      // console.log(amount);
      tabledata.push(
        <tr key={key}>
          <td style={{ textAlign: "left" }}>{bed}</td>
          <td>{quanty}</td>
          <td>{rate}.00</td>
          <td>{discount.toFixed(2)}</td>
          <td>{gamount.toFixed(2)}</td>
          <td>{gst}.00</td>
          <td style={{ textAlign: "right" }}>
            <span style={{ fontWeight: "bold" }}>{currency}</span>{" "}
            {amount.toFixed(2)}
          </td>
        </tr>
      );
    }
  }
  // if (discount_obamount > 0) {
  //   alert(grossAmount)
  //   grossAmount -= discount_obamount;
  // }
  return tabledata;
};
export const pdfsenderndownload = async (
  action,
  booking_details,
  paid_percent,
  phone_no,
  status
) => {
  // return;
  console.warn(phone_no);
  const doc = new jspdf(); // no arguments passed to jspdf as giving parmaters was giving blank  pdfs when send through email
  const data = yourpdf(booking_details, paid_percent);

  // console.log("creating", Date.now());
  //doc.setFont("sans-serif")
  await doc.html(renderToStaticMarkup(data));
  // .then((e) => console.log("Created", Date.now()));
  // doc.setFontSize(12);
  doc.setTextColor("#ffffff00");
  doc.textWithLink("www.bunkbread.com", 85, 290, {
    url: "https://www.bunkbread.com",
  }); // can be added using <a> in jsx.element above in yourpdf
  if (action === "email") {
    const attachment = doc.output();
    var pd = Buffer.from(attachment);
    //  pd.toString("base64") not producing image when send to the firebase functions and also the lenght was more
    pd = btoa(pd);
    // debugger;
    // console.log(pd.length);

    axios.post(
      `userdata/${status === "confirm" ? "confirmBooking" : "mailReciept"}`,
      {
        buff: booking_details.partial ? null : pd,
        email: booking_details.email,
        pnr: booking_details.pnr,
        partial: booking_details.partial,
        name: booking_details.name,
      }
    );
    return "success";
  } else doc.save(booking_details.receipt);
  return "success";
};
