let states = []

const mixedGuestDetails = (state = states, action) => {
    if(action.type === 'mixedGuestDetails'){
        return action.payload
    }
    else {
        return state
    }
}

export default mixedGuestDetails