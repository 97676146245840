import React from "react";
import { withStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";
// import Collapse from "@material-ui/core/Collapse";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import IconButton from "@material-ui/core/IconButton";
// import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
// import { AppBar } from "@material-ui/core";

const useStyles = (theme) => ({
  root: {
    display: "block",
    marginTop: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  expand: {
    display: "flex",
    padding: "10px 0",
    fontSize: "12px",
    color: "blue",
  },
  expandOpen: {
    display: "none",
  },
});

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { classes } = this.props;
    // const bull = <span className={classes.bullet}>•</span>;
    return (
      <React.Fragment>
        <section id="footer" className={this.props.bottom && "bottom"}>
          <Divider
            style={{
              margin: "15px 0 5px",
            }}
          />
          {/* <hr></hr> */}
          <div
            style={{
              margin: "10px 0",
              width: "100%",
            }}
            className="footer"
          >
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
              }}
            >
              Ⓒ bunkbread 2022
            </span>
            
            <div className="socialIcon">
              <div className="social">
                <a href="https://www.instagram.com/bunkbread_/">
                  {/* <i className="fab fa-instagram"></i> */}
                  <img src="/social/insta.png" />
                </a>
              </div>

              <div className="social">
                <a href="https://www.facebook.com/bunkbread">
                  {/* <i className="fab fa-facebook-square"></i> */}
                  <img
                    style={{ borderRadius: "4px" }}
                    src="/social/facebook.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Footer);
