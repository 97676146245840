import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableRow from "@material-ui/core/TableRow";
// import CallIcon from "@material-ui/icons/Call";
// import MailIcon from "@material-ui/icons/Mail";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Chip from "@material-ui/core/Chip";
// import WifiIcon from "@material-ui/icons/Wifi";
// import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
// import TvIcon from "@material-ui/icons/Tv";
// import BathtubIcon from "@material-ui/icons/Bathtub";
import Footer from "./footer";
const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    '& .MuiTab-textColorPrimary.Mui-selected ': {
      color : 'rgba(0,0,0,0.70)',},
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class tabsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      expanded: false,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleKnowHow = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };
  populateHow = () => {
    var listData = [];
    for (var data in Object.keys(this.props.know.how)) {
      // console.log(this.props.know.how[data])
      listData.push(
        <li
          style={{ fontSize: "13px", fontFamily: "Poppins" }}
          key={data}
        >
          {this.props.know.how[data]}
        </li>
      );
    }
    return listData;
  };
  populateWhere = () => {
    var listData = [];
    for (var data in Object.keys(this.props.know.where)) {
      listData.push(
        <li
          style={{ fontSize: "13px", fontFamily: "Poppins" }}
          key={data}
        >
          {this.props.know.where[data]}
        </li>
      );
    }

    return listData;};
  populateWhat = () => {
    var listData = [];
    for (var data in Object.keys(this.props.know.what)) {
      listData.push(
        <li
          style={{ fontSize: "13px", fontFamily: "Poppins" }}
          key={data}
        >
          {this.props.know.what[data]}
        </li>
      );
    }

    return listData;
  };

  populateAmenities = () => {
    var chipData = [];

    for (var data in Object.keys(this.props.amenities)) {
      chipData.push(<Chip label={this.props.amenities[data]} />);
    }
    return chipData;
  };
  populateDorms = () => {
    var listData = [];
    var listData = [];
    for (var data in Object.keys(this.props.dorms)) {
      listData.push(<li key={data}>{this.props.dorms[data]}</li>);
    }

    return listData;
  };
  render() {
    const { classes, know, amenities, location, dorms } = this.props;
    console.log(location);
    return (
      <React.Fragment>
        <Paper className={`handleSwitch width ${classes.root}`}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            textColor="primary"
            centered
            className="Switch"
            TabIndicatorProps={{ style: { background: "none" } }}
          >
            <Tab label="Know How" />
            <Tab label={location === "ecopulse_kurseong" ? "Stay" : "Dorm"} />
            <Tab label="Amenities" />
          </Tabs>

          <TabPanel className="table_P" value={this.state.value} index={0}>
            {/* <TableContainer className="table_W" component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.details}>
                      <TableCell component="th" scope="row">
                        {row.icon}
                      </TableCell>
                      <TableCell>{row.details}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
            <div className={classes.root}>
              <Accordion
                expanded={this.state.expanded === "panel1"}
                onChange={this.handleKnowHow("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                    className={classes.heading}
                  >
                    How to Reach
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    I am an accordion
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    {know !== null && know.how !== null ? (
                      <ul>{this.populateHow()}</ul>
                    ) : (
                      <ul>
                        <li
                          style={{
                            fontSize: "13px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            }}
                          >
                            Own bike/car:
                          </span>
                          &nbsp; TV Tower - Gatty’s Cafe -S.P.Bungalow -
                          bunkbread Darjeeling
                        </li>
                        <li
                          style={{
                            fontSize: "13px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            }}
                          >
                            Shared Cab:
                          </span>
                          &nbsp; Taxi Stand - Bata - Club stand - Keventer’s -
                          Chowrasta fountain - S.P. Bungalow - bunkbread
                          Darjeeling
                        </li>
                      </ul>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {location !== "ecopulse_kurseong" ? (
                <Accordion
                  expanded={this.state.expanded === "panel2"}
                  onChange={this.handleKnowHow("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                      }}
                      className={classes.heading}
                    >
                      Where To
                    </Typography>
                    {/* <Typography className={classes.secondaryHeading}>
                    You are currently not an owner
                  </Typography> */}
                  </AccordionSummary>
                  <AccordionDetails>
                  <Typography component="div">
                    {know !== null && know.where !== null ? (
                      <ul>{this.populateWhere()}</ul>
                    ) : (
                      <ul>
                        <li
                          style={{
                            fontSize: "13px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            }}
                          >
                            Own bike/car:
                          </span>
                          &nbsp; TV Tower - Gatty’s Cafe -S.P.Bungalow -
                          bunkbread Darjeeling
                        </li>
                        <li
                          style={{
                            fontSize: "13px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            }}
                          >
                            Shared Cab:
                          </span>
                          &nbsp; Taxi Stand - Bata - Club stand - Keventer’s -
                          Chowrasta fountain - S.P. Bungalow - bunkbread
                          Darjeeling
                        </li>
                      </ul>
                    )}
                  </Typography>
                </AccordionDetails>
                  {/* <AccordionDetails>
                    <Typography component="div">
                      <ul>
                        <li
                          style={{
                            fontSize: "13px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Sonam’s Kitchen Tom & Jerry Nerdvana Gatty’s Cafe
                          Kathmandu Kitchen Kunga Keventer’s Glenaries{" "}
                        </li>
                      </ul>
                    </Typography>
                  </AccordionDetails> */}
                </Accordion>
              ) : null}
              <Accordion
                expanded={this.state.expanded === "panel3"}
                onChange={this.handleKnowHow("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                    className={classes.heading}
                  >
                    What to Do
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Filtering has been entirely disabled for whole web server
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    {know !== null && know.what !== null ? (
                      <ul>{this.populateWhat()}</ul>
                    ) : (
                      <ul>
                        <li
                          style={{
                            fontSize: "13px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Day trek to nearby places Weekly workshops Thrift
                          shopping Tea bar hopping
                        </li>
                      </ul>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Accordion
                expanded={this.state.expanded === "panel4"}
                onChange={this.handleKnowHow("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.heading}>
                    Personal data
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Nunc vitae orci ultricies, auctor nunc in, volutpat nisl.
                    Integer sit amet egestas eros, vitae egestas augue. Duis vel
                    est augue.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </div>
          </TabPanel>
          <TabPanel value={this.state.value} index={1}>
            <div className={classes.root}>
              <List component="nav" aria-label="main mailbox folders">
                {dorms === null ? (
                  <>
                    <ListItem button>
                      <ListItemIcon>
                        <SupervisorAccountIcon />
                      </ListItemIcon>
                      <ListItemText primary="6 Bed Mix-Dorm with common toilet" />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <PermIdentityIcon />
                      </ListItemIcon>
                      <ListItemText primary="6 bed Female-Dorm with common toilet" />
                    </ListItem>
                  </>
                ) : (
                  <Typography style={{fontFamily: "poppins"}}>
                  {this.populateDorms()}</Typography>
                )}
              </List>
            </div>
          </TabPanel>
          <TabPanel value={this.state.value} index={2}>
            {/* <Chip icon={<WifiIcon />} label="Wifi" />
            <Chip icon={<FreeBreakfastIcon />} label="Breakfast" />
            <Chip icon={<TvIcon />} label="Tv" />
            <Chip icon={<BathtubIcon />} label="Bathtub" /> */}
            {amenities !== null ? this.populateAmenities() : null}
          </TabPanel>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(tabsDetail);
