import React from "react";

class Error extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className={`alert alert-danger container ${
            this.props.Visible ? "" : "display-none"
          }`}
          role="alert"
        >
          {this.props.text}
        </div>
      </React.Fragment>
    );
  }
}

export default Error;
