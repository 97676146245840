import React from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "../../apis/axios";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import { updateHotspot } from "../../actions";
import { hotspotDetails, hotspotSelection } from "../../actions";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import history from "../../helpers/history";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import { SportsRugbySharp } from "@material-ui/icons";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
const moment = require("moment-timezone");

const useStyles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "95%",
      fontFamily: "sans-serif",
    },
     '& label.Mui-focused': {
      color: '#ffde17',//rgba(0, 0, 0, 0.54)',
    },
     '& .MuiOutlinedInput-root': {
      '& fieldset': {
        
      },
      '&:hover fieldset': {
        borderColor: '#ffde17',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffde17',
      },
    }, 

    /** '&  MuiList-root.MuiMenu-list.MuiList-padding':{
      top : "1px",
      bottom :"1px",
    },*/
  },
});
// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: '#fff',
//   },
// }));
//const classes = useStyles();
const theme1 = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#ffde17',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#121f12',
    },
  },
});


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const formValid = ({ formErrors, location_id, check_in, check_out }) => {
  let valid = true;

  if (location_id === "") {
    valid = false;
    formErrors.location_id = "please select a valid location";
  }

  if (check_in === null) {
    valid = false;
    formErrors.check_in = "please select a valid check in date";
  }

  if (check_out === null) {
    valid = false;
    formErrors.check_out = "please select a valid check out date";
  }

  return valid;
};

class Hotspot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location_id: "",
      check_in: null,
      check_out: null,
      clicked: false,
      redirect: false,
      onprocess: false,
      backdrop: false,
      text: "",
      hotspots: [],
      open: false,
      formErrors: {
        location_id: "",
        check_in: "",
        check_out: "",
      },
    };
  }

  componentDidMount() {
    // alert("WOW")
    const getData = async () => {
      if (this.props.hotspotLocation.length === 0) {
        let res = await axios.get("/hotspot/get");
        this.setState({ hotspots: res.data });
        this.props.hotspotDetails(res.data);
      }
      this.setState({ hotspots: this.props.hotspotLocation });
    };

    const today = moment.tz("Asia/Calcutta").format("YYYY-MM-DD");

    if (localStorage.getItem("hotspot_location_id") !== null) {
      this.setState({
        location_id: localStorage.getItem("hotspot_location_id"),
      });
    }
    if (localStorage.getItem("hotspot_check_in") !== null) {
      const check_in = localStorage.getItem("hotspot_check_in");
      moment(check_in).isSameOrAfter(today)
        ? this.setState({ check_in: localStorage.getItem("hotspot_check_in") })
        : this.setState({
            check_in: today,
          });
    }

    if (
      localStorage.getItem("hotspot_check_in") &&
      localStorage.getItem("hotspot_check_out")
    ) {
      moment(localStorage.getItem("hotspot_check_in")).isSameOrAfter(
        moment.tz("Asia/Calcutta").format("YYYY-MM-DD")
      )
        ? this.setState(
            {
              check_out: localStorage.getItem("hotspot_check_out"),
            },
            () => {
              if (window.location.pathname === "/hotspot") this.explore();
            }
          )
        : this.setState(
            {
              check_out: moment(moment.tz("Asia/Calcutta").format("YYYY-MM-DD"))
                .add(1, "day")
                .endOf("day")
                .format("YYYY-MM-DD"),
            },
            () => {
              if (window.location.pathname === "/hotspot") this.explore();
            }
          );
    }
    if (
      localStorage.getItem("hotspot_check_in") !== null ||
      localStorage.getItem("hotspot_check_out") !== null ||
      localStorage.getItem("hotspot_location_id") !== null
    ) {
      this.setState({ clicked: true });
    }
    getData();

    //Across Refresh
    window.location.pathname === "/hotspot" &&
      this.setState(
        { location_id: localStorage.getItem("hotspot_location_id") },
        () => {
          this.explore();
        }
      );
    // getData()
  }

  componentDidUpdate() {
    if (window.location.pathname === "/hotspot") {
      moment(this.state.check_in).isSameOrAfter(this.state.check_out) &&
        this.setState(
          {
            check_out: moment(this.state.check_in)
              .add(1, "day")
              .endOf("day")
              .format("YYYY-MM-DD"),
          },
          () => {
            this.explore();
          }
        );
    } else {
      moment(this.state.check_in).isSameOrAfter(this.state.check_out) &&
        this.setState({
          check_out: moment(this.state.check_in)
            .add(1, "day")
            .endOf("day")
            .format("YYYY-MM-DD"),
        });
    }

    localStorage.getItem("currency") &&
      this.state.currency !== localStorage.getItem("currency") &&
      this.setState({ currency: localStorage.getItem("currency") });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  handleFromDateChange = (date) => {
    const { formErrors } = this.state;
    formErrors.check_in = "";
    let newd = moment(date).add(330, "m").toDate();
    let newd2 = moment(date).add(330, "m").toDate();
    newd2 = moment(newd2).add(1, "day").toDate();

    window.location.pathname === "/hotspot"
      ? this.setState(
          { check_in: newd.toISOString().slice(0, 10), onprocess: true },
          () => {
            if (window.location.pathname === "/hotspot") {
              if (this.state.check_out < this.state.check_in) {
                this.setState(
                  { check_out: newd2.toISOString().slice(0, 10) },
                  () => {
                    this.explore();
                  }
                );
              }
              else {
                //this.setState({ backdrop: true});
                this.explore();
              //console.log("backdrop"+this.state.backdrop);
              //setTimeout(() => {
                this.setState({ onprocess: true});
              //}, 500);
            }
            }
            //backdrop: true 
            //console.log("backdrop"+this.state.backdrop);
            setTimeout(() => {
              this.setState({ onprocess: false});
            }, 200);
            //console.log("backdrop true"+this.state.backdrop);
          }
        )
      : this.setState({ check_in: newd.toISOString().slice(0, 10) });

    // this.setState({ check_in: newd.toISOString().slice(0, 10) }, () => {if(window.location.pathname === '/hotspot')this.explore()});
    localStorage.setItem("hotspot_check_in", newd.toISOString().slice(0, 10));
  };

  handleToDateChange = (date) => {
    const { formErrors } = this.state;
    formErrors.check_out = "";
    let newd = moment(date).add(330, "m").toDate();

    window.location.pathname === "/hotspot"
      ? this.setState(
          { check_out: newd.toISOString().slice(0, 10), onprocess: true },
          () => {
            this.explore();
            setTimeout(() => {
              this.setState({ onprocess: false });
            }, 2000);
          }
        )
      : this.setState({ check_out: newd.toISOString().slice(0, 10) });

    // this.setState({ check_out: newd.toISOString().slice(0, 10) }, () => {if(window.location.pathname === '/hotspot')this.explore()});
    localStorage.setItem("hotspot_check_out", newd.toISOString().slice(0, 10));
  };

  handleChange = (event) => {
    const { formErrors } = this.state;
    formErrors.location_id = "";
    window.location.pathname === "/hotspot"
      ? this.setState(
          { location_id: event.target.value, onprocess: true },
          () => {
            this.explore();
            setTimeout(() => {
              this.setState({ onprocess: false });
            }, 2000);
          }
        )
      : this.setState({ location_id: event.target.value });

    for (let i = 0; i < this.state.hotspots.length; i++) {
      if (this.state.hotspots[i].location_id === event.target.value) {
        localStorage.setItem(
          "price",
          JSON.stringify(this.state.hotspots[i].price)
        );
        localStorage.setItem(
          "price_USD",
          JSON.stringify(this.state.hotspots[i].price_USD)
        );
        localStorage.setItem(
          "price_EUR",
          JSON.stringify(this.state.hotspots[i].price_EUR)
        );
        localStorage.setItem("tax", JSON.stringify(this.state.hotspots[i].tax));
        localStorage.setItem(
          "bed_type",
          JSON.stringify(this.state.hotspots[i].bed_type)
        );
        localStorage.setItem("hotspot_location_id", event.target.value);
        localStorage.setItem(
          "hotspot_location",
          this.state.hotspots[i].location
        );
        if (window.location.pathname === "/hotspot") this.explore();
      }
    }
    this.props.hotspotSelection([0, 0, 0, 0, 0, 0, 0]);
  };

  explore = () => {
    if (formValid(this.state)) {
      if (window.location.pathname !== "/hotspot") history.push("/hotspot");
      this.props.updateHotspot(
        this.state.location_id,
        this.state.check_in.slice(2),
        this.state.check_out.slice(2)
      );
    } else {
      this.setState({
        open: true,
        text: "Please Enter All Inputs",
      });
    }
  };

  render() {
    const { classes } = this.props;
    if (Object.keys(this.state.hotspots).length === 0 || this.state.onprocess) {
      return (
        <React.Fragment>
          <div>
          <Backdrop
           //key={key}
           style={{ position: "absolute", zIndex: "100" }}
           open={this.state.backdrop}
         >
          <CircularProgress color="inherit" />
        </Backdrop>
        </div>
          <Skeleton
            variant="rect"
            style={{ margin: "auto", marginTop: "10px", width: "90%" }}
            height={75}
          />
          <Skeleton
            style={{ margin: "auto", marginTop: "10px", width: "90%" }}
            variant="text"
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div
          className={`location ${classes.root}`}
          style={{ marginTop: "15px" }}
        >
          <TextField
            onClick={() => this.setState({ clicked: true })}
            id="standard-select-currency"
            select
            label="Location"
            size="small"
            value={this.state.location_id}
            onChange={this.handleChange}
            variant="outlined"
            //fontFamily="sans-serif !important"
            //color="primary"
          >
            {this.state.hotspots.map((option) => (
              <MenuItem key={option.location_id} value={option.location_id} >
                {option.location}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <ThemeProvider theme={theme1}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid
            className={` ${this.state.clicked ? "" : "display-none"}`}
            style={{ width: "95%", margin: "auto", }}
            container
            spacing={3}
          >
            <Grid style={{ padding: "0 8px" }} item xs={6}>
              <DatePicker
                style={{ margin: "8px 0" }}
                margin="normal"
                id="date-picker-from"
                label="From"
                format="dd/MM/yyyy"
                disablePast
                value={this.state.check_in}
                inputVariant="outlined"
                size="small"
                maxDate={moment(moment.tz("Asia/Calcutta").format("YYYY-MM-DD"))
                  .add(90, "day")
                  .endOf("day")
                  .format("MM/DD/YYYY")}
                allowKeyboardControl={false}
                onChange={this.handleFromDateChange}
              />
            </Grid>
            <Grid style={{ padding: "0 8px" }} item xs={6}>
              <DatePicker
                style={{ margin: "8px 0" }}
                margin="normal"
                id="date-picker-to"
                label="To"
                format="dd/MM/yyyy"
                value={this.state.check_out}
                inputVariant="outlined"
                size="small"
                minDate={moment(this.state.check_in)
                  .add(1, "day")
                  .endOf("day")
                  .format("MM/DD/YYYY")}
                maxDate={moment(moment.tz("Asia/Calcutta").format("YYYY-MM-DD"))
                  .add(91, "day")
                  .endOf("day")
                  .format("MM/DD/YYYY")}
                onChange={this.handleToDateChange}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        </ThemeProvider>
        <div
          className={`explore-btn ${this.props.exploreNone && "display-none"}`}
        >
          <Button
            style={{
              width: "91%",
              backgroundColor: "#ffde17",
              fontFamily: "Poppins",
            }}
            onClick={this.explore}
            variant="contained"
          >
            Explore
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert
            style={{ width: "95%" }}
            onClose={this.handleClose}
            severity="error"
          >
            {this.state.text}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Destinations: Object.values(state.availableDestinations),
    avail: state.availReducer,
    hotspotLocation: state.hotspotDetails,
    history: state.history,
  };
};

const styles = withStyles(useStyles)(Hotspot);

export default connect(mapStateToProps, {
  updateHotspot,
  hotspotDetails,
  hotspotSelection,
})(styles);
// export default connect(mapStateToProps, { updateHotspot, hotspotDetails })(Hotspot);
