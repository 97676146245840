import React from "react";
import EditForm from "./editForm";
import { connect } from "react-redux";
import { userProfile, edit } from "../../actions";
import Button from "@material-ui/core/Button";
import firebase from "firebase";
import history from "../../helpers/history";
import { LinearProgress } from "@material-ui/core";

const state = {};

class ProfileEdit extends React.Component {
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((id) => this.props.userProfile(id));
      } else {
        history.push("/login");
      }
    });
  }

  change = ({ name, value }) => {
    state[name] = value;
  };

  Onsubmit = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((id) => this.props.edit(id, state));
      }
    });
  };

  render() {
    if (!this.props.user.profile) {
      return (
        <div>
          <LinearProgress style={{ backgroundColor: "yellow" }} />
          <div style={{ display: "absolute", margin: "50% 45%" }}>
            Loading&nbsp;Wait
          </div>
        </div>
      );
    }

    const { profile } = this.props.user;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "40px",
        }}
      >
        {Object.entries(profile).map((val) => {
          if (val[0] === "name")
            return (
              <EditForm
                key={val[0]}
                name={val[0]}
                change={this.change}
                value={val[1]}
              />
            );
        })}
        <Button
          style={{ height: "50%", margin: "auto" }}
          onClick={this.Onsubmit}
          variant="contained"
          color="inherit"
        >
          Done
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userProfile,
  };
};

export default connect(mapStateToProps, { userProfile, edit })(ProfileEdit);
