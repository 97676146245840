import React from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
var i = 0;
class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Snackbar: true,
    };
  }

  componentDidMount() {
    const change = () => {
      var doc = document.getElementById("banner");
      var text = Object.values(this.props.offers);
      // doc.style.backgroundColor = color[i];
      doc.innerHTML = text[i];
      i = (i + 1) % text.length;
    };
    this.set = setInterval(change, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.set);
  }

  render() {
    const length = Object.values(this.props.offers).length;
    // console.log(this.props.offers);
    return (
      <React.Fragment>
        <div style={{ display: !this.state.Snackbar && "none" }}>
          <CloseIcon
          className= "crossIcon"
            onClick={() => this.setState({ Snackbar: false })}
            style={{
              position: "absolute",
              right: "8px",
              color: "#ffde17",
              top: "13px",
            }}
          />
          <SnackbarContent
            style={{
              fontFamily: "poppins",
              minHeight: "40px",
              color: "#ffde17",
              fontWeight: "200",
              borderTopLeftRadius: "0",
              borderTopRightRadius: "0",
            }}
            id="banner"
            message={this.props.offers[length]}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Banner;
