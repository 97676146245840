import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "../helpers/history";
import { connect } from "react-redux";
import landing from "./landing";
// import login from "./login";
import bookNow from "./bookNow";
// import axios from "axios";
import login2 from "./login2";
import GuestDetails from "./GuestDetails";
import Summary from "./Summary";
// import * as actions from "../actions";
import { UserContext } from "../providers/UserProvider";
import admin from "./admin";
import explore from "./static/explore";
import profile from "./profile";
import userbooking from "./userBookings";
import profileEdit from "./static/profileEdit";
import navbar from "./static/navbar/navbarMui";
import franchise from "./franchise";
import authreq from "./authreq";
import destinations from "./destinations";
import W_Summary from "./WorkpadSummary";
import SetupProfile from "./setupprofile";
import aboutUs from "./aboutUs";
import Hotspots from "./static/exploreHotspots";
import PDF from "./static/retest";
// import Navbar from "./static/navbar/navbarMui";
import BookHotspot from "./BookHotspot";
import hsummary from "./hsummary";
import Page404 from "./static/404";
import Contactus from "./static/contactus";
// import asset from "../well/assetlinks.json"
//import { AuthProvider } from "../context/AuthContext";
// axios.defaults.headers.common["x-api-key"] = 12345;
// axios.defaults.baseURL =
//   "https://us-central1-test-git-ci-6ea81.cloudfunctions.net/payserver";

class App extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = { username: String, uid: String };
  }
  componentDidMount() {
    const user = this.context;
    // console.log(asset)
  }
  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={landing} />
            {/* <Route path="/"  component={navbarMui} /> */}
            <Route path="/login" exact component={login2} />
            {/* <Route path="/signup" exact component={login} /> */}
            <Route path="/booknow" exact component={bookNow} />
            <Route path="/workpad" exact component={bookNow} />
            <Route path="/details" exact component={GuestDetails} />
            {/* <Route path="/login/passwordreset" exact component={passreset} /> */}
            <Route path="/summary" exact component={Summary} />
            <Route path="/workpadsummary" exact component={W_Summary} />
            <Route path="/admin" exact component={admin} />
            <Route path="/explore/:location" exact component={explore} />
            <Route path="/profile" exact component={profile} />
            <Route path="/bookings/:id" exact component={userbooking} />
            <Route path="/edit" exact component={profileEdit} />
            <Route path="/test" exact component={PDF} />
            <Route
              path="/setupprofile/:userId"
              exact
              component={SetupProfile}
            />
            <Route path="/navbar" exact component={navbar} />
            <Route path="/franchise" exact component={franchise} />
            <Route path="/destinations" exact component={destinations} />
            <Route path="/aboutus" exact component={aboutUs} />
            <Route path="/explorehotspots" exact component={Hotspots} />
            <Route path="/auth/:userId/:type" exact component={authreq} />
            <Route path="/hotspot" exact component={BookHotspot} />
            <Route path="/hsummary" exact component={hsummary} />
            <Route path="/404" exact component={Page404} />
            <Route path="/contactus" exact component={Contactus} />
            <Route component={Page404} />
            {/* <Route path="/assetlinks.json" exact component={asset}></Route> */}
          </Switch>
        </Router>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state);
  return state;
};

export default connect(mapStateToProps)(App);
