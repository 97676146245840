import React from "react";
import CancleBooking from "./static/cancleBooking";
import { userBookings } from "../actions";
import { connect } from "react-redux";
import firebase from "firebase";
import history from "../helpers/history";
import { LinearProgress } from "@material-ui/core";
import NavbarMui from "./static/navbar/navbarMui";

import cancelIMG from "../assets/cancle.jpeg";
import upcomingIMG from "../assets/upcoming.jpeg";
class Bookings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.match.params.id,
      onprocess: false,
    };
  }

  componentDidMount() {
    this.setState({ onprocess: true });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then(async (id) => {
          const status = await this.props.userBookings(id, this.state.location);
          status === "DONE" &&
            setTimeout(() => {
              this.setState({ onprocess: false });
            }, 500);
        });
      } else {
        history.push("/login");
      }
    });
  }

  componentDidUpdate() {
    //this.setState({onprocess:true})
    if (this.state.location !== this.props.match.params.id) {
      this.setState({ location: this.props.match.params.id, onprocess: true });
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          {
            user.getIdToken().then(async (id) => {
              const result = await this.props.userBookings(
                id,
                this.state.location
              );
              result === "DONE" &&
                setTimeout(() => {
                  this.setState({ onprocess: false });
                }, 500);
            });
          }
        } else {
          history.push("/login");
        }
      });
    }
    // this.setState({onprocess:false})
  }

  images = () => {
    switch (this.props.match.params.id) {
      case "previous":
        return upcomingIMG;
      case "deleted":
        return cancelIMG;
      case "upcoming":
        return upcomingIMG;
      default:
        return;
    }
  };

  renderItem = () => {
    // console.log(Object.keys(this.props.bookings).length === 0);
    let inr = null;
    // console.log(this.props.bookings);
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.hasOwnProperty("inr_id") &&
      this.props.location.state.inr_id !== null
    ) {
      inr = this.props.location.state.inr_id;
      // this.props.location.state = undefined;
      // console.log(inr);
    }

    return Object.values(this.props.bookings).map((booking) => {
      // console.log("details of booking", booking)
      return (
        <CancleBooking
          params={this.props.match.params.id}
          key={booking.docId}
          item={booking}
          notify={
            booking.docId === inr
              ? { toOpen: true, id: booking.docId }
              : { toOpen: false }
          }
        />
      );
    });
  };

  render() {
    if (!this.props.bookings) {
      return (
        <React.Fragment>
          <NavbarMui />
          <LinearProgress
            style={{
              position: "sticky",
              //top:"10vh",
              backgroundColor: "#ffde17",
              zIndex: "999",
            }}
          />
          {/* <div style={{ display: "absolute", margin: "50% 45%" }}>
            Loading...
          </div> */}
        </React.Fragment>
      );
    }

    if (this.state.onprocess) {
      return (
        <React.Fragment>
          <NavbarMui />
          <LinearProgress
            style={{
              position: "sticky",
              //top:"10vh",
              backgroundColor: "#ffde17",
              zIndex: "999",
            }}
          />
          {/* <div style={{ display: "absolute", margin: "50% 45%" }}>
            Loading...
          </div> */}
        </React.Fragment>
      );
    }
    // console.log(this.state.location);
    // console.log(this.state.location, this.props.location);

    return (
      <React.Fragment>
        <NavbarMui />
        {this.props.match.params.id === "previous" ? (
          <h3
            style={{
              color: "#ffde17",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "14px",
              opacity: "1",
            }}
          >
            Previous Bookings
          </h3>
        ) : this.props.match.params.id === "deleted" ? (
          <h3
            style={{
              color: "#ffde17",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "14px",
              opacity: "1",
            }}
          >
            Cancelled Bookings
          </h3>
        ) : (
          <h3
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "14px",
              opacity: "1",
              color: "#ffde17",
            }}
          >
            Upcoming Bookings
          </h3>
        )}
        {Object.keys(this.props.bookings).length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "100%" }} src={this.images()} />
          </div>
        ) : (
          this.renderItem()
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    history: state.history,
    bookings: state.userBookings,
  };
};

// const styles = withStyles(useStyles)(Bookings);

export default connect(mapStateToProps, { userBookings })(Bookings);
