import React from "react";

class PP extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div>
          Privacy policy is required by the law and this specific policy only
          applies to bunkbread.com and not to the websites/portals of any other
          companies, individuals or organisations to whom we provide links to
          our websites.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Use of Your Information
        </div>
        <div>
          We collect personal information for identification and safety of our
          guests, to understand who we are accommodating at our properties, to
          submit for police verification, and for the safety of our staff. In
          addition, website user and guest data is collected for statistical
          purposes. We also collect our guests’ age,gender, for statistical
          analysis purposes. When you visit our websites, we may automatically
          log your IP address (the unique address which identifies your computer
          on the internet). We use IP addresses to help us manage our websites
          and to collect broad demographic information for analytical use. For
          reservations, we send guests confirmation emails and will therefore
          require your email address. Exceptions may occur in the case of us
          needing to contact previous guests in relation to post or lost
          belongings.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Reservation Data
        </div>
        <div>
          In order for us to confirm a reservation for you, we do require some
          information. This will usually consist of: 1.)Guests name 2.)Telephone
          or mobile number – in case of an emergency 3.)Gender 4.)Age
          5.)Identification data i.e. Passport, Driving License,Voter ID(any)
          details. Aadhaar and PAN card not accepted. 6.)Date of arrival and
          departure 7.)Email address Upon arrival, we will cross check the same
          information from the fellow travellers. Upon arrival, we will cross
          check the same information from the fellow travellers.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Personal Data
        </div>
        <div>
          bunkbread respects guest privacy and will not sell or disclose guests’
          personal information to any other person, business or third party
          unless in the case of an emergency and/or it is seen as part of our
          duty of care.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Keeping Guests' Information Updated
        </div>
        <div>
          As an accommodation and leisure provider, we have guests returning to
          our properties on a regular basis. It is your duty to inform us if any
          of your personal information, which we hold about you, needs to be
          updated. We may contact you at any time, if you have booked
          accommodation with us and we suspect we hold false information about
          you.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Website Security
        </div>
        <div>
          The Internet is not a secure medium. However, we have put in place
          various security procedures, including firewalls that are used to
          block unauthorized traffic to our website.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Photography and Film
        </div>
        <div>
          No permission is needed to take photos or film at our properties.
          However, we do recommend asking for permission before photographing or
          filming other guests who are not part of your group. Verbal consent is
          solicited as a goodwill gesture. On occasions we may commission crews
          to film or take photographs at our properties for promotional
          purposes. If you do not wish to be filmed or photographed, you are
          required to voluntarily leave the filming or photography area.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Disclosing Guests' Personal Information to Third Parties
        </div>
        <div>
          Other than that for the purposes referred to in this policy, we will
          not disclose any personal information without your permission unless
          we are legally obliged to do so (for example, if required to do so by
          court order or for the purposes of prevention of fraud).
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Your Rights
        </div>
        <div>
          By submitting your information to us, you consent to the use of that
          information as set out in this Privacy Policy. You may request at any
          time that we provide you with the personal information we hold about
          you. You may also choose to add, modify or delete information about
          you stored with us. Provision of such information will be subject to
          proving your identity and full address with a utility bill and
          acceptable photo ID. For any such requests, please reach out to us on
          teambunkbread@gmail.com
        </div>

        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Changes to Our Privacy Policy
        </div>
        <div>
          We may change our Privacy Policy at any time. Continued use of our
          website signifies that you agree to any such changes.
        </div>
      </React.Fragment>
    );
  }
}

export default PP;
