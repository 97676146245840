let states = []

const femaleGuestDetails = (state = states, action) => {
    if (action.type === 'femaleGuestDetails') {
        console.log(action.payload,"REDUCERSS")
        return action.payload
    }
    else {
        return state
    }
}

export default femaleGuestDetails