import React from "react";
import Navbar from "./static/navbar/navbarMui";
import Booking from "./static/booking";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import Detail from "./static/detail";
import Footer from "./static/footer";
import { notification, offers, LiveSoon, hotspotSelection } from "../actions";
import { connect } from "react-redux";
import { LinearProgress, Popover } from "@material-ui/core";
import Switch from "./static/Switch";
import Banner from "./static/Banner";
//import InfoText from "./static/infoText";
import HotspotDetails from "./static/hotspotDetail";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import { hotspotSelection } from "../actions";
import { Snackbar, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Liveimg from "../assets/livesoon.jpg";
import history from "../helpers/history";
import { auth } from "../firebase";
// import { addNotifyData } from "../helpers/addNotifications";
import axios from "../apis/axios";
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
var historyunblocker;
var activatedhistory = 0;
var backlistner;


class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opensnackbar: false,
      error_message: "",
      severity: "error",
      backcounter: 0,
      openExit: false,
    };
  }
  
  handelsnackclose = () => {
    //event.preventDefault();
    // console.log(this.props.location);
    this.props.location.state = undefined;
    this.setState({
      opensnackbar: false,
      error_message: "",
      // nameError: false,
      // emailError: false,
      // severity:"error"
    });
  };
  componentWillUnmount() {
    backlistner();

    // if(activatedhistory!=0)
    // { alert()
    //   historyunblocker()
    //   activatedhistory=0
    // }
  }

  componentDidMount() {
   

    // window.close()
    backlistner = auth.onAuthStateChanged((user) => {
      // console.log(user.uid);
      if (user !== null) {
        var dataBook = JSON.parse(localStorage.getItem("notifyBookdata"));
        // console.log(dataBook);
        if (dataBook !== null) {
          // alert("pending Booking");
          //add notifcation
          // alert("sending request")
          axios
            .post("/userdata/addBookingNotification", {
              data: dataBook,
              id: user.uid,
            })
            .then((res) => {
              // alert("wowwww")
              console.log(res.data);
              if (res.data === "success") {
                localStorage.removeItem("notifyBookdata");
              }
            })
            .catch((e) => {
              // console.log(e)
            });
        }
      }
    });
    // history.go(0)
    // console.log(history);

    if (localStorage.getItem("lastUpadated") === null) {
      let date = new Date();
      localStorage.setItem("lastUpadated", date.toISOString());
    } else {
      let date = new Date();
      let date2 = new Date(localStorage.getItem("lastUpadated"));
      if (Math.abs(date2 - date) / 1000 >= 600) {
        localStorage.clear();
        let date3 = new Date();
        localStorage.setItem("lastUpadated", date3.toISOString());
      }
    }
    this.props.notification();
    this.props.offers();
    this.props.hotspotSelection([0, 0, 0, 0, 0, 0, 0]);
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.hasOwnProperty("prev")
    ) {
      if (this.props.location.state.prev === "login") {
        // console.log(this.state.location);
        this.setState({
          opensnackbar: true,
          severity: "success",
          error_message: "Log-in Successful",
        });
      } else if (this.props.location.state.prev === "setupprofile") {
        // console.log(this.state.location);
        this.setState({
          opensnackbar: true,
          severity: "success",
          error_message: "Profile Updated Successfully",
        });
      }
    }
  }
  setLiveSoon = () => this.props.LiveSoon(false);

  // componentDidMount(){
  //   // const frm = document.createElement("form")
  //   // const script = document.createElement("script")
  //   // script.src = "https://checkout.razorpay.com/v1/payment-button.js"
  //   // script.async=true;
  //   // script.setAttribute("data-payment_button_id","pl_GgNNvXMjMgSzmO");
  //   // frm.appendChild(script)
  //   // document.body.appendChild(frm)
  // }
  render() {
    console.log(this.props.Livesoon);
    if (
      this.props.Notification.text === "NO TEXT" ||
      this.props.Offers.offers === "NO OFFER"
    ) {
      return (
        <React.Fragment>
          <Navbar notification={this.props.Notification} />
          <LinearProgress />
        </React.Fragment>
      );
    }
  
    return (
      
      <div >
        {this.props.Offers.show && <Banner offers={this.props.Offers.offers} />}
        <Navbar notification={this.props.Notification} />

        <Dialog open={this.state.openExit}>Hi</Dialog>
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.opensnackbar}
          onClose={this.handelsnackclose}
          style={{
            //display: "none",
            marginBottom: "4rem",
          }}
        >
          <Alert
            variant="filled"
            elevation={6}
            style={{ width: "90vw" }}
            severity={this.state.severity}
            action={
              <IconButton
                aria-label="close"
                //color="inherit"
                onClick={this.handelsnackclose}
                size="small"
                style={{ color: "inherit" }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {this.state.error_message}
          </Alert>
        </Snackbar>
        <Switch />
        {/* <Booking landing={true} /> */}
       { /* <InfoText />  */}
       <Detail />
        <HotspotDetails />
        {/* <Dialog
          className="Livepopup"
          open={this.props.Livesoon}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              color: "#ffde17",
              fontSize:"30px",
              zIndex: "1",
            }}
            onClick={this.setLiveSoon}
          />
          <div style={{ margin: "auto" }}>
            {/* <div style={{ textAlign: "center" }}>
              <img
                className="liveIMAGE"
                style={{ paddingTop: "0px" }}
                src={Liveimg}
              />
            </div> 

            <Typography
              style={{
                padding: "25px",
                textAlign: "left",
                fontFamily: "poppins",
                fontSize: "18px",
                color: "#3d3d3d",
                fontWeight: "400",
              }}
            >
              <span style={{fontSize: "18px", fontWeight: "600", paddingBottom: "15px", textAlign: "center", display: "block"}}>COVID-19 INFO</span>
              <span style={{fontSize: "14px", paddingBottom: "10px",textAlign: "center", display: "block"}}>Adventurers from outside West Bengal must carry:
</span>
<span style={{fontSize: "12px", paddingBottom: "10px", textAlign: "center", display: "block"}}>

  <p style={{margin: "0px"}}>Full vaccination certificate</p>
  <p style={{margin: "0px"}}>OR</p>
  <p style={{margin: "0px"}}>72 hrs fresh RTPCR -ve report</p>
 
</span>
<span style={{fontSize: "14px", paddingTop: "20px",paddingBottom: "20px", textAlign: "center", display: "block"}}>
Adventurers from within West Bengal have no mandation as of now.
</span>
<span style={{fontSize: "14px", textAlign: "center", paddingBottom: "20px", display: "block"}}>
Our HOTSPOTs take ample COVID precautions to make your stay hygeinic and safe.
</span>
<span style={{fontSize: "14px", textAlign: "center", display: "block"}}>
Adventurers are advised to wear masks and avoid crowded areas.</span>


            </Typography>
          </div>
        </Dialog> */}
        <Footer Workpad_none />
      </div>
     
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Notification: state.notification,
    Offers: state.offers,
    Livesoon: state.livesoon,
  };
};

export default connect(mapStateToProps, {
  notification,
  offers,
  hotspotSelection,
  LiveSoon,
})(Landing);
