import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
//import "firebase/messaging";
const fireapp = firebase.initializeApp({
  // use config only for local else comment it
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
});
// console.log(fireapp)
export const db = fireapp.firestore();
export const auth = fireapp.auth();
// const message = fireapp.messaging()
// message.getToken({vapidKey:"BCMwLriFV9FStbCpaZ_HwozpC8wRW3fEOM-wmmM7HLXpXlsdNCfe2fOXmSqfRIS75IN12fkeqA4jemkK8n9LaFA"}).then(token => {
//   console.log(token)
// })
auth
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    // console.log("Persistence Local")
  })
  .catch((e) => {
    // console.log("Error persisiting local")
  });
//export const functions = fireapp.functions()
export default fireapp;
