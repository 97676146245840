import React from "react";
import Navbar from "./static/navbar/navbarMui";
import Booking from "./static/booking";
import Dorms from "./static/dorms";
import Footer from "./static/footer";
import CheckOutSteps from "./static/checkoutSteps";
import { connect } from "react-redux";
import { update_available } from "../actions";
import Workpad from "./static/workpad";
class bookNow extends React.Component {
  render() {
    // if (!this.props.availableNow.mixed_dorm_available) {
    //   return <div>Loading...</div>;
    // }
    return (
      <React.Fragment>
        <Navbar />
        <CheckOutSteps step1 />
        {window.location.pathname === "/booknow" ? (
          <Booking exploreNone />
        ) : (
          <Workpad exploreNone />
        )}
        {window.location.pathname === "/booknow" ? (
          <Dorms
            //female={this.props.availableNow.female_dorm_available}
            mixed={this.props.availableNow.mixed_dorm_available}
            load={this.load}
          />
        ) : (
          <Dorms
            //female={this.props.availableNow.female_dorm_available}
            mixed={this.props.availableNow.mixed_dorm_available}
            load={this.load}
            workpad
          />
        )}

        <Footer Workpad_none />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    availableNow: state.availableNow,
  };
};

export default connect(mapStateToProps)(bookNow);
