import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import UserProvider from "./providers/UserProvider"
import App from "./components/app";
import Reducers from "./reducers";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration"
import swConfig from "./swConfig";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

ReactDOM.render(
  <Provider
    store={createStore(Reducers, composeEnhancers(applyMiddleware(reduxThunk)))}
  ><UserProvider>
    <App />
    </UserProvider>
  </Provider>,
  document.querySelector("#root")
);

ServiceWorkerRegistration.register(swConfig)