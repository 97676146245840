export default (state = null, action) => {
  switch (action.type) {
    case "SELECTED":
      return {
        selected: action.payload,
      };
    default:
      return state;
  }
};
