import React from "react";
// import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { pdfsenderndownload, yourpdf } from "./renderpdf";
import { Component, Fragment } from "react";
import { Rating } from "@material-ui/lab";
import { TextField, Button, Card } from "@material-ui/core";
import { auth, db } from "../../firebase";
import { Adb } from "@material-ui/icons";
import { popUpdate, v } from "../../swConfig";
import { render } from "react-dom";
// import booking from "./booking";
// // import UserNotification from "./notifications/usernotification";
// import { List } from "@material-ui/core";

// Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "row",
//     backgroundColor: "#E4E4E4",
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
// });
const data = {
  name: "Suraj Kachhap",
  booked_date: new Date().toLocaleDateString(),
  booked_at: "Tue, 11 May 2021 03:39:22 GMT",
  location_id: "ECO001",
  gst: "0",
  check_in: "2021-05-29",
  check_out: "2021-05-30",
  currency: "INR",

  booking_type: "hotspot",
  bed_type: ["Two Person Shared Tent", "Tree House", "Test1"],
  // price_USD: {
  //   "Two Person Shared Tent": 900,
  //   "Tree House": 2000,
  //   Test: 100,
  // },
  price: {
    "Two Person Shared Tent": 900,
    "Tree House": 2000,
    " Test": 100,
  },
  guest_details: {
    "Two Person Shared Tent": [{ name: "Suraj", age: "22", gender: "M" }],
    "Tree House": [
      // {
      //   name: "Suraj",
      //   age: "22",
      //   gender: "M",
      // },
    ],
    Test1: [],
  },
  user_id: "2qwsexrdcft7u43567",
  orderId: "OG_afiojdf",
  paymentId: "PG_iuhdf89hauid",
  email: "suramani13@gmail.com",
  receipt: "IN260521ECO5IXD",
  amount: 900,
  coupon_code: "",
  coupon_discount: {},
  paid_amount: 850,
  partial: false,
  paid_percent: 100,
  phone_no: "+917870014228",
  pnr: "PB260521ECO5IXD",
  // mixed_dorm_price: 500,
  // female_dorm_price: 600,
};
const wdata = {
  amount: 2478,
  booked_at: "2021-05-31T19:00:27.032Z",
  booking_type: "workpad",
  check_in: "2021-06-01",
  check_in_status: false,
  check_out: "2021-06-08",
  coupon_code: "",
  coupon_discount: "",
  currency: "INR",
  email: "ssmun34@gmail.com",
  female_bed_count: 0,
  female_dorm_price: "250",
  female_guest_details: [],
  gst: "18",
  id: "BBIDBA45128921060121060828bc24054a9d",
  location: "Bangalore",
  location_id: "BA451289",
  mixed_bed_count: 1,
  mixed_dorm_price: "300",
  name: "SURAJ KACHHAP",
  orderId: "order_HHVVNVJXEEEOf0",
  paid_amount: 2478,
  paid_percent: 100,
  paymentId: "pay_HHVVTHgaIdPlmI",
  phone_no: "+917870014228",
  pnr: "PB310521BA4L48A",
  receipt: "IN310521BA4L48A",
  review: false,
  user_id: "8AMLTfWNZORErMvQuTMvR4cO3NO2",
  mixed_guest_details: [{ name: "mahesh", age: "23", gender: "M" }],
};
const obdata = {
  amount: 2600,
  bed_type: (2)[("Two Person Shared Tent", "Tree House")],
  booked_at: "Mon, 31 May 2021 17:59:29 GMT",
  booking_type: "hotspot",
  check_in: "2021-05-31",
  check_in_status: false,
  check_out: "2021-06-01",
  coupon_code: "FLAT30",
  coupon_discount: { INR: 100, USD: 10, EUR: 5 },
  currency: "INR",
  email: "ssmun34@gmail.com",
  gst: "0",
  guest_details: { "Tree House": Array(0), "Two Person Shared Tent": Array(3) },
  location: "Ecopulse Kurseong",
  location_id: "ECO001",
  name: "SURAJ KACHHAP",
  orderId: "order_HHUSz6rgj6OsiP",
  paid_amount: 2600,
  paid_percent: 100,
  partial: false,
  paymentId: "pay_HHUT4Zqs568Qpr",
  phone_no: "+917870014228",
  pnr: "PB310521ECOY5YC",
  price: { Test: 100, "Tree House": 2000, "Two Person Shared Tent": 900 },
  receipt: "IN310521ECOY5YC",
  user_id: "8AMLTfWNZORErMvQuTMvR4cO3NO2",
  verified: false,
};
// Create Document Component
class Retest extends Component {
  constructor() {
    super();
    this.state = {
      stars: 0,
      message: "",
      box: "",
    };
  }
  componentDidMount() {
    this.review();
    console.log(this.state);
  }
  async emailsender() {
    console.log("sendEmail");
    await pdfsenderndownload("email", data);
    return;
  }
  async pdfdownloader() {
    console.log("pdfDownloader");
    await pdfsenderndownload("download", data);
    return;
  }

  submitReview = (location_id, docID, name) => {
    if (this.state.stars === 0 || this.state.message === "") {
      alert("Empty Review !");
    } else {
      // var collection = "hotels"
      // if (booking_type === "hotspot") {
      //   collection="hotspots"
      // }
      db.collection("reviews")
        .doc(location_id)
        .collection("comments")
        .doc(docID)
        .set({
          name: name,
          uid: "NhHgD9TIp3RNrFKIuBqcWuvemjt2",
          review_text: this.state.message,
          stars: this.state.stars,
        })
        .then(() => {
          db.collection("bookings")
            .doc(docID)
            .update({
              review: true,
            })
            .then(() => {
              var box = <>Thanks for the review!</>;
              this.setState({ box: box });
            });
        });
      // alert("Hurray")
    }
  };

  review = () => {
    // console.log(auth.currentUser.uid)
    var box;
    db.collection("bookings")
      .where("user_id", "==", "NhHgD9TIp3RNrFKIuBqcWuvemjt2")
      .where("review", "==", false)
      .limit(1)
      .get()
      .then((result) => {
        console.log(result.docs[0].id);
        console.log(result.docs[0].exists);
        if (result.empty === false) {
          box = (
            <Card style={{ margin: "1rem", padding: "1rem 2rem" }}>
              <h1>Review</h1>
              <br />
              {result.docs[0].data().location}(
              {result.docs[0].data().location_id})<br />
              <br />
              {result.docs[0].data().check_in} -{" "}
              {result.docs[0].data().check_out}
              <br />
              <br />
              <div>
                <Rating
                  name="half-rating"
                  defaultValue={0}
                  precision={0.5}
                  // style={{ margin: "0rem 1rem" }}
                  onChange={(e) => this.setState({ stars: e.target.value })}
                />
                <br />
                <br />
                <TextField
                  multiline
                  variant="outlined"
                  style={{ width: "80vh", height: "5rem" }}
                  size="large"
                  placeholder="Review..."
                  onChange={(e) => this.setState({ message: e.target.value })}
                ></TextField>
                <br />{" "}
              </div>
              <Button
                variant="contained"
                style={{
                  margin: "1rem 1rem",
                  float: "right",
                  background: "aliceblue",
                }}
                onClick={(e) =>
                  this.submitReview(
                    result.docs[0].data().location_id,
                    // result.docs[0].data().booking_type,
                    result.docs[0].id,
                    result.docs[0].data().name
                  )
                }
              >
                Submit
              </Button>
            </Card>
          );
          this.setState({ box: box });
        } else {
          // throw new Error("error")
          box = <Card>NO reviews</Card>;
          this.setState({ box: box });
        }
      })
      .catch((e) => {
        box = (
          <Card style={{ margin: "1rem", padding: "1rem 2rem" }}>
            Error Fecthing Data
          </Card>
        );
        this.setState({ box: box });
      });
  };

  createDoc() {
    const b = document.createElement("div");
    b.id = "popppp";
    document.body.appendChild(b);
    render(v, document.getElementById("popppp"));
  }
  render() {
    console.log(this.state);
    return (
      <div>
        {yourpdf(obdata, 100)}
        {/* { true ? this.createDoc():null} */}

        <button onClick={this.pdfdownloader}>Click to Download PDF</button>
        <button onClick={this.emailsender}>Click to Email PDF</button>

        {/* <hr /> */}

        {/* {this.state.box} */}
      </div>
    );
  }
}
export default Retest;
