const value = {
  show: false,
  offers: "NO OFFER",
};
export default (state = value, action) => {
  switch (action.type) {
    case "OFFERS":
      return {
        show: action.payload.show,
        offers: action.payload.offers,
      };
    default:
      return state;
  }
};
