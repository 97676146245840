import axios from "axios";
import { _serverurl } from "../keys/URLS";
import {xapikeys} from "../keys/keys"
export default axios.create({
  baseURL: _serverurl,
  headers: {
    "x-api-key": xapikeys,
    // "Access-Control-Allow-Origin": "*",
    // // "Access-Control-"
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // 'Access-Control-Allow-Headers': '*'
  },
});
