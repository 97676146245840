import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Button from "@material-ui/core/Button";

import Cancle from "./cancle";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import { pdfsenderndownload } from "./renderpdf";

import { Snackbar, IconButton, Divider } from "@material-ui/core";
// import { Alert } from "@material-ui/lab";
// import { Close as CloseIcon } from "@material-ui/icons";
const moment = require("moment-timezone");
const useStyles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class CancleBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      snackmessage: "",
      showsnackbar: false,
      notify: false,
      idopen: null,
    };
  }

  handleChange = (panel) => (event, isExpanded) => {
    // console.log(this.state);
    this.setState({ expanded: isExpanded ? panel : false, notify: false });
  };

  onprintClick = async (data) => {
    const res = await pdfsenderndownload(
      "download",
      data,
      true,
      null,
      "userFetch"
    );
    // console.log(res);
    if (res === "success") {
      this.setState({
        snackmessage: "Reciept Downloaded",
        showsnackbar: true,
      });
    }
  };

  onDownloadClick = async (data) => {
    const res = await pdfsenderndownload(
      "email",
      data,
      true,
      data.phone_no,
      "download"
    );
    if (res === "success") {
      this.setState({
        snackmessage: "You will recieve Reciept in your mail shortly!",
        showsnackbar: true,
      });
    }
  };
  onCloseSnack = () => {
    this.setState({
      snackmessage: "",
      showsnackbar: false,
    });
  };

  componentDidMount() {
    if (
      this.props.notify.id &&
      this.props.notify.id !== this.state.idopen &&
      !this.state.notify
    ) {
      // console.log("switching");
      this.setState({
        notify: true,
        idopen: this.props.notify.id,
        expanded: true,
      });
      // this.props.notify = false
    } else {
      this.setState({ notify: false });
    }
  }
  openAccordianOnNotify = () => {
    // console.log("openAccordian=>", this.props.item, this.props.notify);
    if (
      this.props.notify.toOpen &&
      this.props.item.docId === this.props.notify.id &&
      !this.state.notify &&
      !this.state.expanded
    ) {
      // this.setState({ notify: true,idopen:this.props.notify.id })
      return true;
    } else {
      //  this.setState({ notify: false});
      return false;
    }
  };
  addGuest = (data) => {
    var sum = 0;
    for (var key of Object.keys(data)) {
      sum += data[key].length;
    }
    return sum;
  };
  render() {
    const { classes } = this.props;
    const { data } = this.props.item;
    // const count = data.female_bed_count + data.mixed_bed_count;
    var person = 0;
    data.bed_type.map((val) => (person += data.guest_details[val].length));

    return (
      <div style={{ margin: "15px 0 0" }} className={classes.root}>
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={this.onCloseSnack}
          open={this.state.showsnackbar}
          message={this.state.snackmessage}
          //onClose={this.handelsnackclose}
          // TransitionComponent="Grow"
        ></Snackbar>
        <Accordion
          style={{ margin: "20px 5px 0 8px" }}
          expanded={
            this.state.expanded === "panel1" || this.openAccordianOnNotify()
          }
          onChange={this.handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="accordionPNR"
            style={{ padding: "0 8px" }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "600",
                width: "44%",
              }}
              className={classes.heading}
            >
              {data.pnr}
            </Typography>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                width: "44%",
              }}
              className={classes.heading}
            >
              {data.location}
            </Typography>
            {data.booking_type === "hotspot" ? (
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  width: "12%",
                }}
                className={classes.heading}
              >
                #{person}
                {/* {data.guest_details["Tree House"].length +
                  data.guest_details["Two Person Shared Tent"].length} */}
                {/* <Typography className={classes.heading}>{data.location}</Typography>
            {console.log(data)}
            {data.booking_type === "hotspot" ? (
              <Typography className={classes.heading}>
                {
                 this.addGuest(data.guest_details)
                } */}
              </Typography>
            ) : (
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  width: "12%",
                }}
                className={classes.heading}
              >
                #{data.female_bed_count + data.mixed_bed_count || ""}
              </Typography>
            )}
          </AccordionSummary>
          <Divider style={{ margin: "8px", marginTop: "-5px" }} />
          <AccordionDetails style={{ padding: "0 8px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                width: "100%",
              }}
            >
              <div style={{ fontSize: "12px", width: "39%" }}>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  Check in :
                </span>
                <br />
                {moment.tz(data.check_in, "Asia/Kolkata").format("DD/MM/YYYY")}
              </div>
              <div style={{ fontSize: "12px", width: "39%" }}>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  Check out :
                </span>
                <br />
                {moment.tz(data.check_out, "Asia/Kolkata").format("DD/MM/YYYY")}
                <br />
              </div>
              <div style={{ fontSize: "12px", width: "22%" }}>
                <span
                  style={{
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  Booked At :
                </span>
                <br />
                {moment.tz(data.booked_at, "Asia/Kolkata").format("DD/MM/YYYY")}
              </div>
            </div>
          </AccordionDetails>

          <AccordionDetails
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "600",
              padding: "20px 8px 10px",
            }}
          >
            Guest Details :
          </AccordionDetails>
          <AccordionDetails style={{ padding: "0 8px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  width: "39%",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Name
              </div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  width: "39%",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Age
              </div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  width: "22%",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Gender
              </div>
              {/* <div
                style={{
                  fontSize: "14px",
                  width: "25%",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Bunk
              </div> */}
            </div>
          </AccordionDetails>
          {data.booking_type === "hotspot" ? (
            <AccordionDetails
              style={{ padding: "4px 8px 0", flexWrap: "wrap" }}
            >
              {Object.keys(data.guest_details).map((val1) => {
                return Object.keys(data.guest_details[val1]).map((val) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        wordWrap: "break-word",
                      }}
                      key={`${val1}${val}`}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          width: "39%",
                          textAlign: "left",

                          fontFamily: "Poppins",
                        }}
                      >
                        {data.guest_details[val1][val].name}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          width: "39%",
                          textAlign: "left",

                          fontFamily: "Poppins",
                        }}
                      >
                        {data.guest_details[val1][val].age}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          width: "22%",
                          textAlign: "left",

                          fontFamily: "Poppins",
                        }}
                      >
                        {data.guest_details[val1][val].gender}
                      </div>
                    </div>
                  );
                });
              })}
            </AccordionDetails>
          ) : (
            <div>Not Accepting Normal bookings</div>
            // <AccordionDetails style={{ padding: "4px 8px 0" }}>
            //   {data.female_bed_count > 0 && (
            //     <div style={{ width: "100%" }}>
            //       {Object.keys(data.female_guest_details).map((val) => (
            //         <div
            //           style={{
            //             fontSize: "14px",
            //             display: "flex",
            //             justifyContent: "space-between",
            //           }}
            //           key={val}
            //         >
            //           <div
            //             style={{
            //               fontSize: "14px",
            //               width: "35%",
            //               textAlign: "left",
            //             }}
            //           >
            //             {data.female_guest_details[val].name}
            //           </div>
            //           <div
            //             style={{
            //               fontSize: "14px",
            //               width: "36%",
            //               textAlign: "left",
            //             }}
            //           >
            //             {data.female_guest_details[val].age}
            //           </div>
            //           <div
            //             style={{
            //               fontSize: "14px",
            //               width: "27%",
            //               textAlign: "left",
            //             }}
            //           >
            //             F
            //           </div>
            //           <div
            //             style={{
            //               fontSize: "14px",
            //               width: "25%",
            //               textAlign: "left",
            //             }}
            //           >
            //             FD
            //           </div>
            //         </div>
            //       ))}
            //     </div>
            //   )}
            // </AccordionDetails>
          )}
          <Divider style={{ margin: "15px 8px 0" }} />
          <AccordionDetails
            style={{
              fontWeight: "600",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "0 8px",
            }}
          >
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                margin: "5px 0px",
              }}
            >
              Net Amount: {data.amount}
            </div>
            {data.booking_type === "hotspot" ? (
              <React.Fragment>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      margin: "5px 0px",
                    }}
                  >
                    HOTSPOT BOOKING
                  </div>
                  <div>
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        margin: "5px 0px",
                      }}
                    >
                      Paid Amount: {data.paid_amount}
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        margin: "5px 0px",
                      }}
                    >
                      Due Amount: {data.amount - data.paid_amount}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    margin: "5px 0px",
                  }}
                >
                  Paid Percent : {data.paid_percent}
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    margin: "5px 0px",
                  }}
                >
                  Remaining Amount :
                  {data.amount - (data.paid_percent / 100) * data.amount}
                </div>
              </React.Fragment>
            )}

            {data.check_in_status ? (
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "13px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  STATUS:
                </span>
                &nbsp; Checked In
              </div>
            ) : (
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "13px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  STATUS:
                </span>
                &nbsp; {this.statuswitch()}
              </div>
            )}
          </AccordionDetails>
          <AccordionDetails
            style={{
              justifyContent: "space-around",
              display: `${data.amount !== data.paid_amount ? "none" : "flex"}`,
            }}
          >
            <Button
              style={{
                background: "#ffde17",
                // display: `${
                //   data.amount !== data.paid_amount ? "none" : "inherit"
                // }`,
              }}
              variant="contained"
              disabled={data.amount !== data.paid_amount}
              onClick={(e) => this.onDownloadClick(data)}
            >
              <MailRoundedIcon />
            </Button>
            <Button
              style={{
                background: "#ffde17",
                // display: `${
                //   data.amount !== data.paid_amount ? "none" : "inherit"
                // }`,
              }}
              variant="contained"
              // onClick={(e) => pdfsenderndownload("download", data, "100")}
              disabled={data.amount !== data.paid_amount}
              onClick={(e) => this.onprintClick(data)}
            >
              <PrintRoundedIcon />
            </Button>
          </AccordionDetails>
          <AccordionDetails style={{ justifyContent: "space-around" }}>
            {this.props.params === "deleted" ? (
              <div style={{ background: "gainsboro" }} variant="contained">
                This booking was cancelled
              </div>
            ) : moment(
                moment.tz("Asia/Calcutta").format("YYYY-MM-DD")
              ).isSameOrBefore(data.check_in) ? (
              <Cancle booking_key={this.props.item.docId} />
            ) : (
              data.check_in_status && (
                <div style={{ background: "gainsboro" }} variant="contained">
                  Thanks for staying with us!
                </div>
              )
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  statuswitch = () => {
    switch (window.location.pathname) {
      case "/bookings/deleted":
        return "Canceled";
        break;
      case "/bookings/upcoming":
        return "Not Checked In";
        break;
      default:
        return "Not Checked IN";
        break;
    }
  };
}

export default withStyles(useStyles)(CancleBooking);
