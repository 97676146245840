//
import React from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hotspot from "./static/Hotspot";
import Grid from '@material-ui/core/Grid';
import {
  withStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Snackbar,
  IconButton,
  Popover,
  DialogActions,
  Grow,
} from "@material-ui/core";
import { Alert, Rating } from "@material-ui/lab";
// import { Close as CloseIcon } from "@material-ui/icons";
import {
  date,
  update_available,
  mixedGuestDetails,
  femaleGuestDetails,
  mixedTempDetails,
  femaleTempDetails,
  fetchuserdata,
  userProfile,
  // updateHotspot,
  hotspotGuestDetails,
  hotspotSelection,
} from "../actions";
import history from "../helpers/history";
import Paper from "@material-ui/core/Paper";
import CheckOutSteps from "./static/checkoutSteps";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
// import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AddIcon from "@material-ui/icons/Add";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RemoveIcon from "@material-ui/icons/Remove";
// import { withRouter } from "react-router-dom";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
// import Backdrop from "@material-ui/core/Backdrop";
// import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Collapse,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import Navbar from "./static/navbar/navbarMui";
import { auth, db } from "../firebase";
import { Link } from "react-router-dom";
import { id } from "date-fns/locale";
import { parseInt } from "lodash";
import { parse } from "date-fns";
var reviewSnap = {
  location: "",
  location_id: "",
  receipt: "",
  name: "",
  user_id: "",
  check_in: "",
  check_out: "",
};
const useStyles = (theme) => ({
  root: {
    display: "flex",
    // flexGrow: "1",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  details: {
    display: "flex",
    flexDirection: "column",
    
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  MuiAlert: {
    action: {
      display: "block",
    },
  },
});

// const useStyles1 = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: '#fff',
//   },
// }));

// export default function SimpleBackdrop() {
//   const classes = useStyles();
//   const [open, setOpen] = React.useState(false);
//   const handleClose = () => {
//     setOpen(false);
//   };
//   const handleToggle = () => {
//     setOpen(!open);
//   };

//   return (
//     <div>
//       <Button variant="outlined" color="primary" onClick={handleToggle}>
//         Show backdrop
//       </Button>
//       <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
//         <CircularProgress color="inherit" />
//       </Backdrop>
//     </div>
//   );
// }


const reviewTheme = createMuiTheme({
  overrides: {
    MuiAlert: {
      action: {
        display: "block",
        // padding: "0",
        marginLeft: "0",
        marginRight: "0",
        paddingLeft: "0",
      },
    },
  },
});

class BookHotspot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: [0, 0, 0, 0, 0, 0, 0],
      no_of_nights: 0,
      bed_type: [],
      guest_details_dialog: false,
      guest_details_1: [],
      guest_details_2: [],
      guest_details: {},
      currency: "INR",
      backdrop: true,
      onprocess: false,
      opensnackbar: false,
      error_message: "",
      severity: "error",
      // reviewPoP: false,
      reviewDisplay: false,
    };
  }
  componentDidMount = async () => {
    try {
      if (
        this.props.location.state !== undefined &&
        this.props.location.state.hasOwnProperty("prev")
      ) {
        if (this.props.location.state.prev === "login") {
          // console.log(this.state.location);
          this.setState({
            opensnackbar: true,
            severity: "success",
            error_message: "Log-in Successful",
          });
        } else if (this.props.location.state.prev === "setupprofile") {
          // console.log(this.state.location);
          this.setState({
            opensnackbar: true,
            severity: "success",
            error_message: "Profile Updated Successfully",
          });
        }
      }
      let guest_details = {};
      this.setState(
        {
          bed_type: JSON.parse(localStorage.getItem("bed_type")),
        },
        () => {
          for (let i = 0; i < this.state.bed_type.length; i++) {
            guest_details[this.state.bed_type[i]] = [];
          }
          this.setState({ guest_details });
        }
      );
      auth.onAuthStateChanged((user) => {
        this.fetchudata("bookingComponent");
      });
      this.setState({ count: this.props.hotspotSelected });
    } catch (err) {
      history.push("/");
      window.location.reload();
      localStorage.clear();
    }
  };

  componentDidUpdate() {
    // console.log("UPDATE");
    localStorage.getItem("currency") &&
      this.state.currency !== localStorage.getItem("currency") &&
      this.setState({ currency: localStorage.getItem("currency") });

    if (
      this.state.guest_details[
        JSON.parse(localStorage.getItem("bed_type"))[0]
      ] === undefined
    ) {
      let guest_details = {};
      let bed_type = JSON.parse(localStorage.getItem("bed_type"));
      for (let i = 0; i < bed_type.length; i++) {
        guest_details[bed_type[i]] = [];
      }
      this.setState({ bed_type, guest_details });
    }
  }

  fetchudata = async (where) => {
    // this.setState({ onprocess: true });
    // console.log("fetching", this.state);
    let tid;
    if (auth.currentUser !== null) {
      await auth.currentUser.getIdToken().then((id) => {
        tid = id;
        // console.log(id);
      });
    } else if (where === "buttonprocced") {
      this.props.history.push("/login");
    }
    const res = await this.props.userProfile(tid);
    // console.log(res);
    let profile = {};
    if (res.hasOwnProperty("error")) {
      // alert("Error Network / USER");
      return null;
    } else return res;
  };

  card(classes, name, key) {
    if (this.props.availableHotspot[name] === undefined) {
      return (
       <div>
        <Backdrop
           key={key}
           style={{ position: "absolute", zIndex: "100" }}
           open={this.state.backdrop}
         >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Skeleton
          key={key}
          variant="rect"
          style={{ margin: "auto", marginTop: "10px", width: "90%" }}
          height={118}
        />
        </div>
      );
    } else {
      return (
        <Card
          key={key}
          className={classes.root}
          style={{
            margin: "10px 8px",
          }}
        >
          <div className="containerImg" >
          <CardMedia
            className={`dormImages`}
            style={{
              height: "100%",
              width: "100%",
              
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              // position: "relative",
              // background: "gainsboro",
              // fontFamily: "Poppins",
              // fontWeight: "600",
              // textAlign: "center",
              // width: "25%",
              // fontSize: "18px",
              // // zIndex: "1",
              // overflowWrap: "anywhere",
            }}
          >
            &nbsp;
            {/* <PermIdentityIcon fontSize="large" />
            {name === "Two Person Shared Tent" ? "Shared Tent" : name} */}
          </CardMedia>
          </div>
          <div style={{ width: "50%" }} className={classes.details}>
            <CardContent style={{ padding: "8px", display: "flex", flexDirection: "column", justifyContent: "space-around" }} className={classes.content}>
              <div
              className="desktopFont"
              >
                {name} *
              </div>
              <Typography
              color="textSecondary"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                }}
              >
                Tariff inclusive of taxes
              </Typography>
              <Typography
              color="textSecondary"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "11px",
                  fontWeight: "400",
                  paddingTop: "8px"
                }}
              >
                * Solo adventurer/Odd groups may have to share tents with fellow adventurers
              </Typography>
              <p
                style={{
                  marginBottom: "0",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  marginTop: "5px"
                }}
              >
                {this.props.availableHotspot[name]} Left {this.props.availableHotspot.Tree_House}
              </p>
              {this.props.availableHotspot[name] > 0 ? (
                <Divider
                  style={{ background: "green", height: "2px", margin: "4px" }}
                ></Divider>
              ) : (
                <Divider
                  style={{ background: "red", height: "2px", margin: "4px" }}
                ></Divider>
              )}
            </CardContent>
          </div>
          <CardMedia style={{ width: "22%", display: "flex", flexDirection: "column", justifyContent: "space-around", textAlign: "center" }}>
            {this.state.currency === "INR" ? (
              <Typography
                style={{
                  fontSize: "21px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                ₹ {JSON.parse(localStorage.getItem("price"))[name]}
              </Typography>
            ) : this.state.currency === "USD" ? (
              <Typography
                style={{
                  fontSize: "21px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                $ {JSON.parse(localStorage.getItem("price_USD"))[name]}
              </Typography>
            ) : (
              <Typography
                style={{
                  fontSize: "21px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                € {JSON.parse(localStorage.getItem("price_EUR"))[name]}
              </Typography>
            )}

            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{
                fontFamily: "Poppins",
                fontSize: "10px",
                fontWeight: "400",
              }}
            >
              per person / Night
            </Typography>

            {this.props.availableHotspot[name] > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    background: "#ffde17",
                    height: "24px",
                  }}
                  onClick={() => {
                    let temp = this.state.count;
                    temp[key] - 1 > 0
                      ? (temp[key] = temp[key] - 1)
                      : (temp[key] = 0);
                    this.setState({ count: temp }, () => {
                      this.props.hotspotSelection(this.state.count);
                    });
                  }}
                >
                  <RemoveIcon
                    color={`${
                      this.state.count[key] <= 0 ? "disabled" : "action"
                    }`}
                  />
                </div>
                <div
                  style={{ fontFamily: "Poppins", fontSize: "17px" }}
                  className="page-item"
                >
                  {this.state.count[key]}
                </div>

                <div
                  style={{
                    border: "1px solid black",
                    background: "#ffde17",
                    height: "24px",
                  }}
                  onClick={() => {
                    let temp = this.state.count;
                    temp[key] + 1 <= this.props.availableHotspot[name]
                      ? (temp[key] = temp[key] + 1)
                      : (temp[key] = temp[key]);
                    this.setState({ count: temp }, () => {
                      this.props.hotspotSelection(this.state.count);
                    });
                  }}
                >
                  <AddIcon
                    color={`${
                      this.props.availableHotspot[name] <= this.state.count[key]
                        ? "disabled"
                        : "action"
                    }`}
                  />
                </div>
              </div>
            )}

            {/* <p
            style={{ fontFamily: "Poppins", margin: "15px 5px" }}
            className="dorms-Avl"
          >
            {this.props.availableHotspot[name] > 0
              ? "Available"
              : "Unavailable"}
          </p> */}
            <div></div>
          </CardMedia>
        </Card>
      );
    }
  }

  checkForPendingReview = async (id) => {
    var dis = false;
    await db
      .collection("past_bookings")
      .where("user_id", "==", id)
      .where("review", "==", false)
      .limit(1)
      .get()
      .then((snap) => {
        // console.log(snap);
        if (!snap.empty) {
          reviewSnap = snap.docs[0].data();
          dis = true;
        }
      });
    return dis;
  };
  handleProceed = async (event) => {
    event.preventDefault();
    this.setState({ onprocess: true });
    if (auth.currentUser === null) {
      let pathname = window.location.pathname.slice(1);
      // console.log(pathname);
      this.props.history.push({
        pathname: "/login",
        state: {
          next: "hotspot",
          prev: "hotspot",
        },
      });
      return;
    }
    let res = null;
    if (!this.props.user.hasOwnProperty("profile")) {
      // console.log("pushong");
      res = await this.fetchudata("buttonprocced");
      if (res === null) {
        // alert(res.error);
        return;
      }
    } else {
      res = this.props.user.profile;
    }
    console.log(res);
    if (res.primary_data === "email" && res.phone === null) {
      let pathname = window.location.pathname.slice(1);
      this.props.history.push({
        pathname: "/setupprofile/" + auth.currentUser.uid,
        state: {
          primary: "email",
          next: pathname,
          prev: pathname,
        },
      });
      return this.setState({ onprocess: false });
    } else if (res.primary_data === "phone" && res.email === null) {
      let pathname = window.location.pathname.slice(1);
      this.props.history.push({
        pathname: "/setupprofile/" + auth.currentUser.uid,
        state: {
          primary: "phone",
          next: pathname,
          prev: pathname,
        },
      });
      return this.setState({ onprocess: false });
    }
    var responseReview = await this.checkForPendingReview(auth.currentUser.uid);
    if (responseReview) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return this.setState({ reviewDisplay: true, onprocess: false });
    }
    // return;

    let temp = [];
    for (let i = 0; i < this.state.count[0]; i++) {
      temp.push({
        name: "",
        age: "",
        gender: "M",
      });
    }
    let temp2 = [];
    for (let i = 0; i < this.state.count[1]; i++) {
      temp2.push({
        name: "",
        age: "",
        gender: "M",
      });
    }
    let temp3 = this.state.guest_details;
    for (let i = 0; i < this.state.bed_type.length; i++) {
      for (let j = 0; j < this.state.count[i]; j++) {
        temp3[this.state.bed_type[i]].push({
          name: "",
          age: "",
          gender: "M",
        });
      }
    }
    this.setState({
      guest_details_1: temp,
      guest_details_2: temp2,
      guest_details: temp3,
      guest_details_dialog: true,
    });
  };

  handleChange = (event) => {
    let temp = this.state.guest_details_1;
    let arr = event.target.name.split("_");
    console.log("test log");
    console.log(arr);
    if (arr[0] === "name") {
       const regex = /[^a-zA-Z ]/gi;
      // temp[parseInt(arr[1])].name = event.target.value;
      if (event.target.value === "" || !regex.test(event.target.value)) {
        temp[parseInt(arr[1])].name = event.target.value;
      }
    } else if (arr[0] === "age") {
      temp[parseInt(arr[1])].age = event.target.value;
    } else {
      temp[parseInt(arr[1])].gender = event.target.value;
    }
    this.setState({ guest_details_1: temp });
  };

  handleChange2 = (event) => {
    let temp = this.state.guest_details_2;
    let arr = event.target.name.split("_");
    if (arr[0] === "name") {
      temp[parseInt(arr[1])].name = event.target.value;
    } else if (arr[0] === "age") {
      temp[parseInt(arr[1])].age = event.target.value;
    } else {
      temp[parseInt(arr[1])].gender = event.target.value;
    }
    this.setState({ guest_details_2: temp });
  };
  handleSubmit = (event) => {
    // debugger;
    this.props.hotspotGuestDetails(this.state.guest_details);
    localStorage.setItem(
      "guest_details",
      JSON.stringify(this.state.guest_details)
    );
    // console.log(typeof (localStorage.getItem("price_USD")));
    // // debugger;
    var bookingData = {};
    try {
      var d1 = new Date();
      var d2 = new Date(localStorage.getItem("hotspot_check_in"));
      if (Math.ceil(Math.abs(d2 - d1) / (1000 * 60 * 60 * 24)) <= 1) {
        throw new Error("date near");
      }
      bookingData = {
        guest_details: this.state.guest_details,

        price: JSON.parse(localStorage.getItem("price")),
        price_USD: JSON.parse(localStorage.getItem("price_USD")),
        price_EUR: JSON.parse(localStorage.getItem("price_EUR")),
        bed_type: JSON.parse(localStorage.getItem("bed_type")),
        title: "hotspot",
        hotspot_check_in: localStorage.getItem("hotspot_check_in"),
        hotspot_check_out: localStorage.getItem("hotspot_check_out"),
        hotspot_location_id: localStorage.getItem("hotspot_location_id"),
        hotspot_location: localStorage.getItem("hotspot_location"),
        tax: JSON.parse(localStorage.getItem("tax")),
        currency: this.state.currency,
      };
    } catch (error) {
      console.log(error);
      bookingData = {};
    }
    // debugger;
    console.log(bookingData);
    if (Object.keys(bookingData).length > 0) {
      localStorage.setItem("notifyBookdata", JSON.stringify(bookingData));
    }
    return this.props.history.push("/hsummary");
  };

  numberOfDays() {
    const date1 = new Date(localStorage.getItem("hotspot_check_in"));
    const date2 = new Date(localStorage.getItem("hotspot_check_out"));
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  totalAmount = () => {
    const date1 = new Date(localStorage.getItem("hotspot_check_in"));
    const date2 = new Date(localStorage.getItem("hotspot_check_out"));
    const diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let price;
    if (this.state.currency === "INR") {
      price = JSON.parse(localStorage.getItem("price"));
    } else if (this.state.currency === "USD") {
      price = JSON.parse(localStorage.getItem("price_USD"));
    } else if (this.state.currency === "EUR") {
      price = JSON.parse(localStorage.getItem("price_EUR"));
    }
    let amount = 0;
    JSON.parse(localStorage.getItem("bed_type")).map((name, key) => {
      amount += price[name] * this.state.count[key] * diffDays;
    });
    return amount;
  };
  handelsnackclose = () => {
    //event.preventDefault();
    // console.log("snackclose");
    this.props.location.state = undefined;
    this.setState({
      opensnackbar: false,
      error_message: "",
      severity: "",
    });
  };

  details = (type, key) => {
    if (this.state.guest_details_dialog) {
      let temp = [];
      //this.setState({ guest_details: [{}] });
      for (let i = 0; i < this.state.count[key]; i++) {
        temp.push(
          <div
            key={i}
            className="guest_details mixed_details"
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "space-around",
              justifyContent: "space-between",
              overflow: "visible",
              // flexWrap: "wrap",
            }}
          >
            <TextField
              style={{ margin: "10px 5px 0 0", width: "63%" }}
              name={`name_${key}`}
              value={this.state.guest_details[type][i].name}
              
              onChange={(e) => {
                const regex = /[^a-zA-Z ]/gi;
                let temp = this.state.guest_details;
                if (e.target.value === "" || !regex.test(e.target.value)) {
                  temp[type][i].name = e.target.value;
                }
                this.setState({ guest_details: temp }, () => {});
              }}
              label="Name"
              type="text"
              required
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 25 }}
            />
            <TextField
              style={{ width: "25%", margin: "10px 10px 0 0" }}
              name={`age_${key}`}
              value={this.state.guest_details[type][i].age}
              onChange={(e) => {
                let temp = this.state.guest_details;
                temp[type][i].age = e.target.value;
                this.setState({ guest_details: temp }, () => {});
              }}
              label="Age"
              type="number"
              required
              variant="outlined"
              size="small"
              InputProps={{ inputProps: { min: 1, max: 99 } }}
            />
            <FormControl style={{ margin: "10px 10px 0 0", width: "12%" }}>
              {/* <InputLabel id="gender_label">Gender</InputLabel> */}
              <Select
                labelId="gender_label"
                name={`gender_${key}`}
                value={this.state.guest_details[type][i].gender}
                onChange={(e) => {
                  let temp = this.state.guest_details;
                  temp[type][i].gender = e.target.value;
                  this.setState({ guest_details: temp }, () => {});
                }}
                required
              >
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="F">F</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      }
      return temp;
    }
  };

  submitReview = (location_id, docID, name, uid) => {
    if (this.state.stars === 0) {
      alert("Rating Must be provided!");
    } else {
      this.setState({ onprocess: true });
      // var collection = "hotels"
      // if (booking_type === "hotspot") {
      //   collection="hotspots"
      // }
      // console.log(docID,location_id,name,uid);
      db.collection("reviews")
        .doc(location_id)
        .collection("comments")
        .doc(docID)
        .set({
          name: name,
          user_id: uid,
          review_text: this.state.message,
          stars: this.state.stars,
        })
        .then(() => {
          db.collection("past_bookings")
            .doc(docID)
            .update({
              review: true,
            })
            .then(() => {
              this.setState({
                opensnackbar: true,
                severity: "success",
                error_message: "Review Submitted Successfully",
                reviewDisplay: false,
                onprocess: false,
              });
            })
            .catch((e) => {
              // console.log(e);
              this.setState({
                opensnackbar: true,
                severity: "error",
                error_message: "Network Error! Unable To Submit Review",
                onprocess: false,
              });
            });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({
            opensnackbar: true,
            severity: "error",
            error_message: "Network Error! Unable To Submit Review",
            onprocess: false,
          });
        });
      // alert("Hurray")
    }
  };
  render() {
    try {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <Navbar />
          <Dialog
            open={this.state.reviewDisplay}
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle>
              <span style={{ fontWeight: "bold" }}>Review</span>
            </DialogTitle>
            <DialogContent>
              {reviewSnap.location}({reviewSnap.location_id})
              <br />
              {reviewSnap.check_in} - {reviewSnap.check_out}
              <br />
              <br />
              <div>
                <Rating
                  name="half-rating"
                  defaultValue={0}
                  precision={0.5}
                  // style={{ margin: "0rem 1rem" }}
                  onChange={(e) => this.setState({ stars: e.target.value })}
                />
                <br />
                <br />
                <TextField
                  multiline
                  variant="outlined"
                  style={{ width: "100%", height: "5rem" }}
                  size="medium"
                  rows={4}
                  placeholder="Review..."
                  onChange={(e) => this.setState({ message: e.target.value })}
                ></TextField>
                <br />
              </div>
              <DialogActions style={{ marginTop: "2rem" }}>
                <Button
                  variant="contained"
                  style={{
                    background: "#cbcbcb",
                  }}
                  onClick={(e) => this.setState({ reviewDisplay: false })}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    background: "aliceblue",
                  }}
                  disabled={this.state.onprocess}
                  onClick={(e) =>
                    this.submitReview(
                      reviewSnap.location_id,
                      // result.docs[0].data().booking_type,
                      reviewSnap.receipt,
                      reviewSnap.name,
                      reviewSnap.user_id
                    )
                  }
                >
                  Submit
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Snackbar
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={this.state.opensnackbar}
            onClose={this.handelsnackclose}
            style={{
              //display: "none",
              marginBottom: "4rem",
            }}
          >
            <Alert
              variant="filled"
              elevation={6}
              style={{ width: "90vw" }}
              severity={this.state.severity}
              action={
                <IconButton
                  aria-label="close"
                  //color="inherit"
                  onClick={this.handelsnackclose}
                  size="small"
                  style={{ color: "inherit" }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {this.state.error_message}
            </Alert>
          </Snackbar>
          <CheckOutSteps step1 />
          <Divider style={{ margin: "8px 8px 20px" }} />
          {/* <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
          <Hotspot exploreNone
          />
          <Divider style={{ margin: "8px 8px 15px" }} />
          {JSON.parse(localStorage.getItem("bed_type")).map((name, key) =>
            this.card(classes, name, key)
          )}
          <Paper style={{ margin: "8px" }}>
            <List component="nav" aria-label="main mailbox folders">
              <div className="selection">
                <ListItem button>
                  <ListItemText>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <Typography
                          color="textSecondary"
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            Number of Nights :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            {this.numberOfDays()}
                      </Typography>
                    </Grid>
                    </Grid>
      {JSON.parse(localStorage.getItem("bed_type")).map(
                      (name, key) => (
      <Grid container spacing={1}>                     
<Grid item xs={9}>
        <Typography
                    color="textSecondary"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      }}
                    >
        {name} (selected):
        </Typography>                 
        </Grid>
        <Grid item xs>
        <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                        }}
                        >
       {this.state.count[key]}
        </Typography>
        </Grid>
      </Grid>
         )
         )} 
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText>
                  <Grid container spacing={1}>
        <Grid item xs={9}>
        <Typography
                    color="textPrimary"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                      }}
                    >
        Total :
        </Typography>
        </Grid>
        <Grid item xs>
        <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                        }}
                        >
        {this.totalAmount()} {this.state.currency}
        {localStorage.getItem("tax") > 0
                      ? ` + ${localStorage.getItem("tax")}% GST`
                      : ""}
        </Typography>
        </Grid>
      </Grid>
                  </ListItemText>
                </ListItem>
              </div>
            </List>
          </Paper>
          <div style={{ textAlign: "center", margin: "15px 8px 30px" }}>
            {this.state.count[0] === 0 && this.state.count[1] === 0 ? (
              <Button
                type="button"
                style={{
                  width: "100%",
                  fontFamily: "Poppins",
                  background: "#ffde17",
                }}
                variant="contained"
                disabled
                onClick={this.handleProceed}
              >
                Proceed
              </Button>
            ) : (
              <Button
                type="button"
                style={{
                  width: "100%",
                  fontFamily: "Poppins",
                  background: "#ffde17",
                }}
                variant="contained"
                onClick={this.handleProceed}
              >
                Proceed
              </Button>
            )}
          </div>
          <Dialog
            className="GuestDetails"
            open={this.state.guest_details_dialog}
            //aria-labelledby="alert-dialog-title"
            //aria-describedby="alert-dialog-description"
          >
            <CloseIcon
              style={{ position: "absolute", top: "5px", right: "5px" }}
              onClick={ () => this.setState({ guest_details_dialog: false, guest_details: {} }) }
            />
{/* , guest_details: {} */}
            <DialogTitle
              style={{
                paddingBottom: "0",
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
              //id="alert-dialog-title"
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                Guest Details
              </Typography>
              
            </DialogTitle>
            <DialogContent>
              <div className="guest_form">
                <form onSubmit={(e) => this.handleSubmit(e)}>
                  {this.state.bed_type.map((type, key) => (
                    <div style={{ marginTop: "20px" }} key={key}>
                      {this.state.count[key] > 0 && (
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                          }}
                        >
                          {type}
                        </div>
                      )}

                      {this.details(type, key)}
                    </div>
                  ))}
                  <br />
                  <div className="guest_submit">
                    <Button
                      style={{
                        width: "100%",
                        fontFamily: "Poppins",
                        background: "#ffde17",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Done
                    </Button>
                  </div>
                </form>
              </div>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      );
    } catch (err) {
      history.push("/");
      window.location.reload();
      localStorage.clear();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    avail: state.availReducer,
    availableNow: state.availableNow,
    mixed_details: state.mixedGuestDetails,
    female_details: state.femaleGuestDetails,
    mixed_temp_details: state.mixedTempDetails,
    female_temp_details: state.femaleTempDetails,
    user: state.userProfile,
    currency: state.currency,
    availableHotspot: state.availableHotspot,
    hotspotSelected: state.hotspotSelection,
  };
};

const mapDispatchToProps = () => {
  return {
    date,
    update_available,
    mixedGuestDetails,
    femaleGuestDetails,
    mixedTempDetails,
    femaleTempDetails,
    fetchuserdata,
    userProfile,
    hotspotGuestDetails,
    hotspotSelection,
  };
};

const styles = withStyles(useStyles)(BookHotspot);

export default connect(mapStateToProps, mapDispatchToProps())(styles);
