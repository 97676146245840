import React from "react";
import Divider from "@material-ui/core/Divider";
import Navbar from "./navbar/navbarMui";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Footer from "./footer";
const useStyles = (theme) => ({
  root: {
    marginTop: "10px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
});

class Hotspots extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navbar />
        <Link to="/">
          <CloseRoundedIcon
          className="crossIcon"
            fontSize="large"
            style={{
              color: "#ffde17",
              position: "absolute",
              right: "10px",
              marginTop: "10px",
              opacity: "1",
              zIndex: "1",
            }}
          />
        </Link>
        <Card style={{ margin: "10px" }} className={classes.root}>
          <Link to="explore/sky_walk">
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  SW
                </Avatar>
              }
              title="HOTSPOT Sky Walk"
              subheader="Above Clouds"
            />
            <CardMedia
              className={classes.media}
              image="/location/skywalk.jpeg"
              title="Paella dish"
            />
          </Link>

          <CardContent>
            <Typography
              style={{
                fontSize: "13px",
                fontFamily: "Poppins",
              }}
              variant="body2"
              // color="textSecondary"
              component="p"
            >
              Sky walk.Discover offbeat, eat local, experience raw at our HOTSPOT.
              Indulge in something very basic yet very satiating. Try out the
              community kitchens, meet local storytellers, hike faint trails,
              soak in hidden pools. Yes, its all possible at our hotspots.
            </Typography>
          </CardContent>
          <CardActions disableSpacing></CardActions>
        </Card>
              

              {/* ------------------Sky WAlk---------------------  */}
        
        <Card style={{ margin: "10px" }} className={classes.root}>
          <Link to="explore/ecopulse_kurseong">
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  E
                </Avatar>
              }
              title="HOTSPOT Ecopulse"
              subheader="Beautiful Landscape"
            />
            <CardMedia
              className={classes.media}
              image="/location/darjeeling.jpg"
              title="Paella dish"
            />
          </Link>

          <CardContent>
            <Typography
              style={{
                fontSize: "13px",
                fontFamily: "Poppins",
              }}
              variant="body2"
              // color="textSecondary"
              component="p"
            >
              Discover offbeat, eat local, experience raw at our HOTSPOT.
              Indulge in something very basic yet very satiating. Try out the
              community kitchens, meet local storytellers, hike faint trails,
              soak in hidden pools. Yes, its all possible at our hotspots.
            </Typography>
          </CardContent>
          <CardActions disableSpacing></CardActions>
        </Card>

        <Footer bottom />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Hotspots);
