import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import {
  availableDestinations,
  exploreWorkpad,
  update_available,
} from "../../actions";
import Error from "./error";
import { Redirect } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

const moment = require("moment-timezone");

const useStyles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "95%",
    },
  },
});

const formValid = ({ formErrors, location_id, check_in, check_out }) => {
  let valid = true;

  if (location_id === "") {
    valid = false;
    formErrors.location_id = "please select a valid location";
  }

  if (check_in === null) {
    valid = false;
    formErrors.check_in = "please select a valid check in date";
  }

  if (check_out === null) {
    valid = false;
    formErrors.check_out = "please select a valid check out date";
  }

  return valid;
};

class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location_id: "",
      check_in: null,
      check_out: null,
      clicked: false,
      mixed_dorm_price: null,
     // female_dorm_price: null,
      formErrors: {
        location_id: "",
        check_in: "",
        check_out: "",
      },
      redirect: false,
    };
  }

  componentDidMount() {
    // alert("WOW")
    if (localStorage.getItem("check_in_W")) {
      const today = moment.tz("Asia/Calcutta").format("YYYY-MM-DD");
      const check_in = localStorage.getItem("check_in_W");

      moment(check_in).isSameOrAfter(today)
        ? this.setState({
            check_in: localStorage.getItem("check_in_W"),
          })
        : this.setState({
            check_in: today,
          });
    }

    if (
      localStorage.getItem("check_in_W") &&
      localStorage.getItem("check_out_W")
    ) {
      moment(localStorage.getItem("check_out_W")).isSameOrAfter(
        moment(localStorage.getItem("check_in_W"))
          .add(7, "day")
          .endOf("day")
          .format("YYYY-MM-DD")
      )
        ? this.setState({
            check_out: localStorage.getItem("check_out_W"),
          })
        : this.setState({
            check_out: moment(localStorage.getItem("check_in_W"))
              .add(7, "day")
              .endOf("day")
              .format("YYYY-MM-DD"),
          });
    }

    localStorage.getItem("W_location_id")
      ? this.setState({
          location_id: localStorage.getItem("W_location_id"),
        })
      : this.setState({ location_id: "" });

    if (
      localStorage.getItem("check_in_W") !== null ||
      localStorage.getItem("check_out_W") !== null ||
      localStorage.getItem("W_location_id") !== null
    ) {
      this.setState({ clicked: true });
    }

    this.props.availableDestinations();
    if (
      localStorage.getItem("check_in_W") !== null &&
      localStorage.getItem("check_out_W")
    ) {
      this.setState({
        // check_in: localStorage.getItem("check_in"),
        // check_out: localStorage.getItem("check_out"),
        // location_id: localStorage.getItem("location_id"),
        mixed_dorm_price: localStorage.getItem("W_mixed_dorm_price"),
        mixed_dorm_price_D: localStorage.getItem("W_mixed_dorm_price_D"),
        mixed_dorm_price_E: localStorage.getItem("W_mixed_dorm_price_E"),
        //female_dorm_price: localStorage.getItem("W_female_dorm_price"),
        //female_dorm_price_D: localStorage.getItem("W_female_dorm_price_D"),
        //female_dorm_price_E: localStorage.getItem("W_female_dorm_price_E"),
      });
    }

    //across refresh
    window.location.pathname === "/workpad" &&
      this.setState(
        { location_id: localStorage.getItem("W_location_id") },
        () => {
          this.explore();
        }
      );
  }

  componentDidUpdate() {
    moment(this.state.check_in).isAfter(
      moment(this.state.check_out)
        .subtract(7, "day")
        .endOf("day")
        .format("YYYY-MM-DD")
    ) &&
      this.setState({
        check_out: moment(this.state.check_in)
          .add(7, "day")
          .endOf("day")
          .format("YYYY-MM-DD"),
      });
  }

  handleFromDateChange = (date) => {
    const { formErrors } = this.state;
    formErrors.check_in = "";
    window.location.pathname === "/workpad"
      ? this.setState({ check_in: date.toISOString().slice(0, 10) }, () => {
          this.explore();
        })
      : this.setState({ check_in: date.toISOString().slice(0, 10) });
  };

  handleToDateChange = (date) => {
    const { formErrors } = this.state;
    formErrors.check_out = "";
    window.location.pathname === "/workpad"
      ? this.setState({ check_out: date.toISOString().slice(0, 10) }, () => {
          this.explore();
        })
      : this.setState({ check_out: date.toISOString().slice(0, 10) });
  };

  handleChange = (event) => {
    const { formErrors } = this.state;
    formErrors.location_id = "";

    for (let i = 0; i < this.props.Destinations.length; i++) {
      if (this.props.Destinations[i].location_id === event.target.value) {
        localStorage.setItem(
          "W_mixed_dorm_price",
          this.props.Destinations[i].W_mixed_dorm_price
        );
        localStorage.setItem(
          "W_mixed_dorm_price_D",
          this.props.Destinations[i].W_mixed_dorm_price_D
        );
        localStorage.setItem(
          "W_mixed_dorm_price_E",
          this.props.Destinations[i].W_mixed_dorm_price_E
        );
        // localStorage.setItem(
        //   "W_female_dorm_price",
        //   this.props.Destinations[i].W_female_dorm_price
        // );
        // localStorage.setItem(
        //   "W_female_dorm_price_D",
        //   this.props.Destinations[i].W_female_dorm_price_D
        // );
        // localStorage.setItem(
        //   "W_female_dorm_price_E",
        //   this.props.Destinations[i].W_female_dorm_price_E
        // );
        localStorage.setItem(
          "W_destination",
          this.props.Destinations[i].location
        );
        localStorage.setItem("tax", this.props.Destinations[i].tax);
      }
    }
    window.location.pathname === "/workpad"
      ? this.setState({ location_id: event.target.value }, () => {
          this.explore();
        })
      : this.setState({ location_id: event.target.value });
    // this.props.Destinations.forEach(doc => {
    //   console.log('insice')
    //   if(doc.location_id === event.target.value){
    //     this.setState({mixed_dorm_price : doc.mixed_dorm_price, female_dorm_price : doc.female_dorm_price})
    //   }
    // })
  };

  explore = () => {
    if (this.props.landing) {
      if (formValid(this.state)) {
        this.setState({ redirect: true });
        this.props.exploreWorkpad(this.state).then((res) => {
          let min_mixed = 100;
            //min_female = 10000;
          for (const [key, value] of Object.entries(this.props.avail)) {
            if (
              key >= localStorage.getItem("check_in_W").slice(2) &&
              key <= localStorage.getItem("check_out_W").slice(2)
            ) {
              if (value.mixed < min_mixed) min_mixed = value.mixed;
              //if (value.female < min_female) min_female = value.female;
            }
          }
          this.props.update_available({
           // female: min_female,
            mixed: min_mixed,
          });
        });
      } else {
        this.setState({
          errVisible: true,
          errText: "Please fill correct inputs",
        });
      }
    } else {
      if (formValid(this.state)) {
        this.props.exploreWorkpad(this.state).then((res) => {
          let min_mixed = 100;
           // min_female = 10000;
          for (const [key, value] of Object.entries(this.props.avail)) {
            if (
              key >= localStorage.getItem("check_in_W").slice(2) &&
              key <= localStorage.getItem("check_out_W").slice(2)
            ) {
              if (value.mixed < min_mixed) min_mixed = value.mixed;
             // if (value.female < min_female) min_female = value.female;
            }
          }

          this.props.update_available({
           // female: min_female,
            mixed: min_mixed,
          });
        });
      } else {
        this.setState({
          errVisible: true,
          errText: "Please fill correct inputs",
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { formErrors } = this.state;
    if (Object.keys(this.props.Destinations).length === 0) {
      // return <Skeleton variant="rect" height={118} />;\
      return (
        <React.Fragment>
          <Skeleton
            variant="rect"
            style={{ margin: "auto", marginTop: "10px", width: "90%" }}
            height={118}
          />
          <Skeleton
            style={{ margin: "auto", marginTop: "10px", width: "90%" }}
            variant="text"
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section style={{ marginTop: "15px" }}>
            <form className={classes.root} noValidate autoComplete="off">
              <div className="location">
                <TextField
                  onClick={() => this.setState({ clicked: true })}
                  id="standard-select-currency"
                  select
                  label="Location"
                  size="small"
                  value={this.state.location_id}
                  onChange={this.handleChange}
                  variant="outlined"
                >
                  {this.props.Destinations.map((option) => (
                    <MenuItem
                      key={option.location_id}
                      value={option.location_id}
                    >
                      {option.location}
                    </MenuItem>
                  ))}
                </TextField>
                {formErrors.location_id.length > 0 && (
                  <span className="errorMessage">{formErrors.location_id}</span>
                )}
              </div>
            </form>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                style={{ width: "99%", margin: "auto" }}
                container
                spacing={3}
                className={` ${this.state.clicked ? "" : "display-none"}`}
              >
                <Grid style={{ padding: "0 8px" }} item xs={6}>
                  <DatePicker
                    style={{ margin: "8px 0" }}
                    margin="normal"
                    id="date-picker-from"
                    label="From"
                    format="dd/MM/yyyy"
                    disablePast
                    value={this.state.check_in}
                    inputVariant="outlined"
                    size="small"
                    maxDate={moment(
                      moment.tz("Asia/Calcutta").format("YYYY-MM-DD")
                    )
                      .add(90, "day")
                      .endOf("day")
                      .format("MM/DD/YYYY")}
                    allowKeyboardControl={false}
                    onChange={this.handleFromDateChange}
                    // KeyboardButtonProps={{
                    //   "aria-label": "change date",
                    // }}
                  />
                  {formErrors.check_in.length > 0 && (
                    <span className="errorMessage">{formErrors.check_in}</span>
                  )}
                </Grid>
                <Grid style={{ padding: "0 8px" }} item xs={6}>
                  <DatePicker
                    style={{ margin: "8px 0" }}
                    margin="normal"
                    id="date-picker-to"
                    label="To"
                    format="dd/MM/yyyy"
                    value={this.state.check_out}
                    inputVariant="outlined"
                    size="small"
                    minDate={moment(this.state.check_in)
                      .add(7, "day")
                      .endOf("day")
                      .format("MM/DD/YYYY")}
                    maxDate={moment(
                      moment.tz("Asia/Calcutta").format("YYYY-MM-DD")
                    )
                      .add(91, "day")
                      .endOf("day")
                      .format("MM/DD/YYYY")}
                    onChange={this.handleToDateChange}
                    // KeyboardButtonProps={{
                    //   "aria-label": "change date",
                    // }}
                  />
                  {formErrors.check_out.length > 0 && (
                    <span className="errorMessage">{formErrors.check_out}</span>
                  )}
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <div
              className={`explore-btn ${classes.root} ${
                this.props.exploreNone && "display-none"
              }`}
            >
              <Button
                style={{ width: "95%", fontFamily: "Poppins" }}
                onClick={this.explore}
                variant="contained"
                color="inherit"
              >
                Explore
              </Button>
            </div>
          </section>
          {this.state.redirect ? <Redirect to="/workpad" /> : ""}
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    Destinations: Object.values(state.availableDestinations),
    avail: state.availReducer,
  };
};

const styles = withStyles(useStyles)(Booking);

export default connect(mapStateToProps, {
  availableDestinations,
  exploreWorkpad,
  update_available,
})(styles);
