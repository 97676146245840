import React from "react";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import PauseIcon from "@material-ui/icons/Pause";
import TrendingFlatOutlinedIcon from "@material-ui/icons/TrendingFlatOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

class CheckoutSteps extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "8px 0",
        }}
      >
        <div>
          {this.props.step1 ? (
            this.props.step1C ? (
              <Chip
                style={{ color: "green" }}
                icon={<DoneIcon style={{ color: "green" }} />}
                label="Explore"
                variant="outlined"
              />
            ) : (
              <Chip
                style={{ color: "green" }}
                icon={
                  <FiberManualRecordIcon
                    style={{ color: "green", fontSize: "12px" }}
                  />
                }
                label="Explore"
                variant="outlined"
              />
            )
          ) : (
            <Chip
              // avatar={<PauseIcon />}
              label="Explore"
              disabled
              variant="outlined"
            />
          )}
        </div>
        <TrendingFlatOutlinedIcon style={{ color: "green", margin: "9px 0" }} />
        <div>
          {this.props.step2 ? (
            this.props.step2C ? (
              <Chip
                style={{ color: "green" }}
                icon={<DoneIcon style={{ color: "green" }} />}
                label="Review"
                variant="outlined"
              />
            ) : (
              <Chip
                style={{ color: "green" }}
                icon={
                  <FiberManualRecordIcon
                    style={{ color: "green", fontSize: "12px" }}
                  />
                }
                label="Review"
                variant="outlined"
              />
            )
          ) : (
            <Chip
              // avatar={<PauseIcon />}
              label="Review"
              disabled
              variant="outlined"
            />
          )}
        </div>
        <TrendingFlatOutlinedIcon style={{ color: "green", margin: "9px 0" }} />
        <div>
          {this.props.step3 ? (
            this.props.step3C ? (
              <Chip
                style={{ color: "green" }}
                icon={<DoneIcon style={{ color: "green" }} />}
                label="Payment"
                variant="outlined"
              />
            ) : (
              <Chip
                style={{ color: "green" }}
                icon={
                  <FiberManualRecordIcon
                    style={{ color: "green", fontSize: "12px" }}
                  />
                }
                label="Payment"
                variant="outlined"
              />
            )
          ) : (
            <Chip
              // avatar={<PauseIcon />}
              label="Payment"
              disabled
              variant="outlined"
            />
          )}
        </div>
      </div>
    );
  }
}

export default CheckoutSteps;
