import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
});

class Review extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Card
          style={{
            margin: "0px 0px",
          }}
          className={classes.root}
        >
          <div className="containerImg" >
          <CardMedia
            className={`dormImages`}
            style={{
              height: "100%",
              width: "100%",
              
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              // position: "relative",
              // background: "gainsboro",
              // fontFamily: "Poppins",
              // fontWeight: "600",
              // textAlign: "center",
              // width: "25%",
              // fontSize: "18px",
              // // zIndex: "1",
              // overflowWrap: "anywhere",
            }}
          >
            &nbsp;
            {/* <PermIdentityIcon fontSize="large" />
            {name === "Two Person Shared Tent" ? "Shared Tent" : name} */}
          </CardMedia>
          </div>
          <div style={{ width: "70%" }} className={classes.details}>
            <CardContent style={{ padding: "8px", display: "flex", flexDirection: "column", justifyContent: "space-around" }} className={classes.content}>
              <div
              className="desktopFont"
              >
                {this.props.name} *
              </div>
              <Typography
                style={{ fontFamily: "Poppins", fontSize: "12px", marginBottom: "10px",}}
              >* Solo adventurers/odd groups may have to share tent with fellow adventurers
                
              </Typography>
              <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            Tariff :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                             {this.props.tariff}
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            No. of Nights: :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                             {this.props.nights}
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            No. of Beds: :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                             {this.props.beds_count}
                      </Typography>
                    </Grid>
                    </Grid>
              {/* <Typography
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
              >
                Tarrif:&nbsp;
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                  }}
                >
                  {this.props.tariff}
                </span>
              </Typography>
              <Typography
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
              >
                No. of Beds:&nbsp;
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                  }}
                >
                  {this.props.beds_count}
                </span>
              </Typography> */}
            </CardContent>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Review);
