import { combineReducers } from "redux";
import availReducer from "./availReducer";
import availableNow from "./availableNow";
import mixedGuestDetails from "./mixedGuestDetails";
import femaleGuestDetails from "./femaleGuestDetails";
import femaleTempDetails from "./femaleTempDetails";
import mixedTempDetails from "./mixedTempDetails";
import availableDestinations from "./availableDestinations";
import admin from "./admin";
import userProfile from "./profile";
import userBookings from "./userBookings";
import notification from "./notification";
import offers from "./offers";
import currency from "./currency";
import authreducer from "./authreducer";
import selected from "./selected";
import availableHotspot from "./availableHotspot";
import hotspotGuestDetails from "./hotspotGuestDetails";
import hotspotDetails from "./hotspotDetails";
import hotspotSelection from "./hotspotSelection";
import livesoon from "./livesoon";
export default combineReducers({
  livesoon,
  availReducer,
  availableNow,
  authreducer,
  mixedGuestDetails,
  femaleGuestDetails,
  mixedTempDetails,
  femaleTempDetails,
  availableDestinations,
  admin,
  userProfile,
  userBookings,
  notification,
  offers,
  currency,
  selected,
  availableHotspot,
  hotspotGuestDetails,
  hotspotDetails,
  hotspotSelection,
});
