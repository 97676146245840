let states = {
  female_dorm_available: null,
  mixed_dorm_available: null,
};

const availableNow = (state = states, action) => {
  if (action.type === "update_available") {
    return {
     // female_dorm_available: action.payload.female,
      mixed_dorm_available: action.payload.mixed,
    };
  } else {
    return state;
  }
};

export default availableNow;
