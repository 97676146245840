import { Component, Fragment, createRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import history from "../../../helpers/history";
import Logo from "../../../assets/logo.png";
import "../../../assets/css/navbar.css";
import {
  Button,
  MenuList,
  MenuItem,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  SwipeableDrawer,
  SnackbarContent,
  Divider,
  Avatar,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
// import Badge from "@material-ui/core/Badge";
// import NotificationsIcon from "@material-ui/icons/Notifications";

import { currency } from "../../../actions";

import {
  Menu as MenuIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  // AccountCircleRounded as AccountCircleRoundedIcon,
  HighlightOffOutlined as HighlightOffOutlinedIcon,
} from "@material-ui/icons";
import { TreeView, TreeItem } from "@material-ui/lab";
import UserNotification, { onLogoutUnsuscribe } from "./usernotification";
const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "INR",
    label: "₹",
  },
  {
    value: "EUR",
    label: "€",
  },
];
const arr = ["red", "blue", "green", "pink"];
const color = arr[Math.floor(Math.random() * 10) % 4];
localStorage.setItem("Avatar_color", color);
class Navbar extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    // const anchorRef = useRef(null);
    this.anchorRef = createRef();
    this.anchorRef1 = createRef();
    this.state = {
      clicked: false,
      openmenu: false,
      openmenu1: false,
      sidebar: false,
      currency: "INR",
      Snackbar: true,
      openLOGOUT: false,
    };
  }

  componentDidMount() {
    localStorage.getItem("currency") &&
      this.setState({ currency: localStorage.getItem("currency") });
    // console.log(this.props.notification.text);
    !this.props.notification.show && this.setState({ Snackbar: false });
  }

  handleLOGOUTD = () => {
    this.setState({ openLOGOUT: true });
  };

  handleLOGOUTDC = () => {
    this.setState({ openLOGOUT: false });
  };

  signout = async () => {
    // console.log("user signout");
    await onLogoutUnsuscribe();
    auth.signOut();
    // console.log(history);
    history.push({
      pathname: "/login",
    });
    this.setState({ openLOGOUT: false });
    this.forceUpdate();
  };
  handleToggle = () => {
    // this.setState({ openmenu: !this.state.openmenu });
    // console.log(this.state.openmenu);
    history.push("/profile");
  };
  handleClose = () => {
    this.setState({ openmenu: false });
  };
  handleToggle1 = () => {
    this.setState({ openmenu1: !this.state.openmenu1 });
  };
  handleClose1 = () => {
    this.setState({ openmenu1: false });
  };

  opensidebar = (e) => {
    // e.preventDefault();
    this.setState({ sidebar: !this.state.sidebar });
  };
  loginuser = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/login",
    });
  };

  handleChange = (event) => {
    this.setState({ currency: event.target.value });
    localStorage.setItem("currency", event.target.value);
    this.props.currency(event.target.value);
  };
  render() {
    const user = this.context;
    // if (user) console.log("user:", user);
    return (
      <Fragment>
        <AppBar
          position="sticky"
          style={{
            background: "none",
            position: "sticky",
            boxShadow: "none",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              outline: "none",
              backgroundColor: "white",
              color: "black",
              background: "white",
              padding: "6px 0px",
            }}
          >
            <IconButton
              edge="start"
              aria-label="menu"
              style={{
                outline: "none",
                color: "#ffde17",
                padding: "8px",
                margin: "0",
              }}
              onClick={this.opensidebar}
            >
              <MenuIcon style={{ fontSize: "35px" }} />
            </IconButton>
            <Link
              to="/"
              style={{
                color: "inherit",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                verticalAlign: "center",
                justifyContent: "space-evenly",
                position: "absolute",
                right: "129px",
              }}
            >
              <img alt="Logo" src={Logo} style={{ maxWidth: "1.3rem" }} />
              <span
                style={{
                  fontFamily: "FredokaOne",
                  fontWeight: "500",
                  fontSize: "1.7rem",
                  paddingLeft: ".2rem",
                  color: "#333333",
                }}
              >
                bunkbread
              </span>
            </Link>

            <div
              className="userInfo"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <UserNotification />
              {/* code shifted to usernotification (same) */}
              {auth.currentUser === null ? (
                <>
                  <Button
                    style={{ padding: "6px 8px 6px" }}
                    onClick={this.loginuser}
                  >
                    LOGIN
                  </Button>
                </>
              ) : (
                <div>
                  <IconButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    style={{
                      minWidth: "50px",
                      outline: "none",
                      padding: "6px 8px 6px",
                    }}
                    onClick={this.handleToggle}
                    ref={this.anchorRef}
                  >
                    {/* <AccountCircleRoundedIcon style={{ fontSize: "30px" }} /> */}
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        background: localStorage.getItem("Avatar_color")
                          ? localStorage.getItem("Avatar_color")
                          : color,
                      }}
                    >
                      {auth.currentUser.displayName !== null
                        ? auth.currentUser.displayName.slice(0, 1)
                        : "Hi"}
                    </Avatar>
                  </IconButton>
                  {/* <Popover
                    id="customized-menu"
                    anchorEl={this.anchorRef.current}
                    open={this.state.openmenu}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    style={{ marginTop: "1rem" }}
                  >
                    <MenuList>
                      <MenuItem onClick={this.popmenu("profile")}  >Profile</MenuItem>
                      <MenuItem onClick={this.popmenu("booking")}>Bookings</MenuItem>
                    </MenuList>
                  </Popover> */}
                </div>
              )}
            </div>
          </Toolbar>
          <Divider />
        </AppBar>
        {window.location.pathname === "/" && (
          <div style={{ display: !this.state.Snackbar && "none" }}>
            <HighlightOffOutlinedIcon
              onClick={() => this.setState({ Snackbar: false })}
              style={{
                position: "absolute",
                right: "8px",
                color: "#ffde17",
                width: "100%",
              }}
            />
            <SnackbarContent message={this.props.notification.text} />
          </div>
        )}

        <SwipeableDrawer
          //   anchor={this.anchorRef.current}
          open={this.state.sidebar}
          onClose={this.opensidebar}
          onOpen={this.opensidebar}
          id="menu"
          disableBackdropTransition={true}
          className="swipeable"
        >
          <MenuList className="sideMenu" style={{ padding: "1rem 2rem" }}>
            <li
              style={{
                fontFamily: "poppins",
                color: "#555",
                fontSize: "20px",
                padding: "0 0 20px",
              }}
            >
              {auth.currentUser === null ? (
                <Link to="/login">HI SIGN IN</Link>
              ) : (
                <>
                  Hi &nbsp;
                  {auth.currentUser.displayName === null
                    ? ""
                    : auth.currentUser.displayName.split(" ")[0] ||
                      user.displayName}
                </>
              )}
            </li>

            <Divider />

            {auth.currentUser !== null && (
              <Link to="/profile/">
                <MenuItem>PROFILE</MenuItem>
              </Link>
            )}

            {auth.currentUser !== null && (
              <MenuItem style={{ paddingLeft: "0", marginLeft: "-7px" }}>
                <TreeView
                  // className={classes.root}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  <TreeItem
                    className="navbarBookings"
                    nodeId="1"
                    label="BOOKINGS"
                  >
                    <Link to="/bookings/previous">
                      <TreeItem
                        className="navbar_hover"
                        style={{ padding: "15px" }}
                        nodeId="2"
                        label="PREVIOUS"
                      />
                    </Link>
                    <Divider />

                    <Link to="/bookings/upcoming">
                      <TreeItem
                        className="navbar_hover"
                        style={{ padding: "15px" }}
                        nodeId="4"
                        label="UPCOMING"
                      />
                    </Link>
                    <Divider />

                    <Link to="/bookings/deleted">
                      <TreeItem
                        className="navbar_hover"
                        style={{ padding: "15px" }}
                        nodeId="3"
                        label="CANCELED"
                      />
                    </Link>
                  </TreeItem>
                </TreeView>
              </MenuItem>
            )}
            <Link to="/aboutus">
              <MenuItem>ABOUT US</MenuItem>
            </Link>

            <Link to="/destinations">
              <MenuItem>DESTINATIONS</MenuItem>
            </Link>

            <Link to="/explorehotspots">
              <MenuItem>HOTSPOTS</MenuItem>
            </Link>

            <Link to="/franchise">
              <MenuItem>FRANCHISE</MenuItem>
            </Link>

            <Link to="/contactus">
              <MenuItem>CONTACT US</MenuItem>
            </Link>

            {/* <Link to="/navbar">Navbar</Link> */}
            {auth.currentUser !== null && (
              <MenuItem onClick={this.handleLOGOUTD}>LOGOUT</MenuItem>
            )}
            <Divider />
            <MenuItem>
              <TextField
                className="currency"
                id="outlined-select-currency-native"
                select
                style={{ width: "50px", margin: "auto" ,}}
                value={this.state.currency}
                onChange={this.handleChange}
                InputProps={{
                  readOnly:
                    window.location.pathname === "/summary" ? true : false,
                }}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Typography
                      style={{ fontFamily: "poppins", fontSize: "16px" }}
                    > {option.label}</Typography>                  
                  </MenuItem>
                ))}
              </TextField>
            </MenuItem>
          </MenuList>
        </SwipeableDrawer>
        <Dialog
          className="LogoutConfirm"
          open={this.state.openLOGOUT}
          onClose={this.handleLOGOUTDC}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
            <Typography
              style={{ fontFamily: "poppins", fontSize: "16px" }}
            >
              ARE YOU SURE YOU WANT TO LOGOUT?
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "8px" }} component="div">
            <DialogContentText
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontSize: "12px",
              }}
              component="div"
              id="alert-dialog-description"
            >
              <Button
                style={{
                  width: "45%",
                  padding: "8px 0",
                  fontFamily: "sans-serif",
                  background: "#ffde17",
                  color: "black",
                }}
                variant="contained"
                onClick={this.signout}
              >
                LOGOUT
              </Button>
              <Button
                style={{
                  width: "45%",
                  padding: "8px 0",
                  fontFamily: "Poppins",
                  background: "#ffde17",
                  color: "black",
                }}
                variant="contained"
                onClick={this.handleLOGOUTDC}
              >
                BACK
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }

  popmenu = (parmas) => {
    history.push("/" + parmas);
  };
}
function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps, { currency })(Navbar);
