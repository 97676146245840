import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Booking from "./booking";
import Workpad from "./workpad";
import { connect } from "react-redux";
import { selected } from "../../actions";
import Hotspot from "./Hotspot";
import HOSTEL from "../../assets/hostelTAB1.jpg";
import WORKPAD from "../../assets/workpadTAB.jpeg";
const useStyles = (theme) => ({
  root: {
    //flexGrow: 1,
     '& .MuiTab-textColorPrimary.Mui-selected ': {
      color : 'rgba(0,0,0,0.70)',
    }, 
    /**' & MuiList-root.MuiMenu-list.MuiList-padding' :{
      padding : '0px',
    },*/
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0" }} p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  componentDidMount() {
    this.props.selected(this.state.value);
  }

  componentDidUpdate() {
    this.props.selected(this.state.value);
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper
          style={{ margin: "20px 10px", height: "230px" }}
          className={`handleSwitch ${classes.root}`}
        >
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            textColor="primary"
            centered="true"
            className="Switch"
            variant="fullWidth"
            TabIndicatorProps={{ style: { background: "none" } }}
          >
            <Tab label="Hostels" />
            <Tab label="Workpad" />
            <Tab label="Hotspots" />
          </Tabs>
          <TabPanel value={this.state.value} index={0}>
            <Booking landing={true} />

            {/* <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // margin: "2rem 0rem",
                flexDirection: "column",
               // padding:"0px 28px",
              }}
              variant="h5"
            >
              <img src={HOSTEL} alt="hostel_img" height="140px"></img>
              <span style={{ fontWeight: "bold", fontFamily :"poppins" }}>Coming Soon!</span>
            </Typography> */}
          </TabPanel>

          <TabPanel value={this.state.value} index={1}>
            <Workpad landing={true} />

            {/* <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // margin: "2rem 0rem",
                flexDirection: "column",
                //padding:"0px 28px",
                }}
              variant="h5"
            >
              <img src={WORKPAD} alt="hostel_img" height="140px"></img>
              <span style={{ fontWeight: "bold", fontFamily :"poppins" }}>Coming Soon!</span>
            </Typography> */}
          </TabPanel>

          <TabPanel value={this.state.value} index={2}>
            <Hotspot />
          </TabPanel>
        </Paper>
      </React.Fragment>
    );
  }
}

const styles = withStyles(useStyles)(Switch);

export default connect(null, { selected })(styles);
