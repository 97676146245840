import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

class GuestDetails extends React.Component {
  render() {
    return (
      // <div className="guest_details_main">
      //     <span>{this.props.name}</span>
      //     <span>{this.props.age}</span>
      //     <span>{this.props.gender}</span>
      // </div>
      <React.Fragment>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "50%" }}
                >
                  {this.props.name}
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "25%" }}
                  align="left"
                >
                  {this.props.age}
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                  align="left"
                >
                  {this.props.gender}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }
}

export default GuestDetails;
