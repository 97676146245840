import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      // slidesToShow: this.state.width > 465 ? 3 : 2,
      slidesToScroll: 1,
      cssEase: "linear",
    };

    return (
      <React.Fragment>
        <section id="detail">
          <div className="container mt-5 carousel">
            <h1
              className="slider_title"
            >
             <div className="newHeaderDark">Explore Hotspots</div>
            </h1>
            <Slider {...settings}>
              <Link to="/explore/ecopulse_kurseong" className="card-wrapper1">
                <div className="card">
                  <div className="card-image1">
                    <img alt="..." src="./location/ecopulse_h.jpg" />
                    <p>
                      HOTSPOT Ecopulse
                    </p>
                  </div>

                  {/* <div className="details1">                                          
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        }}
                        // className="job-title"
                      >
                        Beautiful Landscape
                      </span>
                    </div> */}
                </div>
              </Link>
              <Link to="/explore/sky_walk" className="card-wrapper1">
                <div className="card">
                  <div className="card-image1">
                    <img alt="..." src="./location/skywalk.jpeg" />
                    <p>
                      HOTSPOT SkyWalk
                    </p>
                  </div>
                </div>
              </Link>
              {/*<Link to="/explore/siliguri" className="card-wrapper">
                <div className="card">
                  <div className="card-image">
                    <img alt="..." src="./location/siliguri.jpg" />
                  </div>

                  <div className="details">
                    <h2>
                      Siliguri
                      <span className="job-title">
                        Gateway of northeast India
                      </span>
                    </h2>
                  </div>
                </div>
              </Link> */}
            </Slider>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Navbar;
