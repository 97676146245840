// import { Component } from "react"
import { List, ListSubheader, ListItem } from "@material-ui/core";
export const createDailogueBooking = (
  pnr,
  totalGuest,
  location,
  check_in,
  check_out,
  currency,
  amount,
  guestArray
) => {
  // var arryM = [];
  return (
    <>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {pnr}{" "}
        <span>
          <b>Guests: </b>
          {totalGuest}
        </span>
      </p>
      <p>
        <b>Location</b> : {location}
      </p>

      <p>
        <b>Check In</b> : {check_in} &nbsp; <b>Check Out</b> : {check_out}
      </p>
      <p>
        <b>Total Tariff</b> : {currency} {amount}
      </p>
      <p>
        <b>Guest Details</b> :
      </p>
      <List
        style={{
          flexWrap: "wrap",
          textAlign: "left",
          marginTop: "-1rem",
          marginLeft: "-.7rem",
        }}
      >
        <ListSubheader
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            wordWrap: "break-word",
            //  textAlign: "right",
            width: "100%",
            fontWeight: "bolder",
            color: "black",
            //  width: "25%",
          }}
        >
          <div style={{ width: "25%" }}>{"Name"}&nbsp;</div>
          <div style={{ width: "25%" }}>{"Gender"}&nbsp;</div>
          <div style={{ width: "25%" }}>{"Age"}</div>
        </ListSubheader>
        {functionLIst(guestArray)}
      </List>
    </>
  );
};
const functionLIst = (data) => {
  const v = data.map((mDetails) => {
    // console.log(mDetails);

    return (
      <ListItem
        key={mDetails.name}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          wordWrap: "break-word",
          //  textAlign: "right",
          width: "100%",
        }}
      >
        <div style={{ width: "25%" }}> {mDetails.name}&nbsp;</div>
        <div style={{ width: "25%" }}>{mDetails.gender}&nbsp;</div>
        <div style={{ width: "25%" }}>{mDetails.age}</div>
      </ListItem>
    );
  });
  return v;
};

export const createDailogueCoupon = (title, description, discount, code) => {
  return (
    <>
      <p>
        <b>{title}</b>
      </p>
      <p>Description: {description}</p>
      <p>Discount: {discount}</p>
      <p>Use Coupon: {code}</p>
    </>
  );
};
// export default PopDialogue
