import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      // slidesToShow: this.state.width > 465 ? 3 : 2,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: "linear",
    };

    return (
      <React.Fragment>
        <section id="detail">
          <div className="container mt-5 carousel">
            <h1
              // style={{ fontFamily: "Poppins", fontSize: "20px" }}
              className="slider_title"
            >
              <div className="newHeaderDark">Explore Hostel</div>
            </h1>
            <Slider {...settings}>
              {/* <div className="card-wrapper">
                <div className="card">
                  <div className="card-image">
                    <img alt="..." src="./location/darjeeling.jpg" />
                    <p> Darjeeling</p>
                  </div>
                  {/* <ul className="social-icons">
                    <li>
                      <Link to="/explore">
                        <i className="fab fa-wpexplorer"></i>
                      </Link>
                    </li>
                  </ul> }
                  <div className="details1">                                         
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        }}
                       // className="job-title"
                        >
                        Queen of hills
                      </span>                    
                  </div>
                </div>
              </div> */}

              {/* <Link to="/explore/gangtok" className="card-wrapper">
                <div className="card">
                  <div className="card-image">
                    <img alt="..." src="./location/gangtok.jpg" />
                  </div>
                  <div className="details">
                    <h2>
                      Gangtok
                      <span className="job-title">Land of monasteries</span>
                    </h2>
                  </div>
                </div>
              </Link> */}

<Link to="/explore/siliguri" className="card-wrapper1">
                <div className="card">
                  <div className="card-image1">
                    <img alt="..." src="./location/siliguri.jpg" />
                    <p>bunkbread Siliguri</p>
                  </div>
                  {/* <ul className="social-icons">
                    <li>
                      <Link to="/explore">
                        <i className="fab fa-wpexplorer"></i>
                      </Link>
                    </li>
                  </ul> */}
                  <div className="details1">                    
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        }}
                        //className="job-title"
                      >
                        Gateway of northeast India
                      </span>                
                  </div>
                </div>
              </Link>
              {/* <Link to="/explore" className="card-wrapper">
                <div className="card">
                  <div className="card-image">
                    <img alt="..." src="./4.jpg" />
                  </div>
                  <ul className="social-icons">
                    <li>
                      <Link to="/explore">
                        <i className="fab fa-wpexplorer"></i>
                      </Link>
                    </li>
                  </ul>
                  <div className="details">
                    <h2>
                      Darjelling
                      <span className="job-title">
                        Contrary to popular belief.
                      </span>
                    </h2>
                  </div>
                </div>
              </Link> */}
            </Slider>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Navbar;
