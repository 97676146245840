import { mixedGuestDetails, femaleGuestDetails } from "../../../actions";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Badge,
  ListSubheader,
  Popover,
  Dialog,
  DialogContent,
  Snackbar,
} from "@material-ui/core";
import {
  // Delete as DeleteIcon,
  Notifications as NotificationsIcon,
  OpenInNew as OpeninNewIcon,
  DeleteSweep as ClearAllIcon,
  NotificationsOff as NotificationsOffIcon,
  // TrendingUpRounded,
  Close as CloseIcon,
} from "@material-ui/icons";
import { Skeleton, Alert } from "@material-ui/lab";
import LOGO from "../../../assets/logo.png";
import { Component, Fragment, createRef } from "react";
// import { Link } from "react-router-dom";
import { auth, db } from "../../../firebase";
// import { UserContext } from "../../../providers/UserProvider";
import history from "../../../helpers/history";
import {
  createDailogueCoupon,
  createDailogueBooking,
} from "./popDialogBooking";
import { connect } from "react-redux";
var snap = [];
var authON;
class UserNotification extends Component {
  // static contextType = UserContext;
  constructor(props) {
    super(props);
    this.anchorRef1 = createRef();

    this.state = {
      notify: [],
      count_notify: 0,
      mountcomponent: false,
      openBox: false,
      open: false,
      popID: "",
      loading: false,
      amount: "",
      check_in: "",
      check_out: "",
      currency: "",
      location: "",
      name: "",
      totalGuest: "",
      guestArray: null,
      pnr: "",
      couponCode: "",
      couponTitle: "",
      couponDiscount: "",
      couponDescription: "",
      snackbaropen: false,
    };
  }
  componentDidMount() {
    // console.log(snap.length);
    if (snap.length > 0)
      //console.log(snap);
      snap.forEach((unsubscribe) => {
        // console.log("unsubscribing");
        unsubscribe();
      });
    snap = [];
    this.setState({ mountcomponent: true });
    authON = auth.onAuthStateChanged((user) => {
      // console.log("**** user changed ****", snap.length);
      if (snap.length > 0)
        //console.log(snap);
        snap.forEach((unsubscribe) => {
          // console.log("unsubscribing");
          unsubscribe();
        });
      snap = [];
      if (user !== null) {
        // console.log(user);
        // console.log("fetching data");
        this.renderdata();
      }
    });
  }
  componentWillUnmount() {
    this.setState({ mountcomponent: false });
    authON();
    if (snap.length > 0) {
      snap.forEach((unsubscribe) => {
        unsubscribe();
      });
    }
  }

  openNotificatonBox = () => {
    /// on click Open Notifications
    this.setState({
      // count_notify: 0,
      openBox: !this.state.openBox,
    });
  };
  onClickDeleteNotify = (notification_id) => {
    /// functions trigger when clicked on a notifications
    // console.log(e);
    db.collection("users") /// firestore command to change seen variable to make it hidden
      .doc(auth.currentUser.uid)
      .collection("notifications")
      .doc(notification_id)
      .update({
        seen: true,
      })
      .catch((e) => {
        // console.log("Failed to notification", notification_id);
      })
      .then(() => {
        // console.log("removed notification", notification_id);
      });
  };

  toBookings = (data, docId) => {
    /// to bookings :: currently decrepted
    // console.log(data.inv);
    // this.onClickDeleteNotify(docId);
    if (data.type === "booking") {
      this.onClickDeleteNotify(docId);
      history.push({
        pathname: "/bookings/upcoming",
        state: {
          inr_id: data.inv || "random",
        },
      });
    }
  };

  openDialog = (data, docID) => {
    this.onClickDeleteNotify(docID);
    // debugger;
    //openDialog
    // console.log("opening dailog");
    // console.log(data, docID);
    switch (data.type) {
      case "booking":
        if (data.inv !== undefined) {
          this.setState({ open: true, popID: data.inv || "", loading: true });
          db.collection("bookings")
            .doc(data.inv)
            .get()
            .then((snap) => {
              // console.log(snap.exists);
              if (snap.exists) {
                const allvalues = snap.data();
                // console.log(allvalues);
                var guest_details;
                const {
                  amount,
                  check_in,
                  check_out,
                  currency,
                  location,
                  name,
                  pnr,

                  booking_type,
                } = allvalues;
                if (booking_type === "hotspot") {
                  guest_details = allvalues.guest_details;
                } else {
                  var { female_guest_details, mixed_guest_details } = allvalues;
                  female_guest_details.forEach((element) => {
                    element.gender = "F";
                  });
                  guest_details = { female_guest_details, mixed_guest_details };
                }
                // console.log(guest_details);
                // var addition_data = {};
                // var totalGuest;
                var guestArray = [];
                for (var key in guest_details) {
                  guest_details[key].map((item) => {
                    guestArray.push(item);
                  });
                }
                // console.log(guestArray);

                this.setState({
                  amount: amount,
                  check_in: check_in,
                  check_out: check_out,
                  currency: currency,
                  location: location,
                  name: name,
                  // totalGuest: totalGuest,
                  totalGuest: guestArray.length,
                  guestArray: guestArray,
                  loading: false,
                  pnr: pnr,
                });
                // console.log(this.state);
              } else {
                this.setState({ snackbaropen: true, message: "Data Error" });
                return this.handleClose();
              }
            });
        }
        break;
      case "coupon":
        if (data.couponCode !== undefined) {
          this.setState({
            open: true,
            loading: true,
            couponCode: data.couponCode,
          });
          db.collection("coupons")
            .where("name", "==", data.couponCode)
            // .doc("Atd13bsNkInpciDZdYfU")
            .get()
            .then((snap) => {
              // console.log(snap.empty);
              if (!snap.empty) {
                // console.log(snap.docs[0])
                snap.forEach((doc) => {
                  // console.log(doc.data)
                  var { description, title, discount_amount } = doc.data();
                  if (typeof discount_amount === "object") {
                    discount_amount = "INR " + discount_amount.INR;
                  }
                  this.setState({
                    couponDescription: description,
                    couponTitle: title,
                    couponDiscount: discount_amount,
                    loading: false,
                  });
                });
              } else {
                this.setState({ snackbaropen: true, message: "Data Error" });
                return this.handleClose();
              }
            });
        }
        break;

      case "pending_booking":
        // console.log("pending data");
        var d = new Date();
        // console.log(d);
        // debugger;
        var expDate = data.expiration_date;
        // console.log(typeof(expDate))
        var timeD = new Date(expDate.seconds * 1000);
        var rmDate = Math.ceil((timeD - d) / (1000 * 24 * 60 * 60));

        // alert(rmDate)
        if (rmDate <= 0) {
          // alert("less");
          this.setState({
            snackbaropen: true,
            message: "Sorry! Booking Date Expired",
          });
          return;
        }
        var values = data.data;
        // localStorage.clear()
        switch (values.title) {
          case "hotspot":
            console.warn("hotspot");
            try {
              for (var key in values) {
                if (
                  typeof values[key] === "string" ||
                  typeof values[key] === "number"
                )
                  localStorage.setItem(key, values[key]);
                else localStorage.setItem(key, JSON.stringify(values[key]));
                // console.log(key, typeof values[key]);
              }
              // alert("sending to ");
              history.push("/hsummary");
              localStorage.removeItem("notifyBookdata");
            } catch (error) {
              this.setState({
                snackbaropen: true,
                message: "Oops Something Went Wrong",
              });
              return this.handleClose();
            }
            break;
          case "workpad":
            // debugger;
            console.warn("workpad");
            try {
              for (var key in values) {
                if (
                  typeof values[key] === "string" ||
                  typeof values[key] === "number"
                )
                  localStorage.setItem(key, values[key]);
                else localStorage.setItem(key, JSON.stringify(values[key]));
                //  console.log(key, typeof values[key]);

                if (key === "female_guest_details") {
                  // console.log("female details");
                  this.props.femaleGuestDetails(values[key]);
                }
                if (key === "mixed_guest_details") {
                  // console.log("male details");
                  this.props.mixedGuestDetails(values[key]);
                }
              }

              history.push("/workpadsummary");
            } catch (error) {
              this.setState({
                snackbaropen: true,
                message: "Oops Something Went Wrong",
              });
              return this.handleClose();
            }
            break;
          case "hostels":
            console.warn("hostels");
            try {
              for (var key in values) {
                if (
                  typeof values[key] === "string" ||
                  typeof values[key] === "number"
                )
                  localStorage.setItem(key, values[key]);
                else localStorage.setItem(key, JSON.stringify(values[key]));

                if (key === "female_guest_details") {
                  this.props.femaleGuestDetails(values[key]);
                }
                if (key === "mixed_guest_details") {
                  this.props.mixedGuestDetails(values[key]);
                }
                //  console.log(key, typeof values[key]);
              }
              history.push("/summary");
            } catch (error) {
              this.setState({
                snackbaropen: true,
                message: "Oops Something Went Wrong",
              });
              return this.handleClose();
            }
            break;
          default:
            // console.log("default pending booking");
            this.setState({
              snackbaropen: true,
              message: "Oops Something Went Wrong",
            });
            break;
        }
        break;
      default:
        // this.setState({
        //   snackbaropen: true,
        //   message: "Oops Something Went Wrong",
        // });
        // console.log("breaked data ");
        break;
    }
  };

  functionLIst = (data) => {
    // console.log(data);
    const v = data.foreach((mDetails) => {
      // console.log(mDetails);

      return (
        <ListItem
          key={mDetails.name}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div> {mDetails.name}&nbsp;</div>
          <div>{mDetails.gender || "F"}&nbsp;</div>
          <div>{mDetails.age}</div>
        </ListItem>
      );
    });
    return v;
  };
  createList = (notify) => {
    return (
      <ListItem
        key={notify.id}
        onClick={(e) => this.openDialog(notify.data(), notify.id)}
        style={{
          cursor: "pointer",
          backgroundColor: "white",
          marginTop: ".5rem",
          transition: "all 1s ",
        }}
      >
        <ListItemText >
          {notify.data().message}
          <br />
          {notify.data().type === "booking" ? (
            <span
              //onClick={(e) => this.toBookings(notify.data().inv,notify.id)}
              style={{ cursor: "pointer" }}
            >
              <OpeninNewIcon style={{ fontSize: "1rem" }} />
              &nbsp;Details
            </span>
          ) : null}
        </ListItemText>
      </ListItem>
    );
  };
  renderdata() {
    // console.log("mounted componenet");
    // console.log(auth.currentUser.uid);
    if (auth.currentUser !== null && this.state.mountcomponent) {
      if (snap.length > 0) {
        snap.forEach((unsubs) => {
          // console.log("unsubs");
          unsubs();
        });
        snap = [];
      }
      const unsubscribe = db
        .collection("users")
        .doc(auth.currentUser.uid)
        .collection("notifications")
        .orderBy("timestamp", "desc")
        .where("seen", "==", false)
        .onSnapshot(
          (snap) => {
            // console.log("printing snap val==>", snap);
            let count_message = 0;
            let vr = snap.docs.map((res) => {
              if (!res.data().seen && this.state.mountcomponent) {
                count_message += 1;
                return this.createList(res);
              }
            });

            // console.log(vr);

            this.setState({ notify: vr, count_notify: count_message });
          },
          (error) => {
            console.log("error==>", error);
            // this.setState({
            //   notify: "No Notifications To Display",
            //   count_notify: 0,
            // });
          }
        );

      snap.push(unsubscribe);
    }
  }
  removeallNotify = () => {
    if (auth.currentUser !== null)
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("notifications")
        .get()
        .then((snaps) => {
          snaps.forEach((docs) => {
            db.collection("users")
              .doc(auth.currentUser.uid)
              .collection("notifications")
              .doc(docs.id)
              .update({
                seen: true,
              })
              .catch((e) => {
                // console.log("removed", docs.id);
              });
          });
        })
        .catch((e) => {
          // console.log(e);
        });
  };
  handleClose = () => {
    // console.log("dailogue");
    this.setState({ open: false, popID: "", loading: false, couponCode: "" });
  };
  render() {
    // console.log(this.state);
    return (
      <Fragment>
        <Snackbar open={this.state.snackbaropen} autoHideDuration={3000}>
          <Alert style={{ width: "90vh" }} severity="error">
            {this.state.message}
          </Alert>
        </Snackbar>
        <IconButton
          onClick={(e) => this.openNotificatonBox()}
          aria-controls="customized-menu"
          aria-haspopup="true"
          ref={this.anchorRef1}
          disabled={this.state.count_notify === 0}
        >
          {" "}
          {this.state.count_notify !== 0 ? (
            <Badge badgeContent={this.state.count_notify} color="secondary">
              <NotificationsIcon />
            </Badge>
          ) : (
            <NotificationsOffIcon />
          )}
        </IconButton>
        <Popover
          anchorEl={this.anchorRef1.current}
          open={this.state.openBox}
          onClose={this.openNotificatonBox}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ width: "90vw" }}
        >
          <List>
            <ListSubheader
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "rgb(255 255 255)",
                borderBottom: "1px solid lightgrey",
              }}
            >
              <span
                style={{
                  fontWeight: "800",
                  fontSize: "1rem",
                  fontFamily: "Poppins",
                }}
              >
                Notifications
              </span>
              {this.state.count_notify > 0 ? (
                <IconButton
                  style={{ fontSize: "1rem" }}
                  onClick={this.removeallNotify}
                >
                  <ClearAllIcon />
                </IconButton>
              ) : null}
            </ListSubheader>

            {this.state.notify}
          </List>

          <Dialog
            className="Notification"
            open={this.state.open}
            onClose={this.handleClose}
            // maxWidth="lg"
            // fullWidth={true}
            disableBackdropClick
            disableEscapeKeyDown
            style={{
              borderRadius: "20px !important",
              backgroundImage: { LOGO },
              backgroundPosition: "right bottom",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ paddingLeft: "1rem", fontSize: "1rem" }}>
                <b>{this.state.popID === "" ? "Coupon" : "Booking Details"}</b>
              </div>
              <IconButton onClick={(e) => this.handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>
            {this.state.popID === "" ? null : (
              <DialogContent style={{ fontSize: "small" }}>
                {this.state.loading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton style={{ height: "20vh" }} animation="wave" />
                  </>
                ) : (
                  createDailogueBooking(
                    this.state.pnr,
                    this.state.totalGuest,
                    this.state.location,
                    this.state.check_in,
                    this.state.check_out,
                    this.state.currency,
                    this.state.amount,
                    this.state.guestArray
                  )
                )}
              </DialogContent>
            )}
            {this.state.couponCode === "" ? null : (
              <DialogContent>
                {this.state.loading ? (
                  <>
                    <Skeleton />
                    <Skeleton style={{ height: "20vh" }} animation="wave" />
                  </>
                ) : (
                  createDailogueCoupon(
                    this.state.couponTitle,
                    this.state.couponDescription,
                    this.state.couponDiscount,
                    this.state.couponCode
                  )
                )}
              </DialogContent>
            )}
          </Dialog>
        </Popover>
      </Fragment>
    );
  }
}
export const onLogoutUnsuscribe = async () => {
  try {
    // console.log("1", snap);
    snap.forEach((unsubscribe) => {
      unsubscribe();
    });
    snap = [];
    // console.log("removed above snapshot");
  } catch (e) {
    // console.log(e);
  }
};

const mapDispatchToProps = () => {
  return {
    mixedGuestDetails,
    femaleGuestDetails,
  };
};
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps())(UserNotification);
