import React from "react";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Navbar from "./static/navbar/navbarMui";
import Footer from "./static/footer";
import { Link } from "react-router-dom";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
const useStyles = (theme) => ({
  root: {
    marginTop: "10px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    display: "flex",
    padding: "10px 0",
    fontSize: "12px",
    color: "blue",
  },
  expandOpen: {
    display: "none",
  },
  avatar: {
    backgroundColor: red[500],
  },
});

class Destinations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded1: false,
      expanded2: false,
    };
  }

  handleExpandClick = () => {
    this.setState({ expanded1: !this.state.expanded1 });
  };

  handleExpandClick2 = () => {
    this.setState({ expanded2: !this.state.expanded2 });
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navbar />
        <Link to="/">
          <CloseRoundedIcon
          className="crossIcon"
            fontSize="large"
            style={{
              color: "#ffde17",
              position: "absolute",
              right: "10px",
              marginTop: "10px",
              opacity: "1",
              zIndex: "1",
            }}
          />
        </Link>
        <div style={{ margin: "10px" }}>
          <Card className={classes.root}>
            {/* <Link to="explore/darjeeling"> */}
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  D
                </Avatar>
              }
              // action={
              //   <IconButton aria-label="settings">
              //     <MoreVertIcon />
              //   </IconButton>
              // }
              title="Darjeeling"
              subheader="Queen of hills"
            />
            <CardMedia
              className={classes.media}
              image="/location/darjeeling.jpg"
              title="Paella dish"
            />
            {/* </Link> */}

            <CardContent style={{ padding: "16px 16px 8px" }}>
              <Typography
                style={{
                  fontSize: "13px",
                  fontFamily: "Poppins",
                }}
                variant="body2"
                // color="textSecondary"
                component="div"
              >
                Darjeeling conjures visions of snow peaks, serenity of vibrant
                green hills steeped in splendour, a land of breathtaking beauty
                crowned by the majestic
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.expanded1,
                  })}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded1}
                  aria-label="show more"
                >
                  Read More...
                </IconButton>
                <Collapse
                  in={this.state.expanded1}
                  timeout="auto"
                  unmountOnExit
                >
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "Poppins",
                    }}
                    variant="body2"
                    component="div"
                  >
                    Himalayas. Darjeeling is one of the most magnificent hill
                    resorts in the world.Flaming red rhododendrons, sparkling
                    white magnolias, miles of undulating hillsides covered with
                    emerald green tea bushes, the exotic forests of silver fir -
                    all under the blanket of a brilliant azure sky dappled with
                    specks of clouds, compellingly confounds Darjeeling as the
                    QUEEN OF HILL STATIONS. The crest of Kanchenjunga shining in
                    the first dawn light truly supports the title. Darjeeling
                    beckons thousands today for a leisurely respite from the
                    bustle of the madding crowd.
                  </Typography>
                </Collapse>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: !this.state.expanded1,
                  })}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded1}
                  aria-label="show more"
                >
                  Read Less
                </IconButton>
              </Typography>
            </CardContent>
          </Card>
          {/* <Card className={classes.root}>
            <Link to="/explore/gangtok">
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    G
                  </Avatar>
                }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title="Gangtok"
                subheader="Land of monasteries"
              />
              <CardMedia
                className={classes.media}
                image="/location/gangtok.jpg"
                title="Paella dish"
              />
            </Link>

            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Gangtok-The name meaning hill-top, Gangtok can be safely said to
                be one of the most beautiful hill stations in the country. With
                everything in just the right amount from a blend of cultural
                identities to urbanisation, Gangtok is a breathing and dynamic
                bit of paradise of the northeast. It has an amazing view of
                mount Kanchenjunga, the third highest mountain peak in the
                world. Also like everything around it, Gangtok is abundant in
                natural beauty and has various natural attractions such as the
                Tsomgo Lake, Ban Jhakri falls, Tashi viewpoint and more. Other
                places to visit include the Enchey Monastery, Ganesh Tok, Do
                Drul Chorten Rumtek Monastery, etc. Also, river Teesta is one of
                the best spots for white water rafting in North East India.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
   
            </CardActions>
          </Card> */}

          <Card className={classes.root}>
            {/* <Link to="/explore/siliguri"> */}
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  S
                </Avatar>
              }
              // action={
              //   <IconButton aria-label="settings">
              //     <MoreVertIcon />
              //   </IconButton>
              // }
              title="bunkbread Siliguri"
              subheader="Gateway of northeast India"
            />
            <CardMedia
              className={classes.media}
              image="/location/siliguri.jpg"
              title="Paella dish"
            />
            {/* </Link> */}

            <CardContent style={{ padding: "16px 16px 8px" }}>
              <Typography
                style={{
                  fontSize: "13px",
                  fontFamily: "Poppins",
                }}
                variant="body2"
                // color="textSecondary"
                component="div"
              >
                Also known as the gateway of North-Eastern India.The strategic
                location of the place makes sure that travellers to the
                North-East has to pass through
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.expanded2,
                  })}
                  onClick={this.handleExpandClick2}
                  aria-expanded={this.state.expanded2}
                  aria-label="show more"
                >
                  Read More...
                </IconButton>
                <Collapse
                  in={this.state.expanded2}
                  timeout="auto"
                  unmountOnExit
                >
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "Poppins",
                    }}
                    variant="body2"
                    component="div"
                  >
                    Siliguri. Summer temperature rarely exceeds 38 deg Cent and
                    winter mean minimum temperature rarely falls below 4 deg
                    Cent. The whole region is popularly famous for three(3) T's
                    - Tea,, Timber & Tourism. The nearest important Railway
                    station is at New Jalpaiguri(NJP) just 3 kms away from the
                    heart of the City. Bagdogra Airport at a distance of 12 Km
                    is the only airport nearby. The National Hiighway 31 & 55
                    connect Siliguri with other major places of North-Eastern
                    India.
                  </Typography>
                </Collapse>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: !this.state.expanded2,
                  })}
                  onClick={this.handleExpandClick2}
                  aria-expanded={this.state.expanded2}
                  aria-label="show more"
                >
                  Read Less
                </IconButton>
              </Typography>
            </CardContent>
          </Card>
        </div>
        <Footer Workpad_none />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Destinations);
