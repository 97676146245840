import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
});

class Review extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      //   <div id="femaleDorm">
      //     <div className="review_icon femaleIcon">
      //       <i
      //         className={
      //           this.props.type === "female"
      //             ? "fas fa-female fa-3x"
      //             : "fas fa-restroom fa-3x"
      //         }
      //       ></i>
      //       <p id="review_name">
      //         {this.props.type === "female" ? "Female Dorm" : "Mixed Dorm"}
      //       </p>
      //     </div>
      //     <div className="review_details">
      //       <div>
      //         {this.props.type === "female" ? "Female Dorm" : "Male Dorm"}
      //       </div>
      //       <div>No. of beds {this.props.beds_count}</div>
      //       <div>
      //         <b>Tariff : {this.props.tariff}</b>
      //       </div>
      //       <div>
      //         <b>Total : {this.props.total}</b>
      //       </div>
      //     </div>
      //   </div>
      <React.Fragment>
        {/* {this.props.type === "female" ? (
          <Card
            style={{
              background: "gainsboro",
              boxShadow: "none",
            }}
            className={classes.root}
          >
            <CardMedia
              className={classes.cover}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                fontFamily: "Poppins",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <PermIdentityIcon fontSize="large" />
              Female Dorm
            </CardMedia>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                  Female Dorm
                </Typography>
                {/* <Typography variant="subtitle1" color="textSecondary">
              It has roots in a piece of classical Latin literature from 45 BC
            </Typography> }
                <Typography style={{ fontFamily: "Poppins" }}>
                  Tarrif: {this.props.tariff}
                </Typography>
                <Typography style={{ fontFamily: "Poppins" }}>
                  No. of Beds {this.props.beds_count}
                </Typography>
              </CardContent>
            </div>
          </Card>
        ) : ( */}
          <Card
            style={{ background: "gainsboro", boxShadow: "none" }}
            className={classes.root}
          >
            <CardMedia
              className={classes.cover}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                fontFamily: "Poppins",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <SupervisorAccountIcon fontSize="large" />
              Mixed Dorm
            </CardMedia>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                  Mixed Dorm
                </Typography>
                {/* <Typography variant="subtitle1" color="textSecondary">
            It has roots in a piece of classical Latin literature from 45 BC
          </Typography> */}
                <Typography style={{ fontFamily: "Poppins" }}>
                  Tarrif: {this.props.tariff}
                </Typography>
                <Typography style={{ fontFamily: "Poppins" }}>
                  No. of Beds {this.props.beds_count}
                </Typography>
              </CardContent>
            </div>
          </Card>
      
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Review);
