import { Dialog, Icon } from "@material-ui/core";
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";
import {render} from "react-dom"
import "./assets/css/update.css";
var open = false;
var dataBook = null
const handleclose = (dataBook) => {
  open = false;
  window.location.reload();

  if (dataBook !== null) localStorage.setItem("notifyBookdata", dataBook);
};
// import {Dailo}
export var v = (
  <Dialog open={true}>
    <div className="container">
      <div className="cookiesContent" id="cookiesPopup">
        {/* <div style={{ fontSize: "3rem",display:"flex",justifyContent:"center",alignItems:"center",color:"blueviolet"}}> */}

        <SystemUpdateAltRoundedIcon
          className="iconClass"
          style={{ fontSize: "large !important" }}
        />

        <p>
          A new version of <b>bunkbread</b> is available. Please update to
          continue using the App.
        </p>
        <button
          className="accept"
          onClick={(e) => {
            handleclose(dataBook);
          }}
        >
          Update
        </button>
      </div>
    </div>
  </Dialog>
);
export const popUpdate = () => {
   const b = document.createElement("div");
   b.id = "popupUpdate";
   document.body.appendChild(b);
   render(v, document.getElementById("popupUpdate"));
};
export default {
  onUpdate: (registration) => {
    // console.log("skipping")
     dataBook = localStorage.getItem("notifyBookdata");
    localStorage.clear();
    registration.waiting.postMessage({ type: "SKIP_WAITING" });
    // window.location.reload()
    open = true;
    popUpdate();
  },
  onSuccess: (registration) => {
    console.log("success registarion service worker");
  },
};
