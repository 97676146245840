let states = {};

const availReducer = (state = states, action) => {
    if (action.type === 'date') {
        let unordered = {...state}
        for (const [key, value] of Object.entries(action.payload)) {
            unordered = {
                ...unordered,
                [key]: {
                    mixed: value.mixed,
                   // female: value.female
                }
            }
        }
        const states = Object.keys(unordered).sort().reduce(
            (obj, key) => {
                obj[key] = unordered[key];
                return obj;
            },
            {}
        );
        return {
            ...states,
        }
    }
    else {
        return state;
    }
}

export default availReducer;