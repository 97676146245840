// import { Page } from "@react-pdf/renderer";
import { Component } from "react";
import { Link } from "react-router-dom";
// import {SentimentDissatisfied} from "@material-ui/icons"
import "../../assets/css/404.css";
class Page404 extends Component {
  render() {
    return (
      <>
        <main>
          <div className="container404">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3rem",
              }}
            >
              <div>
                <h1 className="h1404">404</h1>
                <h2 className="h2404">UH OH! You're lost.</h2>
                <div className="emoji sad">
                  <figure className="face">
                    <span className="eyes">
                      <span className="eye"></span>
                      <span className="eye"></span>
                    </span>
                    <span className="mouth tounge"></span>
                  </figure>
                </div>
                <p>
                   Something went wrong and you end up here.<br />
                </p>
                <a href="/">
                  <button className="btn404 green">HOME</button>
                </a>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Page404;
