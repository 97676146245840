import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AddIcon from "@material-ui/icons/Add";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RemoveIcon from "@material-ui/icons/Remove";
import { withRouter } from "react-router-dom";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import {
  date,
  update_available,
  mixedGuestDetails,
  //femaleGuestDetails,
  mixedTempDetails,
  //femaleTempDetails,
  fetchuserdata,
  userProfile,
} from "../../actions";
import { connect } from "react-redux";
import { auth, db } from "../../firebase";
// import { stubTrue } from "lodash";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  DialogActions,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import { Snackbar, IconButton } from "@material-ui/core";
import { Alert, Rating } from "@material-ui/lab";
// import firebase from "firebase";
var reviewSnap = {
  location: "",
  location_id: "",
  receipt: "",
  name: "",
  user_id: "",
  check_in: "",
  check_out: "",
};
export const Component = withRouter(({ history, location }) => {});
let authOnComponent;
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
});

let userdata;
class Dorms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fcount: 0,
      mcount: 0,
      mixed_guest_details: [],
    //  female_guest_details: [],
      modal: false,
      onprocess: false,
      currency: "INR",
      workpad: false,
      opensnackbar: false,
      severity: "error",
      error_message: "",
      reviewDisplay: false,
    };
    this.handleProceed = this.handleProceed.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handelsnackclose = () => {
    //event.preventDefault();
    // console.log("snackclose");
    this.props.location.state = undefined;
    this.setState({
      opensnackbar: false,
      error_message: "",
    });
  };
  componentWillUnmount() {
    authOnComponent();
  }
  componentDidMount() {
    // alert("WOW")
    // console.log("This.props:", this.props);
    this.props.workpad && this.setState({ workpad: true });
    // if(this.props.location.state!==undefined){
    //   if(this.props.location.state.hasOwnProperty("proccedBook") && this.props.location.state.proccedBook){
    //     this.setState({modal:true})
    //   }
    // }
    const { availableNow } = this.props;
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.hasOwnProperty("prev")
    ) {
      if (this.props.location.state.prev === "login") {
        // console.log(this.state.location);
        this.setState({
          opensnackbar: true,
          severity: "success",
          error_message: "Log-in Successful",
        });
      } else if (this.props.location.state.prev === "setupprofile") {
        // console.log(this.state.location);
        this.setState({
          opensnackbar: true,
          severity: "success",
          error_message: "Profile Updated Successfully",
        });
      }
    }
    authOnComponent = auth.onAuthStateChanged((user) => {
      this.fetchudata("dormcompenent");
      // this.setState({ onprocess: false });
    });

    this.props.availableNow.mixed_dorm_available > 0 &&
      this.setState({ mcount: 1 });

    localStorage.getItem("currency") &&
      this.setState({ currency: localStorage.getItem("currency") });
  }

  componentDidUpdate() {
    // console.log("UPDATE");
    localStorage.getItem("currency") &&
      this.state.currency !== localStorage.getItem("currency") &&
      this.setState({ currency: localStorage.getItem("currency") });
  }

  totalAmount(type) {
    const date1 = new Date(localStorage.getItem("check_in"));
    const date2 = new Date(localStorage.getItem("check_out"));
    const diffTime = Math.abs(date2 - date1);
    // console.log(diffTime);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (this.state.currency === "INR") {
      if (type === "mixed")
        return (
          this.state.mcount *
          diffDays *
          parseInt(localStorage.getItem("mixed_dorm_price"))
        );
      // else if (type === "female")
      //   return (
      //     this.state.fcount *
      //     diffDays *
      //     parseInt(localStorage.getItem("female_dorm_price"))
      //   );
    } else if (this.state.currency === "USD") {
      if (type === "mixed")
        return (
          this.state.mcount *
          diffDays *
          parseInt(localStorage.getItem("mixed_dorm_price_D"))
        );
      // else if (type === "female")
      //   return (
      //     this.state.fcount *
      //     diffDays *
      //     parseInt(localStorage.getItem("female_dorm_price_D"))
      //   );
    } else if (this.state.currency === "EUR") {
      if (type === "mixed")
        return (
          this.state.mcount *
          diffDays *
          parseInt(localStorage.getItem("mixed_dorm_price_E"))
        );
      // else if (type === "female")
      //   return (
      //     this.state.fcount *
      //     diffDays *
      //     parseInt(localStorage.getItem("female_dorm_price_E"))
      //   );
    }
  }

  // componentDidUpdate() {
  //   this.props.availableNow &&
  //     this.state.mcount === 0 &&
  //     this.props.availableNow.mixed_dorm_available > 0 &&
  //     this.setState({ mcount: 1 });
  // }

  fetchudata = async (where) => {
    // this.setState({ onprocess: true });
    // console.log("fetching", this.state);
    let tid;
    if (auth.currentUser !== null) {
      await auth.currentUser.getIdToken().then((id) => {
        tid = id;
        // console.log(id);
      });
    } else if (where === "buttonprocced") {
      this.props.history.push("/login");
    }
    const res = await this.props.userProfile(tid);
    // console.log(res);
    let profile = {};
    if (res.hasOwnProperty("error")) {
      // alert("Error Network / USER");
      return null;
    } else return res;
  };

  // femaleDorm(classes) {
  //   return (
  //     <Card style={{ margin: "10px 8px" }} className={classes.root}>
  //       <CardMedia
  //         className={`dormImages ${classes.cover}`}
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "relative",
  //           fontFamily: "Poppins",
  //           fontWeight: "600",
  //           textAlign: "center",
  //           width: "25%",
  //           fontSize: "18px",
  //           zIndex: "1",
  //         }}
  //       >
  //         <PermIdentityIcon fontSize="large" />
  //         Female Dorm
  //       </CardMedia>
  //       <div style={{ width: "50%" }} className={classes.details}>
  //         <CardContent style={{ padding: "8px" }} className={classes.content}>
  //           <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
  //             8 Bed Female Dorm
  //           </Typography>
  //           <Typography
  //             style={{
  //               fontFamily: "Poppins",
  //               color: "black",
  //               fontSize: "12px",
  //             }}
  //           >
  //             Tarrif exclusive of taxes
  //           </Typography>
  //           <p style={{ marginBottom: "0" }}>
  //             {this.props.availableNow.female_dorm_available} Left
  //           </p>
  //           {this.props.availableNow.female_dorm_available > 0 ? (
  //             <Divider
  //               style={{ background: "green", height: "2px", margin: "4px" }}
  //             ></Divider>
  //           ) : (
  //             <Divider
  //               style={{ background: "red", height: "2px", margin: "4px" }}
  //             ></Divider>
  //           )}
  //           {/* <Typography style={{ fontFamily: "Poppins" }}>
  //             Tarrif: {localStorage.getItem("female_dorm_price")} INR +{" "}
  //             {localStorage.getItem("tax")} GST
  //           </Typography> */}
  //         </CardContent>
  //       </div>
  //       <CardMedia
  //         style={{ width: "25%", textAlign: "center" }}
  //         className={classes.cover}
  //       >
  //         {!this.state.workpad ? (
  //           this.state.currency === "INR" ? (
  //             <Typography
  //               style={{
  //                 fontSize: "21px",
  //                 fontFamily: "Poppins",
  //                 fontWeight: "600",
  //               }}
  //             >
  //               {localStorage.getItem("female_dorm_price")} INR
  //             </Typography>
  //           ) : this.state.currency === "EUR" ? (
  //             <Typography
  //               style={{
  //                 fontSize: "21px",
  //                 fontFamily: "Poppins",
  //                 fontWeight: "600",
  //               }}
  //             >
  //               {localStorage.getItem("female_dorm_price_E")} EUR
  //             </Typography>
  //           ) : (
  //             <Typography
  //               style={{
  //                 fontSize: "21px",
  //                 fontFamily: "Poppins",
  //                 fontWeight: "600",
  //               }}
  //             >
  //               {localStorage.getItem("female_dorm_price_D")} USD
  //             </Typography>
  //           )
  //         ) : this.state.currency === "INR" ? (
  //           <Typography
  //             style={{
  //               fontSize: "21px",
  //               fontFamily: "Poppins",
  //               fontWeight: "600",
  //             }}
  //           >
  //             {localStorage.getItem("W_female_dorm_price")} INR
  //           </Typography>
  //         ) : this.state.currency === "EUR" ? (
  //           <Typography
  //             style={{
  //               fontSize: "21px",
  //               fontFamily: "Poppins",
  //               fontWeight: "600",
  //             }}
  //           >
  //             {localStorage.getItem("W_female_dorm_price_E")} EUR
  //           </Typography>
  //         ) : (
  //           <Typography
  //             style={{
  //               fontSize: "21px",
  //               fontFamily: "Poppins",
  //               fontWeight: "600",
  //             }}
  //           >
  //             {localStorage.getItem("W_female_dorm_price_D")} USD
  //           </Typography>
  //         )}

  //         <Typography
  //           variant="subtitle1"
  //           color="textSecondary"
  //           style={{
  //             fontFamily: "Poppins",
  //             fontSize: "11px",
  //             fontWeight: "600",
  //           }}
  //         >
  //           Per bed per Night
  //         </Typography>

  //         {this.props.availableNow.female_dorm_available > 0 && (
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "space-around",
  //               alignItems: "center",
  //               padding: "10px 0px",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 border: "1px solid black",
  //                 background: "#ffde17",
  //                 height: "24px",
  //               }}
  //               onClick={() =>
  //                 this.setState({
  //                   fcount:
  //                     this.state.fcount - 1 > 0 ? this.state.fcount - 1 : 0,
  //                 })
  //               }
  //             >
  //               <RemoveIcon
  //                 color={`${this.state.fcount <= 0 ? "disabled" : "action"}`}
  //               />
  //             </div>
  //             <div className="page-item">
  //               {this.props.availableNow.female_dorm_available > 0
  //                 ? this.state.fcount
  //                 : 0}
  //             </div>

  //             <div
  //               style={{
  //                 border: "1px solid black",
  //                 background: "#ffde17",
  //                 height: "24px",
  //               }}
  //               onClick={() =>
  //                 this.setState({
  //                   fcount:
  //                     this.state.fcount + 1 <
  //                     this.props.availableNow.female_dorm_available + 1
  //                       ? this.state.fcount + 1
  //                       : this.state.fcount,
  //                 })
  //               }
  //             >
  //               <AddIcon
  //                 color={`${
  //                   this.props.availableNow.female_dorm_available <=
  //                   this.state.fcount
  //                     ? "disabled"
  //                     : "action"
  //                 }`}
  //               />
  //             </div>
  //           </div>
  //         )}

  //         {/* <p
  //           style={{ fontFamily: "Poppins", margin: "15px 5px" }}
  //           className="dorms-Avl"
  //         >
  //           {this.props.availableNow.female_dorm_available > 0
  //             ? "Available"
  //             : "Unavailable"}
  //         </p> */}
  //         <div></div>
  //       </CardMedia>
  //     </Card>
  //   );
  // }

  mixedDorm(classes) {
    return (
      // <div id="mixedDorm">
      <Card style={{ margin: "10px 8px" }} className={classes.root}>
        <CardMedia
          className={`dormImages ${classes.cover}`}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            background: "gainsboro",
            fontFamily: "Poppins",
            fontWeight: "600",
            textAlign: "center",
            width: "25%",
            fontSize: "18px",
            zIndex: "1",
          }}
        >
          <SupervisorAccountIcon fontSize="large" />
          Mixed Dorm
        </CardMedia>
        <div style={{ width: "50%" }} className={classes.details}>
          <CardContent style={{ padding: "8px" }} className={classes.content}>
            <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
            Mixed Dorm
            </Typography>
            <Typography
              style={{
                fontFamily: "Poppins",
                color: "black",
                fontSize: "12px",
              }}
            >
              Tarrif exclusive of taxes
            </Typography>
            <p style={{ marginBottom: "0" }}>
              {this.props.availableNow.mixed_dorm_available} Left
            </p>
            {this.props.availableNow.mixed_dorm_available > 0 ? (
              <Divider
                style={{ background: "green", height: "2px", margin: "4px" }}
              ></Divider>
            ) : (
              <Divider
                style={{ background: "red", height: "2px", margin: "4px" }}
              ></Divider>
            )}
            {/* <Typography style={{ fontFamily: "Poppins" }}>
              Tarrif: {localStorage.getItem("mixed_dorm_price")} INR +{" "}
              {localStorage.getItem("tax")} GST
            </Typography> */}
          </CardContent>
        </div>
        <CardMedia
          style={{ width: "25%", textAlign: "center" }}
          className={classes.cover}
        >
          {!this.state.workpad ? (
            this.state.currency === "INR" ? (
              <Typography
                style={{
                  fontSize: "21px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                {localStorage.getItem("mixed_dorm_price")} INR
              </Typography>
            ) : this.state.currency === "EUR" ? (
              <Typography
                style={{
                  fontSize: "21px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                {localStorage.getItem("mixed_dorm_price_E")} EUR
              </Typography>
            ) : (
              <Typography
                style={{
                  fontSize: "21px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                {localStorage.getItem("mixed_dorm_price_D")} USD
              </Typography>
            )
          ) : this.state.currency === "INR" ? (
            <Typography
              style={{
                fontSize: "21px",
                fontFamily: "Poppins",
                fontWeight: "600",
              }}
            >
              {localStorage.getItem("W_mixed_dorm_price")} INR
            </Typography>
          ) : this.state.currency === "EUR" ? (
            <Typography
              style={{
                fontSize: "21px",
                fontFamily: "Poppins",
                fontWeight: "600",
              }}
            >
              {localStorage.getItem("W_mixed_dorm_price_E")} EUR
            </Typography>
          ) : (
            <Typography
              style={{
                fontSize: "21px",
                fontFamily: "Poppins",
                fontWeight: "600",
              }}
            >
              {localStorage.getItem("W_mixed_dorm_price_D")} USD
            </Typography>
          )}

          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{
              fontFamily: "Poppins",
              fontSize: "11px",
              fontWeight: "600",
            }}
          >
            Per bed per Night
          </Typography>

          {this.props.availableNow.mixed_dorm_available > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              <div
                style={{
                  border: "1px solid black",
                  background: "#ffde17",
                  height: "24px",
                }}
                onClick={() =>
                  this.setState({
                    mcount:
                      this.state.mcount - 1 > 0 ? this.state.mcount - 1 : 0,
                  })
                }
              >
                <RemoveIcon
                  color={`${this.state.mcount <= 0 ? "disabled" : "action"}`}
                />
              </div>
              <div className="page-item">
                {this.props.availableNow.mixed_dorm_available > 0
                  ? this.state.mcount
                  : 0}
              </div>

              <div
                style={{
                  border: "1px solid black",
                  background: "#ffde17",
                  height: "24px",
                }}
                onClick={() =>
                  this.setState({
                    mcount:
                      this.state.mcount + 1 <
                      this.props.availableNow.mixed_dorm_available + 1
                        ? this.state.mcount + 1
                        : this.state.mcount,
                  })
                }
              >
                <AddIcon
                  color={`${
                    this.props.availableNow.mixed_dorm_available <=
                    this.state.mcount
                      ? "disabled"
                      : "action"
                  }`}
                />
              </div>
            </div>
          )}

          {/* <p
            style={{ fontFamily: "Poppins", margin: "15px 5px" }}
            className="dorms-Avl"
          >
            {this.props.availableNow.mixed_dorm_available > 0
              ? "Available"
              : "Unavailable"}
          </p> */}
          <div></div>
        </CardMedia>
      </Card>
      // </div>
    );
  }
  checkForPendingReview = async (id) => {
    var dis = false;
    await db
      .collection("past_bookings")
      .where("user_id", "==", id)
      .where("review", "==", false)
      .limit(1)
      .get()
      .then((snap) => {
        // console.log(snap);
        if (!snap.empty) {
          reviewSnap = snap.docs[0].data();
          dis = true;
        }
      });
    // console.log(dis);
    return dis;
  };
  async handleProceed(event) {
    event.preventDefault();
    //await this.props.userProfile(auth.currentUser.uid);
    // console.log(this.props.user.hasOwnProperty("profile"));
    this.setState({ onprocess: true });
    if (auth.currentUser === null) {
      let pathname = window.location.pathname.slice(1);
      // console.log(pathname);
      this.props.history.push({
        pathname: "/login",
        state: {
          next: pathname,
          prev: pathname,
        },
      });
      return;
    }
    let res = null;
    if (!this.props.user.hasOwnProperty("profile")) {
      // console.log("pushong");
      res = await this.fetchudata("buttonprocced");
      if (res === null) {
        // alert(res.error);
        return;
      }
    } else {
      res = this.props.user.profile;
    }

    if (res.primary_data === "email" && res.phone === null) {
      let pathname = window.location.pathname.slice(1);
      this.props.history.push({
        pathname: "/setupprofile/" + auth.currentUser.uid,
        state: {
          primary: "email",
          next: pathname,
          prev: pathname,
        },
      });
      return this.setState({ onprocess: false });
    } else if (res.primary_data === "phone" && res.email === null) {
      let pathname = window.location.pathname.slice(1);
      this.props.history.push({
        pathname: "/setupprofile/" + auth.currentUser.uid,
        state: {
          primary: "phone",
          next: pathname,
          prev: pathname,
        },
      });
      return this.setState({ onprocess: false });
    }
    var responseReview = await this.checkForPendingReview(auth.currentUser.uid);
    if (responseReview) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return this.setState({ reviewDisplay: true, onprocess: false });
    }

    this.setState({ modal: true,onprocess:false });
    if (this.state.mcount === 0 && this.state.fcount === 0) {
      // alert("Guest in one of the dorm must be atleast 1");
      return;
    }
    if (this.state.mixed_guest_details.length < this.state.mcount) {
      let temp = [];
      for (
        let i = 0;
        i < this.state.mcount - this.state.mixed_guest_details.length;
        i++
      ) {
        temp.push({
          name: "",
          age: "",
          gender: "M",
        });
      }
      this.setState({
        mixed_guest_details: [...this.state.mixed_guest_details, ...temp],
      });
    } else if (this.state.mixed_guest_details.length > this.state.mcount) {
      let temp = this.state.mixed_guest_details;
      temp = temp.slice(0, this.state.mcount);
      this.setState({ mixed_guest_details: temp });
    }

    // if (this.state.female_guest_details.length < this.state.fcount) {
    //   let temp = [];
    //   for (
    //     let i = 0;
    //     i < this.state.fcount - this.state.female_guest_details.length;
    //     i++
    //   ) {
    //     temp.push({
    //       name: "",
    //       age: "",
    //     });
    //   }
    //   this.setState({
    //     female_guest_details: [...this.state.female_guest_details, ...temp],
    //   });
    // } else if (this.state.female_guest_details.length > this.state.fcount) {
    //   let temp = this.state.female_guest_details;
    //   temp = temp.slice(0, this.state.fcount);
    //   this.setState({ female_guest_details: temp });
    // }
  }

  handleChange(event) {
    // console.log(event);
    let arr = event.target.name.split("_");
    if (arr[0] === "mixed") {
      let temp = this.state.mixed_guest_details;
      // console.log(temp, arr);
      if (arr[1] === "name" || arr[1] === "age") {
        temp[parseInt(arr[2])][arr[1]] = event.target.value;
        this.setState({ mixed_guest_details: temp });
      } else {
        let arr2 = event.target.id.split("_");
        arr2[1] === "male"
          ? (temp[parseInt(arr2[2])].gender = "M")
          : (temp[parseInt(arr2[2])].gender = "F");
        this.setState({ mixed_guest_details: temp });
      }
     }// else if (arr[0] === "female") {
    //   let temp = this.state.female_guest_details;
    //   temp[parseInt(arr[2])][arr[1]] = event.target.value;
    //   this.setState({ female_guest_details: temp });
    // }
  }

  handleSubmit(event) {
    event.preventDefault();
    // console.log("handelsubmit");
    localStorage.removeItem("mixed_guest_details");
    //localStorage.removeItem("female_guest_details");
    this.props.mixedGuestDetails(this.state.mixed_guest_details);
   // this.props.femaleGuestDetails(this.state.female_guest_details);
    localStorage.setItem(
      "mixed_guest_details",
      JSON.stringify(this.state.mixed_guest_details)
    );
    // localStorage.setItem(
    //   "female_guest_details",
    //   JSON.stringify(this.state.female_guest_details)
    // );
    // document.querySelector('.btn-close').click()
    // alert("pushed summary");
     var bookingData = {};
     try {
       var d1 = new Date();
       var d2 = new Date(localStorage.getItem("hotspot_check_in"));
       if (Math.ceil(Math.abs(d2 - d1) / (1000 * 60 * 60 * 24)) <= 1) {
         throw new Error("date near");
       }
       bookingData = {
         mixed_guest_details: this.state.mixed_guest_details,
        // female_guest_details: this.state.female_guest_details,
         [`${this.props.workpad ? "W_" : ""}mixed_dorm_price`]: JSON.parse(
           localStorage.getItem(
             `${this.props.workpad ? "W_" : ""}` + "mixed_dorm_price"
           )
         ),
         [`${this.props.workpad ? "W_" : ""}mixed_dorm_price_D`]: JSON.parse(
           localStorage.getItem(
             `${this.props.workpad ? "W_" : ""}` + "mixed_dorm_price_D"
           )
         ),
         [`${this.props.workpad ? "W_" : ""}mixed_dorm_price_E`]: JSON.parse(
           localStorage.getItem(
             `${this.props.workpad ? "W_" : ""}` + "mixed_dorm_price_E"
           )
         ),
        //  [`${this.props.workpad ? "W_" : ""}female_dorm_price`]: JSON.parse(
        //    localStorage.getItem(
        //      `${this.props.workpad ? "W_" : ""}` + "female_dorm_price"
        //    )
        //  ),
        //  [`${this.props.workpad ? "W_" : ""}female_dorm_price_D`]: JSON.parse(
        //    localStorage.getItem(
        //      `${this.props.workpad ? "W_" : ""}` + "female_dorm_price_D"
        //    )
        //  ),
        //  [`${this.props.workpad ? "W_" : ""}female_dorm_price_E`]: JSON.parse(
        //    localStorage.getItem(
        //      `${this.props.workpad ? "W_" : ""}` + "female_dorm_price_E"
        //    )
         //),
         //  hotstop_type: JSON.parse(localStorage.getItem("bed_type")),
         title: `${this.props.workpad ? "workpad" : "hostels"}`,
         [`check_in${this.props.workpad ? "_W" : ""}`]: localStorage.getItem(
           "check_in" + `${this.props.workpad ? "_W" : ""}`
         ),
         [`check_out${this.props.workpad ? "_W" : ""}`]: localStorage.getItem(
           "check_out" + `${this.props.workpad ? "_W" : ""}`
         ),
         [`${this.props.workpad ? "W_" : ""}location_id`]: localStorage.getItem(
           `${this.props.workpad ? "W_" : ""}` + "location_id"
         ),
         [`${this.props.workpad ? "W_" : ""}destination`]: localStorage.getItem(
           `${this.props.workpad ? "W_" : ""}` + "destination"
         ),
         tax: JSON.parse(localStorage.getItem("tax")),
         currency: this.state.currency,
       };
     } catch (error) {
       console.log(error);
       bookingData = {};
    }
    // console.log(bookingData)
     if (Object.keys(bookingData).length > 0)
      localStorage.setItem("notifyBookdata", JSON.stringify(bookingData));
    
    this.props.workpad
      ? this.props.history.push("/workpadsummary")
      : this.props.history.push("/summary");
  }
  checkforprocced = () => {
    if (auth.currentUser !== null) {
      // console.log("===>");
      // auth.currentUser.reload();
      // console.log("<===");
    }
  };
  submitReview = (location_id, docID, name, uid) => {
    if (this.state.stars === 0) {
      alert("Rating Must be provided!");
    } else {
      this.setState({ onprocess: true });
      // var collection = "hotels"
      // if (booking_type === "hotspot") {
      //   collection="hotspots"
      // }
      // console.log(docID);
      db.collection("reviews")
        .doc(location_id)
        .collection("comments")
        .doc(docID)
        .set({
          name: name,
          user_id: uid,
          review_text: this.state.message,
          stars: this.state.stars,
        })
        .then(() => {
          db.collection("past_bookings")
            .doc(docID)
            .update({
              review: true,
            })
            .then(() => {
              this.setState({
                opensnackbar: true,
                severity: "success",
                error_message: "Review Submitted Successfully",
                reviewDisplay: false,
                onprocess: false,
              });
            })
            .catch((e) => {
              // console.log(e);
              this.setState({
                opensnackbar: true,
                severity: "error",
                error_message: "Network Error! Unable To Submit Review",
                onprocess: false,
              });
            });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({
            opensnackbar: true,
            severity: "error",
            error_message: "Network Error! Unable To Submit Review",
            onprocess: false,
          });
        });
      // alert("Hurray")
    }
  };
  render() {
    const { classes } = this.props;
    if (this.props.availableNow.mixed_dorm_available === null) {
      return (
        <React.Fragment>
          <Snackbar
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={this.state.opensnackbar}
            onClose={this.handelsnackclose}
            style={{
              //display: "none",
              marginBottom: "4rem",
            }}
          >
            <Alert
              variant="filled"
              elevation={6}
              style={{ width: "90vw" }}
              severity={this.state.severity}
              action={
                <IconButton
                  aria-label="close"
                  //color="inherit"
                  onClick={this.handelsnackclose}
                  size="small"
                  style={{ color: "inherit" }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {this.state.error_message}
            </Alert>
          </Snackbar>
          <Skeleton
            variant="rect"
            style={{ margin: "auto", marginTop: "10px", width: "90%" }}
            height={118}
          />
          <Skeleton
            variant="rect"
            style={{ margin: "auto", marginTop: "10px", width: "90%" }}
            height={118}
          />
          <Skeleton
            style={{ margin: "auto", marginTop: "10px", width: "90%" }}
            variant="text"
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Dialog open={this.state.reviewDisplay} fullWidth={true} maxWidth="lg">
          <DialogTitle>
            <span style={{ fontWeight: "bold" }}>Review</span>
          </DialogTitle>
          <DialogContent>
            {reviewSnap.location}({reviewSnap.location_id})
            <br />
            {reviewSnap.check_in} - {reviewSnap.check_out}
            <br />
            <br />
            <div>
              <Rating
                name="half-rating"
                defaultValue={0}
                precision={0.5}
                // style={{ margin: "0rem 1rem" }}
                onChange={(e) => this.setState({ stars: e.target.value })}
              />
              <br />
              <br />
              <TextField
                multiline
                variant="outlined"
                style={{ width: "100%", height: "5rem" }}
                size="medium"
                rows={4}
                placeholder="Review..."
                onChange={(e) => this.setState({ message: e.target.value })}
              ></TextField>
              <br />
            </div>
            <DialogActions style={{ marginTop: "2rem" }}>
              <Button
                variant="contained"
                style={{
                  background: "#cbcbcb",
                }}
                onClick={(e) => this.setState({ reviewDisplay: false })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  background: "aliceblue",
                }}
                disabled={this.state.onprocess}
                onClick={(e) =>
                  this.submitReview(
                    reviewSnap.location_id,
                    // result.docs[0].data().booking_type,
                    reviewSnap.receipt,
                    reviewSnap.name,
                    reviewSnap.user_id
                  )
                }
              >
                Submit
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {this.checkforprocced()}
        {/* <div className="dorms-card"> */}
        {/* <div className="card-body"> */}
        {this.mixedDorm(classes)}
        {/*this.femaleDorm(classes)*/}
        <div className="selection">
          <div>Mixed Dorm : {this.state.mcount} selected</div>
          {/* <div>Female Dorm : {this.state.fcount} selected</div> */}
          <div>
            Total : {this.totalAmount("mixed")} {/*+ this.totalAmount("female")*/}{" "}
            {this.state.currency} {/*+ localStorage.getItem("tax")% GST*/}
          </div>
        </div>
        {this.state.mcount === 0 && this.state.fcount === 0 ? (
          <div style={{ textAlign: "center", margin: "15px 8px 30px" }}>
            <Button
              type="button"
              style={{
                width: "100%",
                fontFamily: "Poppins",
                background: "#ffde17",
              }}
              // onClick={this.handleProceed}
              variant="contained"
              disabled
            >
              Proceed
            </Button>
          </div>
        ) : (
          <div style={{ textAlign: "center", margin: "15px 8px 30px" }}>
            <Button
              type="button"
              style={{
                width: "100%",
                fontFamily: "Poppins",
                background: "#ffde17",
              }}
              onClick={this.handleProceed}
              variant="contained"
              disabled={this.state.onprocess}
            >
              Proceed
            </Button>
          </div>
        )}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}

        <Dialog
          className="GuestDetails"
          open={this.state.modal}
          // onClose={(e) => this.setState({ modal: false })}
          // aria-labelledby="modal-title"
          // aria-describedby="modal-details"
        >
          <CloseIcon
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => this.setState({ modal: false })}
          />
          <DialogTitle id="modal-title">Guest Details</DialogTitle>

          <DialogContent>
            {/* <DialogContentText id="modal-details">
              Enter Guest Details
            </DialogContentText> */}
            <div className="guest_form">
              <form onSubmit={(e) => this.handleSubmit(e)}>
                {this.state.mcount > 0 && (
                  <div
                    style={{
                      fontSize: "18px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Mixed Dorm
                  </div>
                )}

                {/* fetching details from counts */}
                {this.state.mixed_guest_details.map((details, key) => (
                  <div key={key}>
                    <div
                      className="guest_details mixed_details"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "space-around",
                        justifyContent: "space-between",
                        overflow: "visible",
                        // flexWrap: "wrap",
                      }}
                    >
                      <TextField
                        style={{ margin: "10px 5px 0 0", width: "63%" }}
                        name={`mixed_name_${key}`}
                        value={this.state.mixed_guest_details[key].name}
                        onChange={this.handleChange}
                        label="Name"
                        type="text"
                        required
                        variant="outlined"
                        size="small"
                      />
                      <TextField
                        style={{ width: "25%", margin: "10px 10px 0 0" }}
                        name={`mixed_age_${key}`}
                        value={this.state.mixed_guest_details[key].age}
                        onChange={this.handleChange}
                        label="Age"
                        type="number"
                        required
                        variant="outlined"
                        size="small"
                      />
                      <FormControl
                        className={`currency ${classes.formControl}`}
                        style={{ margin: "10px 10px 0 0", width: "12%" }}
                      >
                        {/* <InputLabel id="gender_label">Gender</InputLabel> */}
                        <Select
                          labelId="gender_label"
                          id={`mixed_${key}`}
                          value={details.gender === "M" ? "M" : "F"}
                          onChange={(event) => {
                            let temp = this.state.mixed_guest_details;
                            temp[key].gender = event.target.value;
                            this.setState({ mixed_guest_details: temp });
                          }}
                        >
                          <MenuItem value="M">M</MenuItem>
                          <MenuItem value="F">F</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                ))}

                {/* {this.state.fcount > 0 && (
                  <div
                    style={{
                      fontSize: "18px",
                      fontFamily: "Poppins",
                      margin: "20px 0 0",
                    }}
                  >
                    Female Dorm
                  </div>
                )}
                //fetching from fcount
                {this.state.female_guest_details.map((details, key) => (
                  <div key={key} className="guest_details female_details">
                    <TextField
                      style={{
                        width: "63%",
                        marginRight: "5px",
                        marginTop: "10px",
                      }}
                      name={`female_name_${key}`}
                      value={this.state.female_guest_details[key].name}
                      onChange={this.handleChange}
                      label="Name"
                      type="text"
                      required
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      style={{ width: "25%", margin: "10px 10px 0 0" }}
                      name={`female_age_${key}`}
                      value={this.state.female_guest_details[key].age}
                      onChange={this.handleChange}
                      label="Age"
                      type="number"
                      required
                      variant="outlined"
                      size="small"
                    />
                    <FormControl
                      className={`currency ${classes.formControl}`}
                      style={{ margin: "10px 10px 0 0", width: "12%" }}
                    >
                      <InputLabel id="gender_label">Gender</InputLabel>
                      <Select
                        labelId="gender_label"
                        id={`mixed_${key}`}
                        value="F"
                        inputProps={{ readOnly: true }}
                      >
                        <MenuItem value="F">F</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ))} */}
                <br />
                <div className="guest_submit">
                  <Button
                    type="submit"
                    // value="Done"
                    // onClick={(e) => this.handleSubmit(e)}
                    style={{
                      width: "100%",
                      fontFamily: "Poppins",
                      background: "#ffde17",
                    }}
                    variant="contained"
                  >
                    Done
                  </Button>
                </div>
              </form>
            </div>
          </DialogContent>
        </Dialog>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    avail: state.availReducer,
    availableNow: state.availableNow,
    mixed_details: state.mixedGuestDetails,
    //female_details: state.femaleGuestDetails,
    mixed_temp_details: state.mixedTempDetails,
    // female_temp_details: state.femaleTempDetails,
    user: state.userProfile,
    currency: state.currency,
  };
};

const mapDispatchToProps = () => {
  return {
    date,
    update_available,
    mixedGuestDetails,
    //femaleGuestDetails,
    mixedTempDetails,
    //femaleTempDetails,
    fetchuserdata,
    userProfile,
  };
};

const styles = withStyles(useStyles)(Dorms);

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withRouter(styles));
