import React from "react";
import { connect } from "react-redux";
import { userProfile, userBookings, fetchuserdata } from "../actions";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import { Link } from "react-router-dom";
// import CheckoutSteps from "./static/checkoutSteps";
// import { Close as CloseIcon } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import Avatar from "@material-ui/core/Avatar";
// import firebase from "firebase";
import history from "../helpers/history";
// import axios from "axios";
import { LinearProgress, Snackbar, IconButton } from "@material-ui/core";
import NavbarMui from "./static/navbar/navbarMui";
// import Navbar from "./static/navbar/navbarMui";
import { auth } from "../firebase";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
// const moment = require("moment-timezone");
import TOU from "./static/termsofuse";
import PP from "./static/privacypolicy";
import CR from "./static/cancellationpolicy";
import Footer from "./static/footer";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Link } from "react-router-dom";
let oNauth;
const useStyles = (theme) => ({
  root: {
    // maxWidth: 345,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    height: 140,
  },
});

const arr = ["red", "blue", "green", "pink"];
var color = "";

if (localStorage.getItem("Avatar_color")) {
  color = localStorage.getItem("Avatar_color");
} else {
  color = arr[Math.floor(Math.random() * 10) % 4];
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      email_verified: "",
      phone: "",
      phone_verified: "",
      open: false,
      PPopen: false,
      CRopen: false,
      opensnackbar: false,
      severity: "error",
      error_message: "",
    };
  }
  componentWillUnmount() {
    oNauth();
  }
  componentDidMount() {
    console.log(this.props.location);
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.hasOwnProperty("prev")
    ) {
      if (this.props.location.state.prev === "login") {
        console.log(this.state.location);
        this.setState({
          opensnackbar: true,
          severity: "success",
          error_message: "Log-in Successful",
        });
      } else if (this.props.location.state.prev === "setupprofile") {
        console.log(this.state.location);
        this.setState({
          opensnackbar: true,
          severity: "success",
          error_message: "Profile Updated Successfully",
        });
      }
    }
    // setTimeout(() => {
    //   if (auth.currentUser) {
    //     console.log(auth.currentUser.uid);
    //   }
    // }, 5000);

    oNauth = auth.onAuthStateChanged((user) => {
      if (user) {
        // this.props.userProfile(user.uid);
        user.getIdToken().then((id) => this.props.userProfile(id));
        // this.props.userBookings(user.uid);
      } else {
        history.push("/login");
      }
    });
  }
  verfiysecondarydata = (authtype, data) => {
    this.props.history.push({
      pathname: "/auth/" + auth.currentUser.uid + "/" + authtype,
      state: {
        toverifydata: data,
        next: "profile",
      },
    });
  };
  // getUserDetails=async()=>{
  //   console.log("sending data")
  //   const val = window.location.href
  //   console.log(val)
  //   const uid = val.split("/")[4]
  //   console.log(uid,this.props.match.params.userId)
  //   const res = await this.props.fetchuserdata(uid)
  //   if(res.hasOwnProperty("message")){
  //     alert(res.message)
  //   }
  //   else {
  //     console.log(res)
  //   }
  //   return

  // }

  handlePP = () => {
    this.setState({ PPopen: true });
  };

  handlePPClose = () => {
    this.setState({ PPopen: false });
  };

  handleCR = () => {
    this.setState({ CRopen: true });
  };

  handleCRClose = () => {
    this.setState({ CRopen: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  /// to setup profile
  toedit = () => {
    // console.log("to setup");
    // console.log(this.props.user);
    var sec;
    if (this.props.user.profile.primary_data === "phone") {
      sec = this.props.user.profile.email;
    } else {
      sec = this.props.user.profile.phone;
    }
    this.props.history.push({
      pathname: "/setupprofile/" + auth.currentUser.uid,
      state: {
        primary: this.props.user.profile.primary_data,
        next: "profile",
        prev: "profile",
        name: this.props.user.profile.name,
        secondary: sec,
      },
    });
  };
  handelsnackclose = () => {
    this.setState({
      opensnackbar: false,
      severity: "success",
      error_message: "Profile Updated Successfully",
    });
  };
  render() {
    console.log(color);
    const { classes } = this.props;

    if (!this.props.user.profile) {
      return (
        <div>
          <NavbarMui />

          <LinearProgress
            hidden={false}
            style={{
              position: "sticky",
              //top: "10vh",
              backgroundColor: "#ffde17",
              zIndex: "999",
            }}
          />
          <div
            style={{
              display: "absolute",
              margin: "50% 40%",
              fontSize: "x-large",
              fontFamily: "Poppins",
            }}
          >
            {/* <CircularProgress style={{backgroundColor:"grey"}}/> */}
          </div>
        </div>
      );
    }

    const { profile } = this.props.user;
    return (
      <React.Fragment>
        <NavbarMui />
        <Link to="/">
          <CloseRoundedIcon
          className="crossIcon"
            fontSize="large"
            style={{
              color: "#ffde17",
              position: "absolute",
              right: "10px",
              marginTop: "10px",
              opacity: "0.7",
              zIndex: "1",
            }}
          />
        </Link>
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.opensnackbar}
          onClose={this.handelsnackclose}
          style={{
            //display: "none",
            marginBottom: "4rem",
          }}
        >
          <Alert
            variant="filled"
            elevation={6}
            style={{ width: "90vw" }}
            severity={this.state.severity}
            action={
              <IconButton
                aria-label="close"
                //color="inherit"
                onClick={this.handelsnackclose}
                size="small"
                style={{ color: "inherit" }}
              >
                <CloseIcon fontSize="inherit" />
                
              </IconButton>
            }
          >
            {this.state.error_message}
          </Alert>
        </Snackbar>
        {/* <CheckoutSteps step1 step2 step3 /> */}
        <div style={{ maxWidth: "450px", margin: "10px" }} className="profile">
          <Card className={classes.root}>
            {/* <CardActionArea> */}
            <CardMedia
              className={classes.media}
              title="Contemplative Reptile"
              style={{ height: "auto" }}
            >
              <Avatar
                style={{
                  width: "100px",
                  height: "100px",
                  margin: "15px auto",
                  background: color,
                  fontSize: "35px",
                }}
                // src={
                //   this.props.user.profile.photo
                //     ? `/${this.props.user.profile.photo}`
                //     : "/broken-image.jpg"
                // }
              >
                {this.props.user.profile.name &&
                  this.props.user.profile.name.slice(0, 1)}
              </Avatar>
            </CardMedia>
            {/* {!this.props.user.profile.photo && (
                <div style={{ padding: "5px 10px" }}>
                  <Link to="/upload">Upload Photo</Link>
                </div>
              )} */}
            <hr />
            <CardContent style={{ padding: "0 5px" }}>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  padding: "0 10px",
                }}
                onClick={this.toedit}
                color="primary"
              >
                EDIT
              </Button>
              <Typography variant="body2" color="textSecondary" component="div">
                <div className={classes.root}>
                  {/* <List component="nav" aria-label="main mailbox folders">
                      {Object.entries(profile).map((val) => {
                        if (val[0] !== "id")
                          return (
                            <ListItem key={val[0]} button>
                              <ListItemIcon>
                                {val[0].toUpperCase()}
                              </ListItemIcon>
                              <ListItemText
                                style={{ textAlign: "right" }}
                                primary={val[1]}
                              />
                            </ListItem>
                          );
                      })}
                    </List> */}
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem style={{ padding: "5px 2px" }} button>
                      <ListItemIcon>NAME</ListItemIcon>
                      <ListItemText
                        style={{
                          marginLeft: "30px",
                        }}
                        className="profileText"
                        primary={
                          <Typography
                          color="textPrimary"
                          style={{fontFamily:"poppins", fontSize: "14px", fontWeight: "400" }}>
                          {this.props.user.profile.name} </Typography> || (
                            <span style={{ fontStyle: "italic" }}>Empty</span>
                          )
                        }
                      />
                    </ListItem>
                    <ListItem style={{ padding: "5px 2px" }} button>
                      <ListItemIcon>EMAIL ID</ListItemIcon>
                      <ListItemText
                        style={{
                          marginLeft: "25px",
                        }}
                        className="profileText"
                        primary={
                          <Typography
                          color="textPrimary"
                          style={{fontFamily:"poppins", fontSize: "14px", fontWeight: "400" }}>
                          {this.props.user.profile.email} </Typography>|| (
                            <span style={{ fontStyle: "italic" }}>Empty</span>
                          )
                        }
                      />
                      {this.props.user.profile.primary_data === "email" ? (
                        <Chip
                          style={{
                            borderColor: "green",
                            color: "green",
                            marginRight: "0",
                          }}
                          variant="outlined"
                          label="Primary"
                          size="small"
                          color="secondary"
                        ></Chip>
                      ) : (
                        <Chip
                          style={{
                            borderColor: "grey",
                            color: "gray",
                            marginRight: "0",
                          }}
                          variant="outlined"
                          label="Secondary"
                          size="small"
                          color="secondary"
                        ></Chip>
                      )}
                    </ListItem>
                    <ListItem style={{ padding: "5px 2px" }} button>
                      <ListItemIcon>PHONE</ListItemIcon>
                      <ListItemText
                        style={{
                          marginLeft: "30px",
                        }}
                        className="profileText"
                        primary={
                          <Typography
                          color="textPrimary"
                          style={{fontFamily:"poppins", fontSize: "14px", fontWeight: "400" }}>
                          {this.props.user.profile.phone} </Typography>|| (
                            <span style={{ fontStyle: "italic" }}>Empty</span>
                          )
                        }
                      />
                      {this.props.user.profile.primary_data === "phone" ? (
                        <Chip
                          style={{
                            borderColor: "green",
                            color: "green",
                            marginRight: "0",
                          }}
                          variant="outlined"
                          size="small"
                          label="Primary"
                          color="secondary"
                        ></Chip>
                      ) : (
                        <Chip
                          style={{
                            borderColor: "grey",
                            color: "gray",
                            marginRight: "0",
                          }}
                          variant="outlined"
                          size="small"
                          label="Secondary"
                          color="secondary"
                        ></Chip>
                      )}
                    </ListItem>
                    <ListItem
                      style={{ padding: "5px 2px" }}
                      style={{
                        marginLeft: "30px",
                      }}
                      className="profileText"
                    >
                      {/* <ListItemText
                          style={{
                            marginLeft: "30px",
                          }}
                          className="profileText"
                        > */}
                      {/* {this.props.user.profile.phone !== null &&
                      !this.props.user.profile.phone_verified ? (
                        <div style={{ textAlign: "center" }}>
                          The phone number is not verified Click to &nbsp;
                          <Button
                            onClick={(e) =>
                              this.verfiysecondarydata(
                                "phoneauth",
                                this.props.user.profile.phone
                              )
                            }
                          >
                            Verify
                          </Button>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {this.props.user.profile.email !== null &&
                      !this.props.user.profile.email_verified ? (
                        <div style={{ textAlign: "center" }}>
                          The Email is not verified Click to &nbsp;
                          <Button
                            color="primary"
                            onClick={(e) =>
                              this.verfiysecondarydata(
                                "emailauth",
                                this.props.user.profile.email
                              )
                            }
                          >
                            Verify
                          </Button>
                        </div>
                      ) : (
                        <div></div>
                      )} */}
                      {/* </ListItemText> */}
                    </ListItem>
                  </List>
                </div>
              </Typography>
            </CardContent>
            {/* </CardActionArea> */}
            <CardActions>
              <Button
                style={{ fontSize: "9px", padding: "0 4px" }}
                size="small"
                color="primary"
                onClick={this.handleClickOpen}
              >
                TERMS & CONDITIONS
              </Button>
              <Button
                style={{ fontSize: "9px", padding: "0 4px" }}
                size="small"
                color="primary"
                onClick={this.handlePP}
              >
                PRIVACY POLICY
              </Button>

              <Button
                style={{ fontSize: "9px", padding: "0 4px" }}
                size="small"
                color="primary"
                onClick={this.handleCR}
              >
                CANCELLATION & REFUND POLICY
              </Button>
            </CardActions>
          </Card>
          {/* {this.getUserDetails()} */}
          {/* {this.renderItem()} */}
        </div>
        <Dialog
          className="GuestDetails"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => this.setState({ open: false })}
          />
          <DialogTitle
            style={{ padding: "20px 8px 0" }}
            id="alert-dialog-title"
          >
            <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
              TERMS & CONDITIONS
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "8px" }} component="div">
            <DialogContentText
              style={{ fontSize: "12px" }}
              component="div"
              id="alert-dialog-description"
            >
              <TOU />
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          className="GuestDetails"
          open={this.state.PPopen}
          onClose={this.handlePPClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => this.setState({ PPopen: false })}
          />
          <DialogTitle
            style={{ padding: "20px 8px 0" }}
            id="alert-dialog-title"
          >
            <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
              PRIVACY POLICY
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "8px" }} component="div">
            <DialogContentText
              style={{ fontSize: "12px" }}
              component="div"
              id="alert-dialog-description"
            >
              <PP />
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          className="GuestDetails"
          open={this.state.CRopen}
          onClose={this.handleCRClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => this.setState({ CRopen: false })}
          />
          <DialogTitle
            style={{ padding: "20px 8px 0" }}
            id="alert-dialog-title"
          >
            <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
              CANCELLATION & REFUND POLICY
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "8px" }} component="div">
            <DialogContentText
              style={{ fontSize: "12px" }}
              component="div"
              id="alert-dialog-description"
            >
              <CR />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Footer bottom />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userProfile,
  };
};

const styles = withStyles(useStyles)(Profile);

export default connect(mapStateToProps, {
  userProfile,
  userBookings,
  fetchuserdata,
})(styles);
