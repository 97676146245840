import React from "react";

class GuestDetails extends React.Component {
    render() {
        return (
            <div className="guest_main">
                <div>
                    <h1 className="text-center">Enter Guest Details</h1>
                    <form className="guest_form">
                        <h2>Mixed Dorm</h2>
                        <div className="guest_details mixed_details">
                            <input name="mixed_name_1" placeholder="Guest Name 1" type="text" required={true} />
                            <input name="mixed_age_1" placeholder="Age" type="number" required={true} />
                            <span>
                                <span class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Male</label>
                                </span>
                                <span class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Female</label>
                                </span>
                            </span>
                        </div>
                        <div className="guest_details mixed_details">
                            <input name="mixed_name_2" placeholder="Guest Name 1" type="text" required={true} />
                            <input name="mixed_age_1" placeholder="Age" type="number" required={true} />
                            <span>
                                <span class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" checked />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Male</label>
                                </span>
                                <span class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Female</label>
                                </span>
                            </span>
                        </div>

                        <h2>Female Dorm</h2>
                        <div className="guest_details female_details">
                            <input placeholder="Guest Name 1" type="text" required={true} />
                            <input placeholder="Age" type="number" required={true} />
                        </div>
                        <div className="guest_details female_details">
                            <input placeholder="Guest Name 1" type="text" required={true} />
                            <input placeholder="Age" type="number" required={true} />
                        </div>
                        <div className="guest_submit">
                            <input type="submit" value="Done" />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default GuestDetails