import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Navbar from "./static/navbar/navbarMui";
import Footer from "./static/footer";
import LOGOHEADER from "../assets/about.jpeg";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Link } from "react-router-dom";
import "../assets/css/test.css";
const useStyles = (theme) => ({
  root: {
    // maxWidth: 345,
    backgroundSize: "contain",
  },
  media: {
    // width: "100vw",
    height: "10rem",
    backgroundSize: "contain",
    maxWidth: "98vw",
    backgroundColor: "#ffde17",
  },
});

class AboutUs extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navbar />
        <Link to="/">
          <CloseRoundedIcon
          className="crossIcon"
            fontSize="large"
            style={{
              color: "white",
              position: "absolute",
              right: "10px",
              marginTop: "10px",
              opacity: "1",
              zIndex: "1",
            }}
          />
        </Link>

        <Card style={{ margin: "10px" }} className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={LOGOHEADER}
              title="bunkbread"
            />
            <CardContent>
              
               <div class="newContent">
                We love adventure, exploring new things. Be it place, people,
                food, or be it traditions & lifestyle. We consume such
                experiences, and confess that we are addicted to it. With
                bunkbread we are trying to aid the ones who share the same
                explorer’s enthusiasm as us. We abet such individuals in
                experiencing the unknown. With our chain of HOSTELs we intend to
                provide a base for the adventure-hungry souls. And with our
                experience HOTSPOTs we intend to help explorers have a
                peculiarly unique outlook of a place, which is mostly hidden for
                a common eye. We also help the 9-5ers to strike a perfect
                balance between their passion & their responsibilities through
                WORKPADs. We are a team of hard working, passionate adventurer’s
                who intend to convey the explorer’s lifestyle to fellow
                adventurers.</div>
            </CardContent>
          </CardActionArea>
        </Card>
        <Footer bottom />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(AboutUs);
