import axios from "../apis/axios";
import { auth } from "../firebase";
import history from "../helpers/history";
// import firebase from "firebase";
import _ from "lodash";
import { _serverurl } from "../keys/URLS";

export const date = (date) => {
  return {
    type: "date",
    payload: date,
  };
};

export const update_available = (data) => {
  return {
    type: "update_available",
    payload: data,
  };
};

export const mixedGuestDetails = (data) => {
  return {
    type: "mixedGuestDetails",
    payload: data,
  };
};

export const femaleGuestDetails = (data) => {
  return {
    type: "femaleGuestDetails",
    payload: data,
  };
};

export const mixedTempDetails = (data) => {
  return {
    type: "mixedTempDetails",
    payload: data,
  };
};

export const femaleTempDetails = (data) => {
  return {
    type: "femaleTempDetails",
    payload: data,
  };
};

export const LiveSoon = (data) => {
  return {
    type: "LIVESOON",
    payload: data,
  };
};

export const availableDestinations = () => {
  return async (dispatch) => {
    const { data } = await axios.get(_serverurl + "destination");
    // var destinations = [];
    // data.map((loc) => {
    //   destinations.push(loc.location);
    // });
    dispatch({
      type: "AVAIL_DESTINATION",
      payload: data,
    });
  };
};

export const exploreDestinations = ({ location_id, check_in, check_out }) => {
  localStorage.setItem("check_in", check_in);
  localStorage.setItem("check_out", check_out);
  localStorage.setItem("location_id", location_id);
  return async (dispatch) => {
    const { data } = await axios.get(
      _serverurl +
        `check?location_id=${location_id}&check_in=${check_in.slice(
          2
        )}&check_out=${check_out.slice(2)}`
    );
    if (data.message === "date_error") {
      return { message: "date_error", body: data.body };
    } else {
      dispatch({
        type: "date",
        payload: data,
      });
    }
  };
};

export const exploreWorkpad = ({ location_id, check_in, check_out }) => {
  localStorage.setItem("check_in_W", check_in);
  localStorage.setItem("check_out_W", check_out);
  localStorage.setItem("W_location_id", location_id);
  return async (dispatch) => {
    const { data } = await axios.get(
      _serverurl +
        `check?location_id=${location_id}&check_in=${check_in.slice(
          2
        )}&check_out=${check_out.slice(2)}`
    );
    dispatch({
      type: "date",
      payload: data,
    });
  };
};

export const selected = (value) => {
  return {
    type: "SELECTED",
    payload: value,
  };
};

export const userProfile = (userId) => async (dispatch) => {
  try {
    // console.log(userId)
    // console.log("fetching user data");
    const { data } = await axios.get("profile", {
      headers: { Authorization: userId },
    });
    // console.log(data);
    dispatch({
      type: "USER_PROFILE",
      payload: data,
    });
    if (data.hasOwnProperty("message")) {
      throw new Error(data.message);
    } else return data;
  } catch (error) {
    // console.log(error);
    return { error: error.message };
  }
};
export const userBookings = (userId, id) => {
  return async (dispatch) => {
    if (id === "deleted") {
      const { data } = await axios.get(`profile/deleted`, {
        headers: { Authorization: userId },
      });
      // console.log(data);
      dispatch({
        type: "USER_BOOKING",
        payload: data,
      });
      return "DONE";
    }
    const { data } = await axios.get(`profile/bookings?id=${id}`, {
      headers: { Authorization: userId },
    });

    dispatch({
      type: "USER_BOOKING",
      payload: data,
    });
    return "DONE";
  };
};

// export const userBookings = (userId) => {
//   return async (dispatch) => {
//     const { data } = await axios.get(`profile/deleted`, {
//       headers: { Authorization: userId },
//     });
//     dispatch({
//       type: "USER_BOOKING",
//       payload: data,
//     });
//   };
// };

export const edit = (TokenId, values) => {
  return async () => {
    const { data } = await axios.patch("profile", values, {
      headers: { Authorization: TokenId },
    });
    history.push("/profile");
  };
};

export const notification = () => {
  return async (dispatch) => {
    const { data } = await axios.get("notification");
    dispatch({
      type: "NOTIFICATION",
      payload: data,
    });
  };
};

export const offers = () => {
  return async (dispatch) => {
    const { data } = await axios.get("notification/offers");
    delete data.offers["show"];
    dispatch({
      type: "OFFERS",
      payload: data,
    });
  };
};

export const currency = (currency) => {
  return {
    type: "CURRENCY",
    payload: currency,
  };
};

export const admin = (date) => {
  const check_in = JSON.stringify(date);
  return async (dispatch) => {
    const { data } = await axios.get(`admin?check_in=${check_in}`);

    dispatch({
      type: "ADMIN",
      payload: data,
    });
  };
};
export const booking = (order, booking_details) => async (dispatch) => {
  // console.log("booking>");
  try {
    // console.log(_serverurl + "destination");
    const details = await axios.post(_serverurl + "destination", {
      location_id: booking_details.location_id,
    });
    let total;
    if (localStorage.getItem("currency") === "INR") {
      total =
        booking_details.mixed_guest_number *
          booking_details.days_count *
          details.data.mixed_dorm_price +
        booking_details.female_guest_number *
          booking_details.days_count *
          details.data.female_dorm_price;
      if (booking_details.partial === true) {
        order.orderamount =
          (total + (details.data.tax / 100) * total) *
            (booking_details.partial_amount / 100) -
          booking_details.coupon_discount;
      } else {
        order.orderamount =
          total +
          (details.data.tax / 100) * total -
          booking_details.coupon_discount;
      }
    } else if (localStorage.getItem("currency") === "USD") {
      total =
        booking_details.mixed_guest_number *
          booking_details.days_count *
          details.data.mixed_dorm_price_D +
        booking_details.female_guest_number *
          booking_details.days_count *
          details.data.female_dorm_price_D;
      if (booking_details.partial === true) {
        order.orderamount =
          (total + (details.data.tax / 100) * total) *
            (booking_details.partial_amount / 100) -
          booking_details.coupon_discount;
      } else {
        order.orderamount =
          total +
          (details.data.tax / 100) * total -
          booking_details.coupon_discount;
      }
    } else if (localStorage.getItem("currency") === "EUR") {
      total =
        booking_details.mixed_guest_number *
          booking_details.days_count *
          details.data.mixed_dorm_price_E +
        booking_details.female_guest_number *
          booking_details.days_count *
          details.data.female_dorm_price_E;
      if (booking_details.partial === true) {
        order.orderamount =
          (total + (details.data.tax / 100) * total) *
            (booking_details.partial_amount / 100) -
          booking_details.coupon_discount;
      } else {
        order.orderamount =
          total +
          (details.data.tax / 100) * total -
          booking_details.coupon_discount;
      }
    }
    // console.log("payment:", _serverurl + "payment/payments");
    const res = await axios.post(_serverurl + "payment/payments", order);
    const data = res.data;
    // console.log(data);
    if (data.message) {
      throw new Error(data.message);
    }
    //dispatch
    // console.log("<booking");
    return res;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const bookingHotspot = (order, booking_details) => async (dispatch) => {
  // console.log("booking>");
  try {
    // console.log(_serverurl + "destination");
    const details = await axios.post(_serverurl + "destination", {
      location_id: booking_details.location_id,
    });
    let total =
      booking_details.mixed_guest_number *
        booking_details.days_count *
        details.data.W_mixed_dorm_price +
      booking_details.female_guest_number *
        booking_details.days_count *
        details.data.W_female_dorm_price;
    if (booking_details.partial === true) {
      order.orderamount =
        (total + (details.data.tax / 100) * total) *
        (booking_details.partial_amount / 100);
    } else {
      order.orderamount = total + (details.data.tax / 100) * total;
    }
    // console.log("payment:", _serverurl + "payment/payments");
    const res = await axios.post(_serverurl + "payment/payments", order);
    const data = res.data;
    // console.log(data);
    if (data.message) {
      throw new Error(data.message);
    }
    //dispatch
    // console.log("<booking");
    return res;
  } catch (error) {
    // console.log(error);
    return;
  }
};

export const sendemailotp = (email) => async (dispatch) => {
  try {
    // console.log(axios.defaults);
    const res = await axios.post(_serverurl + "userauth/sendemailotp", {
      recipient: email,
    });
    // console.log(res);
    if (res.data.status !== "message delivered")
      throw new Error(res.data.status);
    // console.log(res.data);
    return res.data;
  } catch (error) {
    return error.message;
  }
};

export const createaccountemail = (email) => async () => {
  try {
    // console.log(email, "user_email");
    const res = await axios.post(_serverurl + "userauth/createaccountemail", {
      user_email: email,
    });
    // console.log("response=>",res.data);
    let user;
    if (res.data.customtoken) {
      // console.log("Auth Persistence and Logging in ");
      await auth
        .signInWithCustomToken(res.data.customtoken)
        .then((usercredential) => {
          // console.log("Logged in");
          // console.log("user:", usercredential);
          user = usercredential.user;
          // console.log(user)
        })
        .catch((e) => {
          // console.log(e);
          throw new Error(e.message);
        });
    }
    // console.log(user);
    // dispatch({ type: "LOG_USER", payload: user });
    return "success";
  } catch (error) {
    // console.log(error);
    return error;
  }
};

export const setupuser = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const res = await axios.post(_serverurl + "userauth/setupuser", data);
    // console.log(res);
    if (res.data.message === "success") {
      return "success";
    } else {
      return "failed";
    }
  } catch (error) {
    return error.message;
  }
};

export const chckdatabse = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const res = await axios.post(_serverurl + "userauth/createdbnew", data);
    return res.data;
  } catch (error) {
    return { message: "failure", status: "network error" };
  }
};

export const fetchuserdata = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const res = await axios.post(_serverurl + "userdata/fetch", { uid: data });
    return res.data;
  } catch {
    return { message: "Network Error" };
  }
};

export const verifysec = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const res = await axios.post(_serverurl + "userauth/verifysec", data);
    return res.data;
  } catch (error) {
    return { status: "failed", message: "Network Error" };
  }
};

export const updateHotspot = (location_id, check_in, check_out) => {
  localStorage.setItem("hotspot_location_id", location_id);
  localStorage.setItem("hotspot_check_in", "20" + check_in);
  localStorage.setItem("hotspot_check_out", "20" + check_out);
  return async (dispatch) => {
    const res = await axios.get(
      `/hotspot/check?location_id=${location_id}&check_in=${check_in}&check_out=${check_out}`
    );
    const res2 = await axios.get(`/hotspot/${location_id}`);
    let d = {};
    console.log({location_id} +res2.data.bed_type[0]);
    for (let i = 0; i < res2.data.bed_type.length; i++) {
      d[res2.data.bed_type[i]] = 1000;
    }
    for (const key in res.data) {
      if (key >= check_in && key < check_out) {
        for (let i = 0; i < res2.data.bed_type.length; i++) {
          if (res.data[key][res2.data.bed_type[i]] < d[res2.data.bed_type[i]])
            d[res2.data.bed_type[i]] = res.data[key][res2.data.bed_type[i]];
        }
      }
    }
    dispatch({
      type: "update_hotspot",
      payload: d,
    });
  };
};

export const hotspotGuestDetails = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "hotspotGuestDetails",
      payload: data,
    });
  };
};

export const hotspotDetails = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "hotspotDetails",
      payload: data,
    });
  };
};

export const hotspotSelection = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "hotspotSelection",
      payload: data,
    });
  };
};
