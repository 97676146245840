import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Link } from "react-router-dom";
import D1 from "../../assets/darjeeling/1.jpg";
import D2 from "../../assets/darjeeling/2.jpg";
import D3 from "../../assets/darjeeling/3.jpg";
import D4 from "../../assets/darjeeling/4.jpeg";
import D5 from "../../assets/darjeeling/5.jpg";
import D6 from "../../assets/darjeeling/6.jpg";
import G1 from "../../assets/gangtok/1.jpg";
import G2 from "../../assets/gangtok/2.jpg";
import G3 from "../../assets/gangtok/3.jpg";
import G4 from "../../assets/gangtok/4.jpg";
import G5 from "../../assets/gangtok/5.jpg";
import G6 from "../../assets/gangtok/6.jpg";
import S1 from "../../assets/siliguri/1.jpg";
import S2 from "../../assets/siliguri/2.jpg";
import S3 from "../../assets/siliguri/3.jpg";
import S4 from "../../assets/siliguri/4.jpg";
import S5 from "../../assets/siliguri/5.jpg";
import S6 from "../../assets/siliguri/6.jpg";
import E1 from "../../assets/kurseong/1.png";
import E2 from "../../assets/kurseong/2.png";
import E3 from "../../assets/kurseong/3.png";
import E4 from "../../assets/kurseong/4.png";
import E5 from "../../assets/kurseong/5.png";
import E6 from "../../assets/kurseong/6.png";
import E7 from "../../assets/kurseong/7.png";

import SW1 from "../../assets/skywalk/1.jpeg";
import SW2 from "../../assets/skywalk/2.jpeg";
import SW3 from "../../assets/skywalk/3.jpeg";
import SW4 from "../../assets/skywalk/4.jpeg";
import SW5 from "../../assets/skywalk/5.jpeg";

import Tabs from "./tabs";
import Typography from "@material-ui/core/Typography";
import Navbar from "./navbar/navbarMui";
import Paper from "@material-ui/core/Paper";
import Footer from "./footer";
import ecopulse_kurseong from "../../assets/ecopulse/ecopulse_kurseong.json";
import skywalk from "../../assets/ecopulse/skywalk.json";
import siliguri from "../../assets/Hostels/siliguri.json";
import Dialog from "@material-ui/core/Dialog";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
});

const Darjeeling_Text =
  " Darjeeling conjures visions of snow peaks, serenity of vibrant green hills steeped in splendour, a land of breathtaking beauty crowned by the majestic Himalayas. Darjeeling is one of the most magnificent hill resorts in the world. Flaming red rhododendrons, sparkling white magnolias, miles of undulating hillsides covered with emerald green tea bushes, the exotic forests of silver fir - all under the blanket of a brilliant azure sky dappled with specks of clouds, compellingly confounds Darjeeling as the QUEEN OF HILL STATIONS. The crest of Kanchenjunga shining in the first dawn light truly supports the title. Darjeeling beckons thousands today for a leisurely respite from the bustle of the madding crowd.";
const Siliguri_Text = siliguri.about_text;

const Gangtok_Text =
  "Gangtok-The name meaning hill-top, Gangtok can be safely said to be one of the most beautiful hill stations in the country. With everything in just the right amount from a blend of cultural identities to urbanisation, Gangtok is a breathing and dynamic bit of paradise of the northeast. It has an amazing view of mount Kanchenjunga, the third highest mountain peak in the world. Also like everything around it, Gangtok is abundant in natural beauty and has various natural attractions such as the Tsomgo Lake, Ban Jhakri falls, Tashi viewpoint and more. Other places to visit include the Enchey Monastery, Ganesh Tok, Do Drul Chorten Rumtek Monastery, etc. Also, river Teesta is one of the best spots for white water rafting in North East India.";
const ecopulse_kurseong_text = ecopulse_kurseong.about_text;
const skywalk_text =skywalk.about_text;

const D_images = [D1, D2, D3, D4];
const S_images = [S1, S2, S3, S4];
const G_images = [G1, G2, G3, G4];
const E_images = [E1, E2, E3, E4, E5, E6, E7];
const SW_images =[SW1, SW2, SW3, SW4, SW5];

const Skywalk_Images = [
  {
    img: SW1,
    author: "Sky Walk",
  },
  {
    img: SW2,
    author: "Sky Walk",
  },
  {
    img: SW3,
    author: "Sky Walk",
  },
  {
    img: SW4,
    author: "Sky Walk",
  },
  {
    img: SW5,
    author: "Sky Walk",
  },
  
];


const Ecopulse_Images = [
  {
    img: E1,
    author: "Ecopulse Kurseong",
  },
  {
    img: E2,
    author: "Ecopulse Kurseong",
  },
  {
    img: E3,
    author: "Ecopulse Kurseong",
  },
  {
    img: E4,
    author: "Ecopulse Kurseong",
  },
  {
    img: E5,
    author: "Ecopulse Kurseong",
  },
  {
    img: E6,
    author: "Ecopulse Kurseong",
  },
];

const Darjeeling_Images = [
  {
    img: D1,
    author: "Darjeeling",
  },
  {
    img: D2,
    author: "Darjeeling",
  },
  {
    img: D3,
    author: "Darjeeling",
  },
  {
    img: D4,
    author: "Darjeeling",
  },
  {
    img: D5,
    author: "Darjeeling",
  },
  {
    img: D6,
    author: "Darjeeling",
  },
];

const Siliguri_Images = [
  {
    img: S1,
    author: "Siliguri",
  },
  {
    img: S2,
    author: "Siliguri",
  },
  {
    img: S3,
    author: "Siliguri",
  },
  {
    img: S4,
    author: "Siliguri",
  },
  {
    img: S5,
    author: "Siliguri",
  },
  {
    img: S6,
    author: "Siliguri",
  },
];

const Gangtok_Images = [
  {
    img: G1,
    author: "Gangtok",
  },
  {
    img: G2,
    author: "Gangtok",
  },
  {
    img: G3,
    author: "Gangtok",
  },
  {
    img: G4,
    author: "Gangtok",
  },
  {
    img: G5,
    author: "Gangtok",
  },
  {
    img: G6,
    author: "Gangtok",
  },
];

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      D_open: false,
      E_open: false,
      S_open: false,
      G_open: false,
      SW_open: false,
      photoIndex: 0,
    };
  }

  render() {
    const { location } = this.props.match.params;
    const { classes } = this.props;
    const { photoIndex } = this.state;
    return (
      <React.Fragment>
        <Navbar />
        <div style={{ margin: "0 0px" }}>
          <div className={`image_W ${classes.root}`}>
            <GridList className={classes.gridList} cols={2.5}>
            {location === "sky_walk" && 
                Skywalk_Images.map((tile) => (
                  <GridListTile
                    onClick={() => this.setState({ SW_open: true })}
                    key={tile.img}
                  >
                    <img src={tile.img} alt={tile.title} />
                    <GridListTileBar
                      title={tile.title}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    />
                  </GridListTile>
                ))}
              {location === "ecopulse_kurseong" &&
                Ecopulse_Images.map((tile) => (
                  <GridListTile
                    onClick={() => this.setState({ E_open: true })}
                    key={tile.img}
                  >
                    <img src={tile.img} alt={tile.title} />
                    <GridListTileBar
                      title={tile.title}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    />
                  </GridListTile>
                ))}
              {location === "darjeeling" &&
                Darjeeling_Images.map((tile) => (
                  <GridListTile key={tile.img}>
                    <img
                      onClick={() => this.setState({ D_open: true })}
                      src={tile.img}
                      alt={tile.title}
                    />
                    <GridListTileBar
                      title={tile.title}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    />
                  </GridListTile>
                ))}

              {location === "gangtok" &&
                Gangtok_Images.map((tile) => (
                  <GridListTile key={tile.img}>
                    <img
                      onClick={() => this.setState({ G_open: true })}
                      src={tile.img}
                      alt={tile.title}
                    />
                    <GridListTileBar
                      title={tile.title}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    />
                  </GridListTile>
                ))}
              {location === "siliguri" &&
                Siliguri_Images.map((tile) => (
                  <GridListTile key={tile.img}>
                    <img
                      onClick={() => this.setState({ S_open: true })}
                      src={tile.img}
                      alt={tile.title}
                    />
                    <GridListTileBar
                      title={tile.title}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    />
                  </GridListTile>
                ))}
            </GridList>
          </div>
          {location === "sky_walk" && (
            <div className="aboutLocation">
              <h2
                style={{ fontFamily: "Poppins", fontSize: "20px" }}
              >
                HOTSPOT Sky Walk
              </h2>
              <span
                style={{ fontSize: "13px", fontFamily: "Poppins" }}
              >
                {skywalk_text}
              </span>
            </div>
          )}
          {location === "ecopulse_kurseong" && (
            <div className="aboutLocation">
              <h2
                style={{ fontFamily: "Poppins", fontSize: "20px" }}
              >
                HOTSPOT Ecopulse
              </h2>
              <span
                style={{ fontSize: "13px", fontFamily: "Poppins" }}
              >
                {ecopulse_kurseong_text}
              </span>
            </div>
          )}
          {location === "darjeeling" && (
            <div className="aboutLocation">
              <h2
                style={{ fontFamily: "Poppins", fontSize: "20px" }}
              >
                Darjeeling
              </h2>
              <span
                style={{ fontSize: "13px", fontFamily: "Poppins" }}
              >
                {Darjeeling_Text}
              </span>
            </div>
          )}
          {location === "gangtok" && (
            <div className="aboutLocation">
              <h2
                style={{ fontFamily: "Poppins", fontSize: "20px" }}
              >
                Gangtok
              </h2>
              <span
                style={{ fontSize: "13px", fontFamily: "Poppins" }}
              >
                {Gangtok_Text}
              </span>
            </div>
          )}
          {location === "siliguri" && (
            <div className="aboutLocation">
              <h2
                style={{ fontFamily: "Poppins", fontSize: "20px" }}
              >
                bunkbread Siliguri
              </h2>
              <span
                style={{ fontSize: "13px", fontFamily: "Poppins" }}
              >
                {Siliguri_Text}
              </span>
            </div>
          )}
          {location === "sky_walk" && (
            <Tabs
              know={skywalk["know_how "]}
              dorms={skywalk.dorms}
              amenities={skywalk.amenities}
              location={location}
            />
          )}
          {location === "ecopulse_kurseong" && (
            <Tabs
              know={skywalk["know_how "]}
              dorms={skywalk.dorms}
              amenities={skywalk.amenities}
              location={location}
            />
          )}
          {location === "siliguri" && (
            <Tabs
              know={siliguri["know_how "]}
              dorms={siliguri.dorms}
              amenities={siliguri.amenities}
              location={location}
            />
          )}
          {/* {location === "ecopulse_kurseong" ? (
            <Tabs
              know={ecopulse_kurseong["know_how "]}
              dorms={ecopulse_kurseong.dorms}
              amenities={ecopulse_kurseong.amenities}
              location={location}
            />
          ) : (
            <Tabs
              know={null}
              dorms={null}
              amenities={null}
              location={location}
            />
          )} */}
          {location === "sky_walk" && (
            <div style={{margin: "10px"}}>
            <Paper>
              
              <div className="locationMap">
                <iframe
                  style={{ width: "60%", border: "none" }}
                  //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d444.20435040107935!2d88.26762061587361!3d27.04170353782433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e42f78cb3e29e1%3A0x1e4e119547616002!2sBunkbread%20Darjeeling!5e0!3m2!1sen!2sin!4v1609248982300!5m2!1sen!2sin"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.7779813881516!2d88.2729799150449!3d26.910540383128588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e431392b9c3d19%3A0x8ca2d841762d9002!2sECOPULSE!5e0!3m2!1sen!2sin!4v1626526117492!5m2!1sen!2sin"
                />
                <Typography
                  style={{
                    margin: "auto",
                    width: "50%",
                    marginLeft: "8px",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                  }}
                  variant="body2"
                  // color="textSecondary"
                  component="p"
                >
                  {location === "sky_walk"
                    ? "Some where in heven West Bengal-734203"
                    : ""}
                </Typography>
              </div>
            </Paper></div>
          )}
          {location === "siliguri" && (
            <div style={{margin: "10px"}}>
            <Paper>
              
              <div className="locationMap">
                <iframe
                  style={{ width: "60%", border: "none" }}
                  //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d444.20435040107935!2d88.26762061587361!3d27.04170353782433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e42f78cb3e29e1%3A0x1e4e119547616002!2sBunkbread%20Darjeeling!5e0!3m2!1sen!2sin!4v1609248982300!5m2!1sen!2sin"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.347248107781!2d88.40791701451676!3d26.733292774227774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e44179c38d1e7d%3A0x109590bcb21803d!2sBunkbread%20Siliguri!5e0!3m2!1sen!2sin!4v1650029633988!5m2!1sen!2sin"
                />
                <Typography
                  style={{
                    margin: "auto",
                    width: "50%",
                    marginLeft: "8px",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                  }}
                  variant="body2"
                  // color="textSecondary"
                  component="p"
                >
                  {location === "siliguri"
                    ? "1st floor, Shakuntala Bhavan, Tea Auction Road, near Mallaguri Underpass, Siliguri, West Bengal-734003"
                    : ""}
                </Typography>
              </div>
            </Paper></div>
          )}
          {location === "ecopulse_kurseong" && (
          <div style={{margin: "10px"}}>
          <Paper>
            
            <div className="locationMap">
              <iframe
                style={{ width: "60%", border: "none" }}
                //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d444.20435040107935!2d88.26762061587361!3d27.04170353782433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e42f78cb3e29e1%3A0x1e4e119547616002!2sBunkbread%20Darjeeling!5e0!3m2!1sen!2sin!4v1609248982300!5m2!1sen!2sin"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.7779813881516!2d88.2729799150449!3d26.910540383128588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e431392b9c3d19%3A0x8ca2d841762d9002!2sECOPULSE!5e0!3m2!1sen!2sin!4v1626526117492!5m2!1sen!2sin"
              />
              <Typography
                style={{
                  margin: "auto",
                  width: "50%",
                  marginLeft: "8px",
                  fontSize: "13px",
                  fontFamily: "Poppins",
                }}
                variant="body2"
                // color="textSecondary"
                component="p"
              >
                {location === "ecopulse_kurseong"
                  ? "Sepoy Dhora Tea Garden, West Bengal-734203"
                  : ""}
              </Typography>
            </div>
          </Paper></div>
          )}
        </div>
          
        <Footer />
        {this.state.SW_open && (
          <Lightbox
            mainSrc={SW_images[photoIndex]}
            nextSrc={SW_images[(photoIndex + 1) % SW_images.length]}
            prevSrc={
              SW_images[(photoIndex + SW_images.length - 1) % SW_images.length]
            }
            onCloseRequest={() => this.setState({ SW_open: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + SW_images.length - 1) % SW_images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % SW_images.length,
              })
            }
          />
        )}
        {this.state.D_open && (
          <Lightbox
            mainSrc={D_images[photoIndex]}
            nextSrc={D_images[(photoIndex + 1) % D_images.length]}
            prevSrc={
              D_images[(photoIndex + D_images.length - 1) % D_images.length]
            }
            onCloseRequest={() => this.setState({ D_open: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + D_images.length - 1) % D_images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % D_images.length,
              })
            }
          />
        )}
{this.state.E_open && (
          <Lightbox
            mainSrc={E_images[photoIndex]}
            nextSrc={E_images[(photoIndex + 1) % E_images.length]}
            prevSrc={
              E_images[(photoIndex + E_images.length - 1) % E_images.length]
            }
            onCloseRequest={() => this.setState({ E_open: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + E_images.length - 1) % E_images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % E_images.length,
              })
            }
          />
        )}
        {this.state.S_open && (
          <Lightbox
            mainSrc={S_images[photoIndex]}
            nextSrc={S_images[(photoIndex + 1) % S_images.length]}
            prevSrc={
              S_images[(photoIndex + S_images.length - 1) % S_images.length]
            }
            onCloseRequest={() => this.setState({ S_open: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + S_images.length - 1) % S_images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % S_images.length,
              })
            }
          />
        )}

        {this.state.G_open && (
          <Lightbox
            mainSrc={G_images[photoIndex]}
            nextSrc={G_images[(photoIndex + 1) % G_images.length]}
            prevSrc={
              G_images[(photoIndex + G_images.length - 1) % G_images.length]
            }
            onCloseRequest={() => this.setState({ G_open: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + G_images.length - 1) % G_images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % G_images.length,
              })
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Explore);
