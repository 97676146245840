import React from "react";
import axios from "../../apis/axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import { TextField, Select, MenuItem, FormControl } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { _serverurl } from "../../keys/URLS";
import history from "../../helpers/history";
import { connect } from "react-redux";
import { Snackbar, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { auth } from "../../firebase";
import { Close as CloseIcon } from "@material-ui/icons";
import firebase from "firebase";
import { userBookings } from "../../actions";
import { pdfsenderndownload } from "./renderpdf";
const moment = require("moment-timezone");

class Cancel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      booking_key: this.props.booking_key,
      booking_id: "",
      amount: null,
      paid_amount: null,
      paid_percent: null,
      check_in: null,
      check_out: null,
      bed_type: [],
      shared_tent_details: [],
      tree_house_details: [],
      guest_details: {},
      refund_percent: 70,
      to_cancel_mixed: [],
      to_cancel_female: [],
      to_cancel_mixed_length: 0,
      to_cancel_female_length: 0,
      new_check_in: null,
      new_check_out: null,
      today_date: null,
      paymentId: null,
      returnAmount: 0,
      // Popup variables
      full_cancel: false,
      modify_guest: false,
      modify_guest_confirmation: false,
      modify_date: false,
      modify_date_confirmation: false,
      backdrop: false,
      openDailogcancel: false,
      opensnackbar: false,
      snack_message: "",
      severity: "success",
    };

    this.newMethod();
  }

  newMethod() {
    this.handleFullOpen = this.handleFullOpen.bind(this);
    this.handleFullClose = this.handleFullClose.bind(this);
    this.handleFullCancel = this.handleFullCancel.bind(this);
    this.modifyGuest = this.modifyGuest.bind(this);
    this.modifyGuestOpen = this.modifyGuestOpen.bind(this);
    this.modifyGuestClose = this.modifyGuestClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount = async () => {
    let res = await axios.get(`booking/${this.state.booking_key}`);
    // for (let i = 0; i < res.data.mixed_guest_details.length; i++) {
    //   temp1[i] = false;
    // }

    // let temp2 = [];
    // for (let i = 0; i < res.data.female_guest_details.length; i++) {
    //   temp2[i] = false;
    // }
    let temp = [];
    let temp1 = [];
    let temp2 = [];
    let isarr = res.data.guest_details instanceof Array;
    if (isarr) temp = res.data.guest_details;
    else {
      for (const type in res.data.guest_details) {
        for (let i = 0; i < res.data.guest_details[type].length; i++) {
          if (type === "Two Person Shared Tent") {
            temp1.push(res.data.guest_details[type][i]);
          } else if (type === "Tree House") {
            temp2.push(res.data.guest_details[type][i]);
          }
          temp.push(res.data.guest_details[type][i]);
        }
      }
    }
    this.setState({
      // to_cancel_mixed: temp1,
      // to_cancel_female: temp2,
      // mixed_details: res.data.mixed_guest_details,
      // female_details: res.data.female_guest_details,
      amount: res.data.amount,
      check_in: res.data.check_in,
      check_out: res.data.check_out,
      new_check_in: res.data.check_in,
      new_check_out: res.data.check_out,
      paid_percent: res.data.paid_percent,
      today_date: res.data.today_date,
      paid_amount: res.data.paid_amount,
      guest_details: res.data.guest_details,
      shared_tent_details: temp1,
      tree_house_details: temp2,
      bed_type: res.data.bed_type,
      paymentId: res.data.paymentId,
      // returnAmount:0
    });
  };

  //  Full Cancel Events

  handleFullOpen(event) {
    this.setState({ full_cancel: true });
  }

  handleFullClose(event) {
    this.setState({ full_cancel: false });
  }

  handleFullCancel(event) {
    var ramount = 0;
    if (this.daysCount(this.state.today_date, this.state.check_in) >= 4) {
      ramount = this.state.paid_amount - 35;
    }
    // alert(ramount);
    this.setState({ backdrop: true });
    axios
      .post(`${_serverurl}hotspot/${this.state.booking_key}`, {
        location_id: this.props.booking_key,
        check_in: this.state.check_in,
        check_out: this.state.check_out,
        guest_details: this.state.guest_details,
        paymentID: this.state.paymentId,
        returnAmount: ramount,
      })
      .then((res) => {
        if (res.data.message === "not found") alert("Not found");
        else if (res.data.message === "success") {
          // history.go(0);
          this.setState({
            full_cancel: false,
            backdrop: false,
            openDailogcancel: true,
            opensnackbar: true,
            snack_message: "Booking cancelled successfully",
            severity: "success",
          });
          // pdfsenderndownload(
          //   "email",
          //   res.data.booking_details,
          //   100,
          //   res.data.booking_details.phoneno,
          //   "cancel"
          // );
        }
      });
  }

  // Guest Modify Events

  modifyGuestOpen(event) {
    this.setState({ modify_guest: true });
  }

  modifyGuestClose(event) {
    this.setState({ modify_guest: false });
  }

  async modifyGuest(event) {
    event.preventDefault();
    this.setState({ backdrop: true });
    await axios.put(`hotspot/${this.state.booking_key}`, {
      guest_details: this.state.guest_details,
      user_id: auth.currentUser.uid,
    });
    this.setState(
      {
        opensnackbar: true,
        snack_message: "Details successfully modified",
        severity: "success",
      },
      () => {
        setTimeout(() => {
          // history.go(0);

          firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              {
                user.getIdToken().then(async (id) => {
                  const result = await this.props.userBookings(
                    id,
                    window.location.pathname.split("/")[2]
                  );
                  result === "DONE" && this.setState({ backdrop: false });
                });
              }
            } else {
              history.push("/login");
              this.setState({ backdrop: false });
            }
          });
        }, 1000);
      }
    );
    this.setState({
      modify_guest: false,
      modify_guest_confirmation: false,
      // backdrop: false,
    });
  }

  handleCheckChange(event) {
    let arr = event.target.id.split("_");
    if (arr[0] === "mixed") {
      if (event.target.checked === true) {
        let temp = this.state.to_cancel_mixed;
        temp[parseInt(arr[1])] = true;
        this.setState({ to_cancel_mixed: temp }, () => {
          // console.log(this.state.to_cancel_mixed);
        });
      } else {
        let temp = this.state.to_cancel_mixed;
        temp[parseInt(arr[1])] = false;
        this.setState({ to_cancel_mixed: temp }, () => {
          // console.log(this.state.to_cancel_mixed);
        });
      }
    } else {
      if (event.target.checked === true) {
        let temp = this.state.to_cancel_female;
        temp[parseInt(arr[1])] = true;
        this.setState({ to_cancel_female: temp }, () => {
          // console.log(this.state.to_cancel_female);
        });
      } else {
        let temp = this.state.to_cancel_female;
        temp[parseInt(arr[1])] = false;
        this.setState({ to_cancel_female: temp }, () => {
          // console.log(this.state.to_cancel_female);
        });
      }
    }
  }

  onsnackClose = () => {
    this.setState({
      opensnackbar: false,
      snack_message: "",
      severity: "success",
    });
  };

  handleChange(event) {
    let arr = event.target.name.split("_");
    let temp;
    arr[0] === "shared"
      ? (temp = this.state.shared_tent_details)
      : (temp = this.state.tree_house_details);
    if (arr[1] === "name" || arr[1] === "age") {
      temp[parseInt(arr[2])][arr[1]] = event.target.value;
      if (arr[0] === "shared") this.setState({ shared_tent_details: temp });
      else this.setState({ tree_house_details: temp });
    } else {
      temp[parseInt(arr[2])][arr[1]] = event.target.value;
      if (arr[0] === "shared") this.setState({ shared_tent_details: temp });
      else this.setState({ tree_house_details: temp });
    }
  }

  // Date Change Events

  daysCount(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  handleFromDateChange = (date) => {
    this.setState({ new_check_in: date.toISOString().slice(0, 10) });
  };

  handleToDateChange = (date) => {
    this.setState({ new_check_out: date.toISOString().slice(0, 10) });
  };

  handleDateChange(event) {
    axios
      .put(`booking/date/${this.state.booking_key}`, {
        amount:
          (this.state.amount /
            this.daysCount(this.state.check_in, this.state.check_out)) *
          this.daysCount(this.state.new_check_in, this.state.new_check_out),
        check_in: this.state.new_check_in,
        check_out: this.state.new_check_out,
      })
      .then((res) => {
        if (res.data.message === "success") {
          this.setState({
            modify_date: false,
            modify_date_confirmation: false,
          });
          alert("Your booking date has been changed successfully");
        } else {
          this.setState({
            modify_date: false,
            modify_date_confirmation: false,
          });
          alert("some error has occurred.\nPlease try again later");
        }
      })
      .catch((e) => {
        // console.log(e)
      });
  }

  details = (type, key) => {
    if (this.state.modify_guest) {
      let temp = [];
      for (let i = 0; i < this.state.guest_details[type].length; i++) {
        temp.push(
          <div
            key={i}
            className="guest_details mixed_details"
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "space-around",
              justifyContent: "space-between",
              overflow: "visible",
              // flexWrap: "wrap",
            }}
          >
            <TextField
              style={{ margin: "10px 5px 0 0", width: "63%" }}
              name={`name_${key}`}
              value={this.state.guest_details[type][i].name}
              onChange={(e) => {
                const regex = /[^a-zA-Z ]/gi;
                let temp = this.state.guest_details;
                if (e.target.value === "" || !regex.test(e.target.value)) {
                  temp[type][i].name = e.target.value;
                }
                this.setState({ guest_details: temp }, () => {});
              }}
              label="Name"
              type="text"
              required
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 25 }}
            />
            <TextField
              style={{ width: "25%", margin: "10px 10px 0 0" }}
              name={`age_${key}`}
              value={this.state.guest_details[type][i].age}
              onChange={(e) => {
                let temp = this.state.guest_details;
                temp[type][i].age = e.target.value;
                this.setState({ guest_details: temp }, () => {});
              }}
              label="Age"
              type="number"
              required
              variant="outlined"
              size="small"
              InputProps={{ inputProps: { min: 1, max: 99 } }}
            />
            <FormControl style={{ margin: "10px 10px 0 0", width: "12%" }}>
              {/* <InputLabel id="gender_label">Gender</InputLabel> */}
              <Select
                labelId="gender_label"
                name={`gender_${key}`}
                value={this.state.guest_details[type][i].gender}
                onChange={(e) => {
                  let temp = this.state.guest_details;
                  temp[type][i].gender = e.target.value;
                  this.setState({ guest_details: temp }, () => {});
                }}
                required
              >
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="F">F</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      }
      return temp;
    }
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Dialog open={this.state.openDailogcancel}>
          <DialogContent>
            Booking Has been successfully Canceled.
            {this.daysCount(this.state.today_date, this.state.check_in) >= 4
              ? "Amount will be refunded in 5-7 working days"
              : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                this.setState({ openDailogcancel: false });
                history.go(0);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop open={this.state.backdrop} style={{ zIndex: 100000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleFullOpen}
            style={{ fontSize: "10px" }}
          >
            Cancel
          </Button>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={this.state.full_cancel}
            onClose={this.handleFullClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="Notification"
          >
            <DialogTitle id="alert-dialog-title">
              {"Cancel Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.daysCount(this.state.today_date, this.state.check_in) >= 4
                  ? `You will be refunded ${this.state.paid_amount - 35}`
                  : `You will not get any refund.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleFullClose} color="primary">
                Disagree
              </Button>
              <Button onClick={this.handleFullCancel} color="secondary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.modifyGuestOpen}
            style={{ fontSize: "10px" }}
          >
            Modify
          </Button>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={this.state.modify_guest}
            onClose={this.modifyGuestClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="GuestDetails"
          >
            <DialogTitle id="alert-dialog-title">
              Modify Guest Details
            </DialogTitle>
            <DialogContent>
              <form onSubmit={this.modifyGuest}>
                {this.state.bed_type.map((type, key) => (
                  <div key={key}>{this.details(type, key)}</div>
                ))}

                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button onClick={this.modifyGuestClose} color="primary">
                    Disagree
                  </Button>
                  <Button type="submit" color="secondary">
                    Agree
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>

        {/* Guest Cancel and Modify Confirmation */}

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.modify_guest_confirmation}
          onClose={() => {
            this.setState({ modify_guest_confirmation: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="Notification"
        >
          <DialogTitle id="alert-dialog-title">
            {"Cancel Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ modify_guest_confirmation: false });
              }}
              color="primary"
            >
              Disagree
            </Button>
            <Button onClick={this.modifyGuest} color="secondary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        {/* Change Date */}
        {/*<div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.setState({ modify_date: true });
                    }}
                    style={{ fontSize: "10px" }}
                  >
                    Modify Date
                  </Button>
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.modify_date}
                    // onClose={this.handleFullClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Change Date Dialog"}
                    </DialogTitle>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid
                        style={{ width: "99%", margin: "auto" }}
                        container
                        spacing={3}
                      >
                        <Grid style={{ padding: "8px" }} item xs={6}>
                          <DatePicker
                            margin="normal"
                            id="date-picker-from"
                            label="From"
                            format="dd/MM/yyyy"
                            disablePast
                            value={this.state.new_check_in}
                            inputVariant="outlined"
                            allowKeyboardControl={false}
                            onChange={this.handleFromDateChange}
                            minDate={this.state.check_in}
                            maxDate={moment(this.state.check_out)
                              .subtract(1, "day")
                              .endOf("day")
                              .format("MM/DD/YYYY")}
                          />
                        </Grid>
                        <Grid style={{ padding: "8px" }} item xs={6}>
                          <DatePicker
                            margin="normal"
                            id="date-picker-to"
                            label="To"
                            format="dd/MM/yyyy"
                            value={this.state.new_check_out}
                            inputVariant="outlined"
                            minDate={moment(this.state.new_check_in)
                              .add(1, "day")
                              .endOf("day")
                              .format("MM/DD/YYYY")}
                            onChange={this.handleToDateChange}
                            maxDate={this.state.check_out}
                            // KeyboardButtonProps={{
                            //   "aria-label": "change date",
                            // }}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          this.setState({ modify_date: false });
                        }}
                        color="primary"
                      >
                        Disagree
                      </Button>
                      {this.state.check_in === this.state.new_check_in &&
                      this.state.check_out === this.state.new_check_out ? (
                        <Button disabled={true} color="secondary">
                          Agree
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({ modify_date_confirmation: true });
                          }}
                          color="secondary"
                        >
                          Agree
                        </Button>
                      )}
                    </DialogActions>
                  </Dialog>
                </div> */}

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.modify_date_confirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Date Change"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.paid_percent < 100
                ? `You will not get any refund because of partial payment.`
                : `You will be refunded ${
                    (((this.state.refund_percent / 100) *
                      this.state.paid_amount) /
                      this.daysCount(
                        this.state.check_in,
                        this.state.check_out
                      )) *
                    this.daysCount(
                      this.state.new_check_in,
                      this.state.new_check_out
                    )
                  }.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ modify_date_confirmation: false });
              }}
              color="primary"
            >
              Close
            </Button>
            <Button onClick={this.handleDateChange} color="secondary">
              Change Date
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          // severity="danger"
          open={this.state.opensnackbar}
          onClose={this.onsnackClose}
          //TransitionComponent="Grow"
          style={{
            //display: "none",
            marginBottom: "4rem",
          }}
        >
          <Alert
            variant="filled"
            elevation={6}
            style={{ width: "90vw" }}
            severity={this.state.severity}
            action={
              <IconButton
                aria-label="close"
                //color="inherit"
                size="small"
                onClick={this.onsnackClose}
              >
                <CloseIcon fontSize="inherit" style={{ color: "white" }} />
              </IconButton>
            }
          >
            {this.state.snack_message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    history: state.history,
  };
};

export default connect(mapStateToProps, { userBookings })(Cancel);
