import React from "react";
import axios from "../apis/axios";
import { auth } from "../firebase";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {
  date,
  update_available,
  mixedGuestDetails,
  femaleGuestDetails,
  mixedTempDetails,
  femaleTempDetails,
  fetchuserdata,
  userProfile,
  // updateHotspot,
  hotspotGuestDetails,
  bookingHotspot,
} from "../actions";
import Footer from "./static/footer";
import GuestDetails from "./static/GuestDetails";
import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
import { Snackbar, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import tick from "../assets/tick.png";
import Dialog from "@material-ui/core/Dialog";
import Backdrop from "@material-ui/core/Backdrop";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import { _razor_key } from "../keys/keys";
import { _serverurl } from "../keys/URLS";
import Navbar from "./static/navbar/navbarMui";
import { Divider } from "@material-ui/core";
import CheckOutSteps from "./static/checkoutSteps";
// import Backdrop from "@material-ui/core/Backdrop";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Hreview from "./static/Hreview";
import { pdfsenderndownload } from "./static/renderpdf";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});
class hsummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bed_type: [],
      pnr: "",
      popup: false,
      onprocess: false,
      currency: "INR",
      date_error: false,
      date_error_message: "",
      opensnackbar: false,
      snack_message: "",
      severity: "error",
      partial: false,
      partial_amount: 0,
      discount_amount: {},
      coupon_code: "",
      coupon_discount: {},
      no_of_nights: 0,
      amount: 0,
      applied_coupon: "",
      coupon_valid: false,
    };
  }
  componentDidMount = () => {
    // console.log(this.props);
    this.props.hotspotGuestDetails(
      JSON.parse(localStorage.getItem("guest_details"))
    );
    this.setState({
      bed_type: JSON.parse(localStorage.getItem("bed_type")),
    });
    this.setState({ onprocess: true });
    axios.get("/booking/partial").then((res) => {
      let d1 = new Date(localStorage.getItem("hotspot_check_in"));
      let d2 = new Date();
      let diff = Math.abs(d2 - d1);
      let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      let temp = {};
      temp["INR"] = 0;
      temp["USD"] = 0;
      temp["EUR"] = 0;
      if (diffDays >= res.data.days) {
        // console.log("insid");
        temp["INR"] = res.data.discount_amount;
        temp["USD"] = res.data.discount_amount_USD;
        temp["EUR"] = res.data.discount_amount_EUR;
        this.setState({
          partial: true,
          partial_amount: res.data.payPercent,
          discount_amount: temp,
          onprocess: false,
        });
      } else {
        this.setState({ onprocess: false, discount_amount: temp });
      }
    });
    this.setState({ amount: this.totalAmount() });
    this.setState({ no_of_nights: this.numberOfDays() })
  };

  componentDidUpdate() {
    localStorage.getItem("currency") &&
      this.state.currency !== localStorage.getItem("currency") &&
      this.setState({ currency: localStorage.getItem("currency") });
  }

  numberOfDays() {
    const date1 = new Date(localStorage.getItem("hotspot_check_in"));
    const date2 = new Date(localStorage.getItem("hotspot_check_out"));
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
  totalAmount = () => {
    const date1 = new Date(localStorage.getItem("hotspot_check_in"));
    const date2 = new Date(localStorage.getItem("hotspot_check_out"));
    const diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let price;
    if (this.state.currency === "INR") {
      price = JSON.parse(localStorage.getItem("price"));
    } else if (this.state.currency === "USD") {
      price = JSON.parse(localStorage.getItem("price_USD"));
    } else if (this.state.currency === "EUR") {
      price = JSON.parse(localStorage.getItem("price_EUR"));
    }
    let amount = 0;
    for (const key in this.props.guestDetails) {
      amount += price[key] * this.props.guestDetails[key].length;
    }
    amount = amount * diffDays;
    return amount + (amount * localStorage.getItem("tax")) / 100;
  };

  onsnackClose = () => {
    this.setState({
      opensnackbar: false,
      snack_message: "",
      severity: "error",
    });
  };

  couponCheck = async (event) => {
    this.setState({ onprocess: true });
    event.preventDefault();
    let res = await axios.post("/booking/coupon", {
      coupon_code: this.state.coupon_code,
      uid: auth.currentUser.uid,
    });
    if (res.data.discount === 0) {
      alert("Invalid Coupon Code");
      this.setState({
        onprocess: false,
        opensnackbar: true,
        snack_message: "Invalid Coupon Code",
        severity: "error",
        coupon_discount: 0,
      });
      this.setState({
        applied_coupon: this.state.coupon_code,
        coupon_valid: false,
      });
      // this.setState({ coupon_discount: 0 });
    } else {
     // console.log("test "+res.data.discount);
    //   const newDate = new Date().toJSON().split("-");
    //   const newDate1 = new Date();
    // const n1 = new Date(localStorage.getItem("hotspot_check_in")).toJSON().split("-");
    // console.log(newDate);
    // console.log(newDate1);
    // console.log(n1);
      if (typeof res.data.discount_amount === "string") {
        // alert("string "+res.data.discount_amount);
        let temp = res.data.discount_amount.slice(
          0,
          res.data.discount_amount.length - 1
        );
        //alert(temp);
        let percent = parseInt(temp);
        let discount_amount = {};
        discount_amount["INR"] = (this.totalAmount() * percent) / 100;
        discount_amount["USD"] = (this.totalAmount() * percent) / 100;
        discount_amount["EUR"] = (this.totalAmount() * percent) / 100;
        this.setState({
          coupon_discount: discount_amount,
          onprocess: false,
          opensnackbar: true,
          snack_message: "Coupon Applied",
          severity: "success",
        });
      } else {
        this.setState({
          coupon_discount: res.data.discount_amount,
          onprocess: false,
          opensnackbar: true,
          snack_message: "Coupon Applied",
          severity: "success",
        });
      }
      this.setState({
        applied_coupon: this.state.coupon_code,
        coupon_valid: true,
      });
    }
  };

  loadScript = async (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  getUnitPrice = (price) => {
    switch (price) {
      case "INR":
        return JSON.parse(localStorage.getItem("price"));
      // break;
      case "USD":
        return JSON.parse(localStorage.getItem("price_USD"));
      // break;
      case "EUR":
        return JSON.parse(localStorage.getItem("price_EUR"));
      // break;
      default:
        return localStorage.getItem("price");
    }
  };

  getSymbol = () => {
    if (this.state.currency === "INR") return "₹";
    else if (this.state.currency === "USD") return "$";
    else if (this.state.currency === "EUR") return "€";
  };

  handlePay = async (event, partial) => {
    this.setState({ onprocess: true });

    // this.setState({ onprocess: true });
    if (auth.currentUser === null) {
      this.setState({
        onprocess: false,
        snack_message: "Please Login in continue booking",
        opensnackbar: true,
        severity: "error",
      });
      return setTimeout(() => {
        this.props.history.push({
          pathname: "/login",
          state: {
            next: "hotspot",
            prev: "hotspot",
          },
        });
      }, 3000);
    }
    var res =null
    if (!this.props.user.hasOwnProperty("profile")) {
      // console.log("pushong");
      var IDToken;
      await auth.currentUser.getIdToken().then(async(id) => {
        // console.log(id);
        // IDToken = id;
         res = await this.props.userProfile(id);
         if (res.hasOwnProperty("error")) {
           // alert(res.error);
           this.setState({ onprocess: false });
           return this.setState({
             onprocess: false,
             snack_message: res.error,
             opensnackbar: true,
             severity: "error",
           });
         }
      });
      // console.log(IDToken);
    
     
    } else {
      res = this.props.user.profile;
    }
    // console.log(res,this.props.user);
    const userdata = {
      name: res.name || "",
      email: res.email || "",
      contact: res.phone || "",
    };
    const rzwindow = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    // console.log(rzwindow);
    if (!rzwindow) {
      this.setState({
        onprocess: false,
        snack_message: "Unable to open Payment Window. Network Error",
        opensnackbar: true,
        severity: "error",
      });
      return;
    }

    //const newDate = new Date().toJSON().split("-");
    const newDate = new Date(localStorage.getItem("hotspot_check_in")).toJSON().split("-");
    //console.log(newDate);
    //console.log("n1"+n1);
    let pay_amount = this.totalAmount();
    if (this.state.coupon_discount[this.state.currency] !== undefined)
      pay_amount =
        this.totalAmount() - this.state.coupon_discount[this.state.currency];

    if (partial === "partial") {
      pay_amount = (this.state.partial_amount * pay_amount) / 100;
    }

    if (this.state.partial && partial !== "partial") {
      if (this.state.coupon_discount[this.state.currency] !== undefined)
        this.setState({
          amount:
            this.totalAmount() -
            this.state.discount_amount[this.state.currency] -
            this.state.coupon_discount[this.state.currency],
        });
      else
        this.setState({
          amount:
            this.totalAmount() -
            this.state.discount_amount[this.state.currency],
        });
      pay_amount = pay_amount - this.state.discount_amount[this.state.currency];
    } else {
      if (this.state.coupon_discount[this.state.currency] !== undefined)
        this.setState({
          amount:
            this.totalAmount() -
            this.state.coupon_discount[this.state.currency],
        });
    }

    pay_amount = Math.ceil(pay_amount);

    // const booking_details = null; /// setting booking details to null
    const order = {
      /// creating a order to send to backend server for creating an id
      receipt:
        "IN" +
        newDate[2].slice(0, 2) +
        newDate[1] +
        newDate[0].slice(2) +
        localStorage.getItem("hotspot_location_id").slice(0, 3) +
        Math.random().toString(36).slice(-4).toLocaleUpperCase(),
      currency: this.state.currency,
      orderamount: pay_amount,
      userId: auth.currentUser.uid,
    };
    // const resy = await this.props.bookingHotspot(order, booking_details); /// sending to server to create order
    // call above with booking_details below is for testing directly
    const resy = await axios
      .post(_serverurl + "payment/payments", order)
      .catch((e) => {
        // console.log(e);
      });

    if (!resy) {
      //  this.props.history.push("/");
      this.setState({
        onprocess: false,
        snack_message: "Network Error! Failed get Payment Details",
        opensnackbar: true,
        severity: "error",
      });
      return this.setState({ onprocess: false });
    }

    const { amount, id, currency } = resy.data; /// getting order ID from server
    const options = {
      ///// payment windows config {Razorpay}
      key: _razor_key, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "bunkbread",
      description: "booking Hotspot",
      order_id: id,
      handler: async (response) => {
        // alert(response.razorpay_payment_id);
        const data = {
          orderCreationId: id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          useruid: auth.currentUser.uid,
          receipt: order.receipt,
        };
        // sending signatures and id,payid to backened to check the if payment was successfull

        const result = await axios.post(
          /// sending data to backened to verfiy if payment is successfull
          _serverurl + "payment/payments/success",
          data
        );
        this.setState({ pnr: "PH" + order.receipt.slice(2) });
        let hotspot_booking = {
          location_id: localStorage.getItem("hotspot_location_id"),
          currency: this.state.currency,
          check_in: localStorage.getItem("hotspot_check_in"),
          check_out: localStorage.getItem("hotspot_check_out"),
          bed_type: this.state.bed_type,
          guest_details: this.props.guestDetails,
          booked_at: new Date().toUTCString(),
          orderId: result.data.orderId,
          paymentId: result.data.paymentId,
          gst: localStorage.getItem("tax"),
          amount: this.state.amount,
          user_id: auth.currentUser.uid,
          paid_amount: pay_amount,
          email: userdata.email,
          receipt: order.receipt,
          pnr: this.state.pnr,
          booking_type: "hotspot",
          name: userdata.name,
          price: this.getUnitPrice(this.state.currency),
          location: localStorage.getItem("hotspot_location"),
          coupon_code: this.state.coupon_code,
          coupon_discount: this.state.coupon_discount,
          partial: false,
          paid_percent: 100,
          review: false,
          phone_no: userdata.contact,
        };
        if (partial === "partial") {
          hotspot_booking = {
            ...hotspot_booking,
            partial: true,
            paid_percent: this.state.partial_amount,
            paid_amount: pay_amount,
          };
        }
        // console.log(hotspot_booking);
        if (result.data.message === "success") {
          let res = await axios.post("/hotspot/booking", hotspot_booking);
          if (res.data.message === "success" ) {
            pdfsenderndownload(
              "email",
              hotspot_booking,
              hotspot_booking.partial,
              userdata.contact,
              "confirm"
            );
            this.setState({ popup: true, pnr: res.data.pnr });
            localStorage.removeItem("notifyBookdata");
          } else if (res.data.message === "date_error") {
            this.setState({
              date_error: true,
              date_error_message: res.data.body,
            });
          } else {
            // alert(res.data.comment)
            this.setState({
              date_error: true,
              date_error_message: res.data.body,
              onprocess: false,
              snack_message: res.data.body,
              opensnackbar: true,
              severity: "error",
            });
          }
        } else {
          this.setState({
            onprocess: false,
            snack_message: "Payment Failed. Try Again",
            opensnackbar: true,
            severity: "error",
          });
        }
      },
      modal: {
        /// things to happen on razorpay window close
        ondismiss: () => {
          /// remove the razoray window from code on close
          // console.log("Checkout form Closed");
          const v = document.getElementsByClassName("razorpay-container");
          // console.log(v);
          v[v.length - 1].style.display = "none";
          this.setState({ onprocess: false });
        },
      },
      prefill: {
        name: userdata.name,
        email: userdata.email,
        contact: userdata.contact,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#ffde17",
      },
    };
    const paymentObject = new window.Razorpay(options); //creating the windows using above "options" config

    paymentObject.open(); /// opening the window
    // paymentObject.on("payment.failed", function (response) {
    //   alert(response.error.code);
    //   alert(response.error.description);
    //   alert(response.error.source);
    //   alert(response.error.step);
    //   alert(response.error.reason);
    //   alert(response.error.metadata.order_id);
    //   alert(response.error.metadata.payment_id);
    //   // rzwindow = null
    //   // const v = document.getElementsByClassName("razorpay-container")
    // });
  };

    changeDateFormat = (date) => {
    var d = new Date(date);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();
    curr_date = curr_date.toString();
    curr_month = curr_month.toString();
    curr_date.length === 1 ? curr_date = "0" + curr_date : curr_date = curr_date;
    curr_month.length === 1 ? curr_month = "0" + curr_month : curr_month = curr_month;
    return(curr_date + "-" + curr_month + "-" + curr_year);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Navbar />
        <Backdrop className={classes.backdrop} open={this.state.onprocess}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {this.state.popup ? (
          <CheckOutSteps step1 step1C step2 step2C step3 step3C />
        ) : (
          <CheckOutSteps step1 step1C step2 />
        )}
        <Divider style={{ margin: "8px 8px 20px" }} />
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          // severity="danger"
          open={this.state.opensnackbar}
          onClose={this.onsnackClose}
          //TransitionComponent="Grow"
          style={{
            //display: "none",
            marginBottom: "4rem",
          }}
        >
          <Alert
            variant="filled"
            elevation={6}
            style={{ width: "90vw" }}
            severity={this.state.severity}
            action={
              <IconButton
                aria-label="close"
                //color="inherit"
                size="small"
                onClick={this.onsnackClose}
              >
                <CloseIcon fontSize="inherit" style={{ color: "white" }} />
              </IconButton>
            }
          >
            {this.state.snack_message}
          </Alert>
        </Snackbar>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "15px 8px 15px",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              fontSize: "20px",
              margin: "5px 0 0",
              fontFamily: "Poppins",
            }}
          >
            Review Details
          </h3>
        </div>

        <Dialog
          open={this.state.date_error}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => this.setState({ date_error: false })}
        >
          <DialogContent>{this.state.date_error_message}</DialogContent>
        </Dialog>
        <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            Destination :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            {localStorage.getItem("hotspot_location")}
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            Check In Date :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            {this.changeDateFormat(localStorage.getItem("hotspot_check_in"))}
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                            Check Out Date :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                                }}
                      >
                           {this.changeDateFormat(localStorage.getItem("hotspot_check_out"))}
                      </Typography>
                    </Grid>
                    </Grid>
        {/* <div style={{ margin: "8px" }} className="booking_details">
          <div style={{ fontFamily: "Poppins", fontSize: "16px" }}>
            <span
              style={{
                fontFamily: "Poppins",
              }}
            >
              Destination:&nbsp;
            </span>
            {localStorage.getItem("hotspot_location")}
          </div>
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              margin: "8px 0",
            }}
          >
            <span style={{ fontFamily: "Poppins" }}>
              Check In Date:&nbsp;
            </span>
            {this.changeDateFormat(localStorage.getItem("hotspot_check_in"))}
          </div>
          <div style={{ fontSize: "16px", fontFamily: "Poppins" }}>
            <span style={{ fontFamily: "Poppins" }}>
              Check Out Date:&nbsp;
            </span>
            {this.changeDateFormat(localStorage.getItem("hotspot_check_out"))}
          </div>
        </div> */}

        {this.state.bed_type.map(
          (type, key) =>
            this.props.guestDetails[type].length > 0 && (
              <Paper
                key={key}
                style={{ margin: "10px 8px", padding: "0 0 20px" }}
              >
                <React.Fragment>
                  <Hreview
                    name={type}
                    beds_count={this.props.guestDetails[type].length}
                    tariff={`${this.getSymbol()} ${
                      this.getUnitPrice(this.state.currency)[type]
                    }`}
                    total={`${this.getSymbol()} ${this.totalAmount()}`}
                    nights={this.state.no_of_nights}
                  />

                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              borderTop: "none",
                              paddingBottom: "0",
                              width: "50%",
                              fontWeight: "600",
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: "none",
                              paddingBottom: "0",
                              width: "25%",
                              fontWeight: "600",
                            }}
                            align="left"
                          >
                            Age
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: "none",
                              paddingBottom: "0",
                              fontWeight: "600",
                            }}
                            align="left"
                          >
                            Gender
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </React.Fragment>

                {this.props.guestDetails[type].map((detail, key) => (
                  <GuestDetails
                    key={key}
                    name={detail.name}
                    age={detail.age}
                    gender={detail.gender}
                  />
                ))}
              </Paper>
            )
        )}
<Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography
                          color="textPrimary"
                          style={{
                            marginLeft: "10px",
                            fontWeight: "600",
                          fontFamily: "Poppins",
                          fontSize: "20px",
                                }}
                      >
                            Total Amount :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "20px",
                                }}
                      >
                           {this.getSymbol()} {this.totalAmount()}
                      </Typography>
                    </Grid>
                    </Grid>
        {/* <h2
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            fontSize: "20px",
            fontFamily: "Poppins",
          }}
        >
          Total Amount :&nbsp;
          <span style={{ fontFamily: "Poppins" }}>
            {this.getSymbol()} {this.totalAmount()}
          </span>
        </h2> */}

        <form
          className="coupon_form"
          onSubmit={this.couponCheck}
          autoComplete="off"
          style={{ textAlign: "center", margin: "10px 8px" }}
        >
          <TextField
            required
            onChange={(e) => {
              if (e.target.value.match(/[a-z]/i))
                e.target.value = e.target.value.toUpperCase();
              this.setState({ coupon_code: e.target.value });
            }}
            name="coupon_code"
            id="coupon"
            label="Coupon Code"
            variant="outlined"
            size="small"
            style={{ width: "60%" }}
          />
          <Button
            style={{
              background: "#ffde17",
              fontFamily: "Poppins",
              width: "40%",
              marginLeft: "10px",
              height: "38px",
            }}
            variant="contained"
            type="submit"
          >
            Apply
          </Button>
        </form>

        {this.state.applied_coupon !== "" && (
          <Chip
            style={{
              marginLeft: "10px",
            }}
            variant="outlined"
            label={
              this.state.coupon_valid
                ? `Applied ${this.state.applied_coupon}`
                : `Invalid Coupon ${this.state.applied_coupon}`
            }
            size="small"
            color={this.state.coupon_valid ? "primary" : "secondary"}
          ></Chip>
        )}
<Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography
                          color="textPrimary"
                          style={{
                            marginLeft: "10px",
                            fontWeight: "600",
                          fontFamily: "Poppins",
                          fontSize: "20px",
                                }}
                      >
                            Net Amount :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "20px",
                                }}
                      >
                           {this.state.coupon_discount[this.state.currency] !== undefined
              ? `${this.getSymbol()} ${Math.ceil(
                  this.totalAmount() -
                  this.state.coupon_discount[this.state.currency]
              )}`
              : `${this.getSymbol()} ${Math.ceil(this.totalAmount())} `}
                      </Typography>
                    </Grid>
                    </Grid>
        {/* <h2
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            fontSize: "20px",
            fontFamily: "Poppins",
          }}
        >
          Net Amount :&nbsp;
          <span style={{ fontFamily: "Poppins" }}>
            {this.state.coupon_discount[this.state.currency] !== undefined
              ? `${this.getSymbol()} ${
                  this.totalAmount() -
                  this.state.coupon_discount[this.state.currency]
                }`
              : `${this.getSymbol()} ${this.totalAmount()} `}
          </span>
        </h2> */}

        {this.state.partial && (
          <>
            <div
              style={{
                fontSize: "12px",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >
              Pay {this.state.partial_amount}% to confirm
            </div>
            <div style={{ textAlign: "center", margin: "15px 0 15px" }}>
              <Button
                variant="contained"
                style={{
                  background: "#ffde17",
                  fontFamily: "Poppins",
                  width: "95%",
                }}
                onClick={(event) => this.handlePay(event, "partial")}
                id="partial"
                className="summary_pay"
                disabled={this.state.onprocess}
              >
                PAY &nbsp;
                {this.state.coupon_discount[this.state.currency] !== undefined
                  ? `${this.getSymbol()} ${Math.ceil(
                      ((this.totalAmount() -
                        this.state.coupon_discount[this.state.currency]) *
                        this.state.partial_amount) /
                      100
                  )}`
                  : `${this.getSymbol()} ${Math.ceil(
                      (this.totalAmount() * this.state.partial_amount) / 100
                  )} `}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <span style={{ margin: "auto", width: "44%", marginLeft: "2%" }}>
                <Divider />
              </span>
              or
              <span style={{ margin: "auto", width: "44%", marginRight: "2%" }}>
                <Divider />
              </span>
            </div>

            <div
              style={{
                fontSize: "12px",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >
              Pay 100% to get instant discount of {this.getSymbol()}
              {this.state.discount_amount[this.state.currency]}
            </div>
          </>
        )}

        <div style={{ textAlign: "center", margin: "15px 0 30px" }}>
          {this.state.partial ? (
            <Button
              variant="contained"
              style={{
                background: "#ffde17",
                fontFamily: "Poppins",
                width: "95%",
              }}
              onClick={this.handlePay}
              className="summary_pay"
              disabled={this.state.onprocess}
            >
              PAY &nbsp;
              {this.state.coupon_discount[this.state.currency] !== undefined
                ? `${this.getSymbol()} ${Math.ceil(
                    this.totalAmount() -
                    this.state.coupon_discount[this.state.currency] -
                    this.state.discount_amount[this.state.currency]
                )} `
                : `${this.getSymbol()} ${Math.ceil(
                    this.totalAmount() -
                    this.state.discount_amount[this.state.currency]
                )}`}
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{
                background: "#ffde17",
                fontFamily: "Poppins",
                width: "95%",
              }}
              onClick={this.handlePay}
              className="summary_pay"
              disabled={this.state.onprocess}
            >
              PAY &nbsp;
              {this.state.coupon_discount[this.state.currency] !== undefined
                ? `${this.getSymbol()} ${Math.ceil(
                    this.totalAmount() -
                    this.state.coupon_discount[this.state.currency]
                )}`
                : `${this.getSymbol()} ${Math.ceil(this.totalAmount())} `}
            </Button>
          )}
        </div>

        {this.state.popup ? (
          <div>
            <Dialog
              className="PaymentSuccess"
              open={this.state.popup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <img style={{ width: "120px", margin: "auto" }} src={tick} />

              <DialogTitle
                style={{ fontSize: "25px", padding: "0", textAlign: "center" }}
                id="alert-dialog-title"
              >
                Congratulations
              </DialogTitle>
              <DialogTitle
                className="paymentText"
                style={{ textAlign: "center" }}
                id="alert-dialog-title"
                style={{ padding: "0", textAlign: "center" }}
              >
                Booking completed
                {/* <br />
                You recieve a email shortly! */}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  style={{
                    fontSize: "12px",
                    padding: "0",
                    textAlign: "center",
                    margin: "15px 0 0",
                  }}
                  id="alert-dialog-description"
                >
                  <br />
                  <span style={{ fontSize: "14px" }}>
                    PNR:&nbsp;
                    {this.state.pnr.toUpperCase()}
                  </span>
                  <br />
                  You will recieve a reciept in your email shortly
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{ display: "flex", justifyContent: "center" }}
                className="after_button"
              >
                <Button
                  style={{
                    width: "95%",
                    padding: "8px 0",
                    fontFamily: "Poppins",
                    background: "#ffde17",
                    color: "black",
                  }}
                  variant="contained"
                  onClick={() => this.props.history.push("/")}
                  color="secondary"
                >
                  <Link to="/">Home</Link>
                </Button>
                {/* <Button
                  style={{
                    width: "50%",
                    padding: "8px 0",
                    fontFamily: "Poppins",
                    background: "#ffde17",
                    color: "black",
                  }}
                  variant="contained"
                  onClick={() => this.props.history.push("/hotspot")}
                  color="secondary"
                >
                  Another Booking
                </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          ""
        )}
        <Footer Workpad_none />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    avail: state.availReducer,
    availableNow: state.availableNow,
    mixed_details: state.mixedGuestDetails,
    female_details: state.femaleGuestDetails,
    mixed_temp_details: state.mixedTempDetails,
    female_temp_details: state.femaleTempDetails,
    user: state.userProfile,
    currency: state.currency,
    availableHotspot: state.availableHotspot,
    guestDetails: state.hotspotGuestDetails,
  };
};

const mapDispatchToProps = () => {
  return {
    date,
    update_available,
    mixedGuestDetails,
    femaleGuestDetails,
    mixedTempDetails,
    femaleTempDetails,
    fetchuserdata,
    userProfile,
    hotspotGuestDetails,
    bookingHotspot,
  };
};
const styles = withStyles(useStyles)(hsummary);
export default connect(mapStateToProps, mapDispatchToProps())(styles);
