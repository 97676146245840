import React from "react";

class TOU extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          PLEASE READ THESE TERMS AND CONDITIONS OF USE (“Terms and Conditions”)
          CAREFULLY BEFORE USING THIS SITE
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Introduction
        </div>
        <div>
          By using, accessing, viewing, transmitting, caching, storing or
          otherwise utilizing any of the services, contents, or functions of
          bunkbread.com you signify your consent to these terms and conditions
          as set forth herein. The terms and conditions set out below apply to
          your use of this web site and related web sites (“Site”), including
          the use of the information services offered on the Site. In accessing
          and using the Site, you agree to be bound by these Conditions so
          please carefully read this section before proceeding. If you do not
          agree to these terms of use, you must refrain from using the Site. We
          reserve the right, at our discretion, to change, modify, add, or
          remove portions of these terms at any time. Please check these terms
          periodically for changes. All changes will become effective
          immediately unless otherwise stated and you should check these terms
          for changes from time to time.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          Conditions
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          1. Use of Website
        </div>
        <div>
          The site is available only to individuals and entities that can form
          legally binding contracts under applicable law. The Site and the
          materials located on or through the Site are provided by us for
          informational purposes only, with the understanding that we are by the
          provision of these materials not engaged in the rendering of legal or
          other professional advice or service. Commercial use of this site is
          strictly prohibited. You may only use this site to make legitimate
          reservations or purchases. You are responsible for maintaining the
          secrecy of your login and account information. You will be financially
          accountable for all uses of the site by you and anyone using your
          login information. The information contained in or through the Site is
          based upon sources believed to be accurate and reliable; and we have
          exercised reasonable care to assure the accuracy of the information.
          However, we make no representation or warranty as to such accuracy.
          These materials were prepared for us by personnel of our owned and
          managed hostels and others. We do not guarantee that the Site will
          operate continuously or without interruption or be error free. In
          addition, We may also suspend or discontinue any aspect of the Site at
          any time without being liable for any direct or indirect loss as a
          result of such action.
        </div>

        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          2. Proprietary Rights Information
        </div>
        <div>
          The Site contains and references of domain name, trademarks, logos,
          brand names, service marks, drawings, illustrations, artwork, videos,
          music, sounds, photographs, trade names, text, graphics, trade dress
          and devices and other valuable trade or service marks or files found
          on this site are proprietary to bunkbread.com are protected by the
          trademark, copyright and intellectual property laws. You may not frame
          or utilize framing techniques to modify, reproduce, sell, broadcast or
          copy any way whatsoever or in any form whatsoever, either partially or
          fully any of our trademarks, logos, or proprietary information without
          the express prior written consent of bunkbread.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          3. Restrictions on use of Materials
        </div>
        <div>
          All materials contained in the Site are the copyrighted property of
          bunkbread. Any software that is made available to download from this
          Site is the copyrighted work of bunkbread. Your use of the Software is
          governed by the terms of the end user license agreement, if any, which
          accompanies or is included with the Software. You may not install or
          use any Software that is accompanied by or includes a License
          Agreement unless You first agree to the License Agreement terms. No
          material from the Site or any Internet site owned, operated, licensed,
          or controlled by us or our affiliates may be copied, reproduced,
          republished, uploaded, posted, transmitted, or distributed in any way.
          For purposes of these terms, the use of any such material on any other
          Web, except that you may download one copy of the materials on any
          single computer for your personal, noncommercial home use only,
          provided you keep intact all copyright and other proprietary notices.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          4. Submissions
        </div>
        <div>
          Any messages, notes, suggestions, ideas, notes, drawings, concepts or
          other material or information (collectively, the “Submissions”), which
          you may submit through or in connection with the Site will become the
          exclusive property of us. Accordingly, you agree that we have the
          right to publish any Submissions for any purpose whatsoever,
          commercial or otherwise, without compensation to the provider of the
          Submissions. In addition, you agree that you will not transmit any
          Submission which infringes upon the rights of any third party.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          5. Promotional Information
        </div>
        <div>
          We may make information regarding specific programs, offers or
          promotions, which we are conducting, available on the Sites. Any such
          program, offer or promotion is subject to the specific terms,
          conditions and restrictions listed on the Site in connection with such
          program, offer or promotion. We reserve the right to alter or withdraw
          any program, offer or promotion at any such time. Each Program, offer
          and promotion is void where prohibited by law. Please refer to and
          read carefully the terms, conditions and restrictions included on the
          Sites in connections with each program, offer or promotion.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          6. Links
        </div>
        <div>
          We do not review or monitor web sites linked to this site and are not
          responsible for the content of any such linked web sites. By providing
          these links, we are not endorsing, sponsoring or recommending such
          sites or the materials disseminated by or services provided by them,
          and are not responsible for the materials, services or other
          situations at or related to or from any other site. Your linking to
          such web sites is at your own risk. In addition, you agree not to link
          your web site or any other third party web site to any page of this
          site other than the brand home page or the specific hotel home page
          (commonly known as “deep linking”) without our prior express written
          consent. We reserve the right to disable any and all links from third
          party sites to this site at any time. These other sites may send their
          own cookies to users, collect data, solicit personal information, or
          contain information that you may find inappropriate or offensive. In
          addition, advertisers on the Site may send cookies to users that we do
          not control. We do not make or give any representation or warranty
          (express or implied) of any kind as to any matter relating to the Site
          and any Linked Web Site, including without limitation, as to
          merchantability, non infringement of intellectual property rights or
          fitness for purpose.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          7. Access and Interference
        </div>
        <div>
          You will not use any robot, spider, other automatic device, or manual
          process to monitor or copy the Site or the contents or information
          (including the Information) contained therein without our prior
          express written consent. You agree that you will not use any device,
          software or routine to interfere or attempt to interfere with the
          proper working of the Site or any transaction being conducted through
          the Site. You agree that you will not copy, reproduce, alter, modify,
          create derivative works, or publicly display any content (except for
          any Information in which you have an ownership interest) from the Site
          without our prior express written consent or the appropriate third
          party. The information (including the Information) you provide to us
          (i) shall not contain any viruses, Trojan horses, worms, time bombs,
          cancelbots or other computer programming routines that are intended to
          damage, detrimentally interfere with, surreptitiously intercept or
          expropriate any system, data or information; and (ii) shall not create
          liability for us or cause us to lose (in whole or in part) the
          services of our ISPs or other suppliers.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          8. Limitation of Liability
        </div>
        <div>
          Neither us nor any of our subsidiaries, affiliates, agents,
          representatives or licensors shall be responsible for any direct,
          indirect, special, incidental or consequential loss or damage,
          punitive or similar damages including without limitation, loss or
          damage by way of loss of profits, loss of business opportunity,
          business interruption or loss of information however arising and
          whether in contract, tort or otherwise, which you or anyone else may
          suffer in connection with or arising out of, however arising and
          whether in contract, tort or otherwise, which you may suffer in
          connection with or arising out of your access or use or your inability
          to access or use of the Site or any Linked Website; or your use of or
          reliance on Information or any other material accessed through the
          Site including via any Linked Web Site; These Conditions do not
          purport to exclude liability arising by any applicable law if, and to
          the extent, such liability cannot be lawfully excluded, however, to
          the extent permitted by law, all warranties, terms or conditions which
          would otherwise be implied into these Conditions are hereby excluded.
          Where applicable law implies any warranty, term or condition, and that
          law prohibits exclusion or modification of the application of or the
          liability of bunkbread under, any such warranty, term or condition,
          then the liability of bunkbread shall include liability for that
          warranty, term or condition but such liability will be limited for a
          breach of that warranty, term or condition, if the breach relates to
          services, the supply of the services again or payment of the cost of
          having the services supplied again; and if the breach relates to
          products and the products can be repaired, repair of such products or
          payment of the costs of having the products repaired, but otherwise,
          replacement of the products or the supply of equivalent products or
          payment of the cost of replacing the products or of acquiring
          equivalent products. We are not responsible for, and shall have no
          liability for: (i) telephone, mobile, electronic,network, internet,
          computer, hardware or software program malfunctions, delays, or
          failures; (ii) for any injury, loss or damage to your computer or
          interception or use of credit card information, related to or
          resulting from use of the site or any sites, services, or materials
          linked or relating thereto; (iii) any incorrect information, whether
          caused by other users or by any of the equipment, software, or
          programming associated with or utilized in the site or as the result
          of technical or human error which may occur in the processing of
          information regarding the site.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          9. Disclaimers
        </div>
        <div>
          ELECTRONIC TRANSMISSIONS, INCLUDING THE INTERNET, ARE PUBLIC MEDIA,
          AND ANY USE OF SUCH MEDIA IS PUBLIC AND NOT PRIVATE. INFORMATION
          RELATED TO OR ARISING FROM SUCH USE IS PUBLIC, OR THE PROPERTY OF
          THOSE COLLECTING INFORMATION, AND NOT PERSONAL OR PRIVATE INFORMATION.
          YOU AGREE THAT YOU USE THE SITE AT YOUR OWN RISK. THE CONTENT,
          SERVICES AND MATERIALS IN THE SITE ARE PROVIDED "AS IS" AND ON AN "AS
          AVAILABLE" BASIS WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND
          EXPRESS, IMPLIED OR STATUTORY. WE DO NOT MAKE ANY REPRESENTATIONS,
          WARRANTIES OR ENDORSEMENTS REGARDING THE ACCURACY, RELIABILITY,
          USEFULNESS OR COMPLETENESS OF THE SERVICES, CONTENT OR MATERIALS IN
          THE SITE OR ANY SITE LINKED TO IT. TO THE MAXIMUM EXTENT PERMISSIBLE
          PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
          NONINFRINGEMENT, DESIGN, ACCURACY, CAPABILITY, SUFFICIENCY,
          SUITABILITY, CAPACITY, COMPLETENESS, AVAILABILITY, COMPATIBILITY OR
          ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DO NOT
          WARRANT THAT THE SITE OR THE SERVICES, CONTENT, MATERIALS OR FUNCTIONS
          CONTAINED IN THE SITE WILL BE CONTINUOUSLY AVAILABLE, UNINTERRUPTED OR
          ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE,
          SERVICES, CONTENT, MATERIALS OR THE SERVERS THAT MAKE THE SITE OR SUCH
          SERVICES, CONTENT AND MATERIALS AVAILABLE ARE FREE OF VIRUSES OR OTHER
          HARMFUL COMPONENTS OR ARE ACCURATE OR COMPLETE. WE DO NOT WARRANT OR
          MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE
          OF THE SERVICES, CONTENT, MATERIALS, FUNCTIONS OR PRODUCTS AVAILABLE
          THROUGH THE SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY,
          OR OTHERWISE. YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING,
          REPAIR, OR CORRECTION. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF
          IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. In
          the event we are held liable for any damages related to such matters,
          your sole and exclusive remedy will be limited to reimbursement for
          services or products paid by you to the entity held liable which were
          not provided by such an entity. You hereby waive any and all rights to
          bring any claim or action related to such matters in any forum beyond
          one (1) year after the first occurrence of the kind of act, event,
          condition or omission upon which the claim or action is based. We
          explicitly disclaim any responsibility for the accuracy, content, or
          availability of information found on sites that link to or from the
          Site. We cannot ensure that you will be satisfied with any products or
          services that you purchase from the Site or from a third-party site
          that links to or from the Site or third party content on the Site. We
          do not endorse any of the merchandise, nor have we taken any steps to
          confirm the accuracy or reliability of, any of the information
          contained in such third-party sites or content. We do not make any
          representations or warranties as to the security of any information
          (including, without limitation, credit card and other personal
          information) you might be requested to give any third party, and you
          hereby irrevocably waive any claim against the Indemnified Parties
          with respect to such sites and third party content. We strongly
          encourage you to make whatever investigation you feel necessary or
          appropriate before proceeding with any online or offline transaction
          with any of these third parties. The Indemnified Parties are not
          responsible for telephone, electric, electronic, network, Internet,
          computer, hardware or software program malfunctions, failures, delays
          or difficulties, or late, lost, stolen, illegible, incomplete,
          garbled, misdirected, mutilated or postage due mail, e-mail, form
          postings, connections, messages or entries, or the security of any and
          all such matters. Further, the Indemnified Parties are not responsible
          for incorrect or inaccurate entry information, whether caused by
          Internet users or by any of the equipment or programming associated
          with or utilized in the Site or by any technical or human error which
          may occur in the processing of any information related to the Site. If
          for any reason any portion of the Site is not capable of running as
          planned, including infection by computer virus, bugs, tampering,
          unauthorized intervention, fraud, technical failures, or any other
          causes beyond the reasonable control of HWI which corrupt or affect
          the administration, security, fairness, integrity, or proper conduct
          of the Site, we reserve the right (but not the obligation) in our sole
          and absolute discretion, to prohibit you and any member, buyer or
          supplier (and all of your and their Information) from using the Site,
          and to cancel, terminate, modify or suspend the Site or any portion
          thereof and void such Information. You also agree that the Indemnified
          Parties are not responsible or liable in any way for injury, loss or
          damage to your computer or interception or use of credit card
          information, related to or resulting from use of the Site or any
          sites, services or materials linked or related thereto or therefrom
          and also are not responsible or liable in any way for any injury,
          loss, claim or damage relating to or resulting from any part of the
          Site operating or not operating on computers or networks used by you
          or communicating with such computers or networks. To the extent we
          list or link to third party products or services, our site acts as the
          venue for suppliers to sell products and services (or, as appropriate,
          solicit offers to buy) and buyers to purchase such products and
          services. We are not involved in the actual transaction between buyers
          and suppliers. As a result, we have no control over the quality,
          safety or legality of the items advertised, the truth or accuracy of
          the listings, the ability of suppliers to sell items or the ability of
          buyers to buy items. We cannot ensure that a buyer or supplier will
          actually complete a transaction. We do not control the information
          provided by other users which is made available through the Site. You
          may find other user's information to be offensive, harmful,
          inaccurate, or deceptive. Please use caution and common sense when
          using the Site. Please note that there are also risks of dealing with
          foreign nationals, underage persons or people acting under false
          pretense. Although we intend that product/service descriptions
          contained in the Site be current and accurate, we make no warranty or
          representation that descriptions of products/service in the Site are
          accurate, complete, current, or reliable in any or all respects. In
          the event that a product described in the Site is not as described,
          your sole remedy is to discontinue the use of product/service. NO ORAL
          ADVICE OR WRITTEN CORRESPONDENCE OR INFORMATION PROVIDED BY US OR ANY
          OF THE INDEMNIFIED PARTIES WILL CREATE A WARRANTY OF ANY KIND AND
          USERS SHOULD NOT RELY ON ANY SUCH INFORMATION OR ADVICE. WE RESERVE
          THE RIGHT, IN OUR SOLE AND ABSOLUTE DISCRETION AND WITHOUT NOTICE, TO
          CORRECT ANY ERRORS OR OMISSIONS IN ANY PORTION OF THE SITE, OR TO DENY
          ACCESS TO THE SITE TO ANYONE AT ANY TIME. NEITHER WE NOR ANY OF THE
          INDEMNIFIED PARTIES, SHALL HAVE ANY LIABILITY ARISING FROM YOUR
          RELIANCE UPON THE INFORMATION PROVIDED ON THE SITE. The Site may
          contain technical inaccuracies or typographical errors or omissions.
          Lemon Tree Hotels is not responsible for any typographical,
          photographic, technical or pricing (including without limitation
          mistaken hotel rates) errors listed on our Site. We reserve the right
          to cancel or modify reservations where it appears that a customer has
          engaged in fraudulent or inappropriate activity or under other
          circumstances where it appears that the reservations contain or
          resulted from a mistake or error.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          10. Governing Law & Jurisdiction
        </div>
        <div>
          The products and/or services described in and available through the
          Site may not be available in your country. We make no representation
          that the services or products offered in the Site are appropriate or
          available for use in any particular location. Those who choose to
          access the Site do so on their own initiative and are responsible for
          compliance with local laws, if and to the extent local laws are
          applicable. If use of the Site and/or viewing or use of any material
          or content therein or services offered thereby violates or infringes
          any applicable law in your jurisdiction(s), you are not authorized to
          view or use the Site and must exit immediately; Any disputes arising
          out of or related to the Site shall be governed by and constructed and
          enforced in accordance with the laws of India. You agree to indemnify
          and hold bunkbread and its subsidiaries, affiliates, officers,
          directors, agents and employees harmless from any claim or demand,
          including reasonable attorney’s fees, made by any third party due to
          or arising out of your breach of this agreement or the documents it
          incorporates by reference, or your violation of any law or the rights
          of a third party. bunkbread’s failure to act with respect to breach by
          you or others does not waive its rights to act with respect to
          subsequent or similar breaches.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          11. Termination
        </div>
        <div>
          These terms are effective until terminated by either party. You may
          terminate these terms at any time by discontinuing use of the Site and
          destroying all materials obtained from any and all such sites and all
          related documentation and all copies and installations thereof,
          whether made under the terms of this Agreement or otherwise. Your
          access to the Site may be terminated immediately without notice from
          us if in our sole and absolute discretion you fail to comply with any
          term or provision of this Agreement. Upon termination, you must cease
          use of the Site and destroy all materials obtained from such site and
          all copies thereof, whether made under the terms of this Agreement or
          otherwise. Notwithstanding the termination of this Agreement, you
          acknowledge and agree that those rights and obligations which by their
          nature are intended to survive the termination of this Agreement in
          order to be fully operative, shall survive the termination of this
          Agreement including, without limitation, the provisions hereof: (i)
          Restrictions on Use of Materials; (ii) Submissions; (iii) Disclaimers;
          (iv) Indemnification; (v) Limitations of Liability; and (xi) Governing
          Laws & Jurisdiction.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          12. General
        </div>
        <div>
          These Terms and Conditions together with bunkbread Privacy Policy,
          constitute the entire agreement between the parties relating to the
          use of this Site. If any provision of these Terms and Conditions is
          deemed void, unlawful, or unenforceable for any reason, then that
          provision shall be severed from the rest of the terms and conditions
          and shall not affect the validity and enforceability of any of the
          remaining provisions. bunkbread may revise these terms and conditions
          at any time by updating the positing and such changes shall be
          effective immediately unless otherwise indicated. No agency,
          partnership, joint-venture, franchisee-franchisor, or
          employee-employer relationship is created by these Terms and
          Conditions or the use of the Site.
        </div>
      </React.Fragment>
    );
  }
}

export default TOU;
