import React from "react";
import TextField from "@material-ui/core/TextField";
// import EditIcon from "@material-ui/icons/Edit";
// import CloseIcon from "@material-ui/icons/Close";
// import Button from "@material-ui/core/Button";

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      disabled: true,
    };
  }

  handleChange = (e) => {
    this.props.change(e.target);
    this.setState({ value: e.target.value });
  };

  // renderButton = () => {
  //   if (this.state.disabled) {
  //     return <EditIcon />;
  //   }
  //   return <CloseIcon />;
  // };

  render() {
    return (
      <div>
        <TextField
          style={{ margin: "10px" }}
          label={this.props.name}
          name={this.props.name}
          // disabled={this.state.disabled}
          type="text"
          value={this.state.value}
          onChange={this.handleChange}
          variant="outlined"
        />
        {/* <Button
          onClick={() => this.setState({ disabled: !this.state.disabled })}
          variant="contained"
          color="inherit"
          style={{ margin: "19px 0 0" }}
        >
          {this.renderButton()}
        </Button> */}
      </div>
    );
  }
}

export default EditForm;
