import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { admin } from "../actions";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
});

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };
  }

  handleDateChange = (date) => {
    this.setState({ date });
    this.props.admin(date);
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-from"
                  label="Date"
                  format="dd/MM/yyyy"
                  value={this.state.date}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>User_ID</TableCell>
                  <TableCell align="right">Female_Bed_Count</TableCell>
                  <TableCell align="right">Mixed_Bed_Count</TableCell>
                  <TableCell align="right">Check_In</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.female_bed_count}</TableCell>
                    <TableCell align="right">{row.mixed_bed_count}</TableCell>
                    <TableCell align="right">{row.check_in}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { data: Object.values(state.admin) };
};

const styles = withStyles(useStyles)(Admin);

export default connect(mapStateToProps, {
  admin,
})(styles);
