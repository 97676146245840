import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { CardMedia } from "@material-ui/core";
import Footer from "./static/footer";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Navbar from "./static/navbar/navbarMui";
import imageF from "../assets/franchise1.jpg";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
const useStyles = (theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  paperContainer: {
backgroundImage:'url(/Franchise_desktop.png)',
  },
});

class Franchise extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Navbar />
        <Link to="/">
          <CloseRoundedIcon
          className="crossIcon"
            fontSize="large"
            style={{
              color: "#ffde17",
              position: "absolute",
              right: "20px",
              marginTop: "10px",
              opacity: "1",
              zIndex: "1",
            }}
          />
        </Link>
        <div style={{ margin: "0px 10px" }}>
          {/* <h2
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              margin: "10px 0 5px",
            }}
          >
            Franchise
          </h2> */}
          {/* <Paper style={{ height: "740px", margin: "10px" }}> */}
          <Paper className= "frnCard">
          <img style={{ height: "315px" }} src={imageF} />
          <div
            style={{
              fontSize: "13px",
              fontFamily: "Poppins",
              margin: "10px",
            }}
          >
            At bunkbread, we endeavour to partner with patrons who share the
            true blood for exploration & adventure, who can extend this
            sentiment to the like minded customers in their journey of
            exploration. Our main objective is to provide a go to brand for
            immersive adventure experience in addition to ensuring a superior
            product experience. Our franchisee network teams are our extended
            partners in this task. Bunkbread deploys a thorough comprehensive
            process for franchise allotment. Franchisee selection is done based
            on a variety of criteria including a personal meeting with the
            applicant. It is only post completion of the evaluation and
            selection process, that other formalities are considered. If you
            receive any communication offering allotment of bunkbread franchise
            against payment/transfer of money and/or otherwise, we advise you to
            seek information/clarifications by writing to us at&nbsp;
            <a href="mailto:teambunkbread@gmail.com">mail</a>&nbsp; or by
            contacting us on 6206653586.
          </div>
          </Paper>
          <h3
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              marginBottom: "5px",
              marginLeft: "20px",
            }}
          >
            Requirements
          </h3>
          <div className="franchiseReqContainer" >
          <CardMedia
            className={`franchiseReq`}
            style={{
              // height: "100%",
              // width: "100%",
              }}>
          </CardMedia>
          </div>

          {/* <div
            style={{ fontSize: "13px", fontFamily: "Poppins" }}
          ></div>
          <Timeline align="alternate">
            <TimelineItem>
              {/* <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                9:30 am
              </Typography>
            </TimelineOppositeContent> */}
              {/* <TimelineSeparator>
                <TimelineDot>
                  <Avatar>A</Avatar>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  style={{ width: "180px" }}
                  elevation={3}
                  className={classes.paper}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    }}
                    variant="h6"
                    component="h1"
                  >
                    Property
                  </Typography>
                  <Typography
                    style={{ fontSize: "13px", fontFamily: "Poppins" }}
                  >
                    5-6 rooms property with ample open areas:
                    halls/lounge/terrace/lawn for customers to socialize.
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem> */}
              {/* <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                10:00 am
              </Typography>
            </TimelineOppositeContent> */}
              {/* <TimelineSeparator>
                <TimelineDot color="primary">
                  <Avatar>B</Avatar>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  style={{ width: "180px" }}
                  elevation={3}
                  className={classes.paper}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    }}
                    variant="h6"
                    component="h1"
                  >
                    Owner
                  </Typography>
                  <Typography
                    style={{ fontSize: "13px", fontFamily: "Poppins" }}
                  >
                    One who commonly shares the enthusiasm for adventure.
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                  <Avatar>C</Avatar>
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  style={{ width: "180px" }}
                  elevation={3}
                  className={classes.paper}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    }}
                    variant="h6"
                    component="h1"
                  >
                    Investment
                  </Typography>
                  <Typography
                    style={{ fontSize: "13px", fontFamily: "Poppins" }}
                  >
                    Willing to invest 5-10 lacs(depending upon the condition of
                    the property) as initial capital investment.
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary">
                  <Avatar>D</Avatar>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  style={{ width: "180px" }}
                  elevation={3}
                  className={classes.paper}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    }}
                    variant="h6"
                    component="h1"
                  >
                    Location
                  </Typography>
                  <Typography
                    style={{ fontSize: "13px", fontFamily: "Poppins" }}
                  >
                    For HOSTELs/WORKPADs, property needs to be within 500mts of the centre of town. For HOTSPOTs, property should be accessible by two-wheelers.
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline> */}
          <h4
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              marginBottom: "5px",
              marginLeft: "20px",
            }}
          >
            Contact Info
          </h4>
          <Paper style={{ height: "60px", margin: "0px" }}>
          <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                            fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            Phone :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            +91-6206653586
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                          color="textSecondary"
                          style={{
                            marginLeft: "10px",
                            fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            E-Mail :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                          style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                                }}
                      >
                            teambunkbread@gmail.com
                      </Typography>
                    </Grid>
                    </Grid>
        </Paper>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(Franchise);
