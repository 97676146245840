let states = []

const mixedTempDetails = (state = states, action) => {
    if(action.type === 'mixedTempDetails'){
        // console.log(action.payload)
        return action.payload
    }
    else {
        return state
    }
}

export default mixedTempDetails