let states = []

const femaleTempDetails = (state = states, action) => {
    if(action.type === 'femaleTempDetails'){
        return action.payload
    }
    else {
        return state
    }
}

export default femaleTempDetails