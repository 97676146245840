import { Component, Fragment } from "react";
import {
  Button,
  FormControl,
  IconButton,
  LinearProgress,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import Navbar from "./static/navbar/navbarMui";
import { auth } from "../firebase";
import firebase from "firebase";
import { sendemailotp, verifysec } from "../actions";
import { connect } from "react-redux";
import Edit from "@material-ui/icons/Edit";
import PhoneInput from "react-phone-input-2";
let recaptcha;
let ocode;
class Authreq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onprocess: false,
      loginput: "",
      logtype: "email",
      otpfield: false,
      error_message: "",
      opensnackbar: false,
      onprocess: false,
      editinput: false,
      oocode: "",
    };
  }

  componentDidMount() {
    // console.log("HIII");
    // auth.settings.appVerificationDisabledForTesting = true;

   
    if (this.props.match.params.type === "phoneauth") {
      recaptcha = new firebase.auth.RecaptchaVerifier("captcha", {
        size: "invisible",
        callback: (response) => {
          //this.state.captchasolved = true;
          this.setState({ captchasolved: true });
          // console.log("captcha solved", this.state.captchasolved);
        },
        "expired-callback": () => {
          this.setState({ captchasolved: false });
          alert("CAptcha error.. reload the page");
          this.setState({
            opensnackbar: true,
            error_message: "Captcha Error : Reload the Page",
            onprocess: false,
          });
          // console.log("reverify");
        },
      });
      recaptcha.render().catch((e) => {
        // alert("Network Error", e);
        this.setState({
          opensnackbar: true,
          error_message: "Captcha Error : Reload the Page",
          onprocess: false,
        });
        // console.log(e);
      });
    }
    if (this.props.location.state !== undefined) {
      if (
        this.props.location.state.hasOwnProperty("toverifydata") &&
        this.props.location.state.toverifydata !== ""
      ) {
        this.setState({ loginput: this.props.location.state.toverifydata });
      } else {
        alert("Wrong Path");
        this.props.history.push("/");
      }
    }
    if (this.props.match.params.type === "phoneauth") {
      this.setState({ logtype: "phone" });
    }
  }

  sendotp = async () => {
    // console.log(this.props);
    this.setState({ editinput: false });
    // return
    /// email ///
    if (this.state.logtype === "email") {
      const res = await this.props.sendemailotp(this.state.loginput);
      if (res.status === "message delivered") {
        this.setState({ otpfield: true });
        ocode = res.ocode;
      }
    }
    /// phone ///
    else if (this.state.logtype === "phone") {
      //phone auth
      await auth
        .signInWithPhoneNumber(this.state.loginput, recaptcha)
        .then((confirmation) => {
          window.confirmationResult = confirmation;
          this.setState({ otpfield: true });
          //update backend
        })
        .catch((e) => {
          // console.log(e);
        });
    }
  };

  verifyotp = async (e) => {
    e.preventDefault();
    this.setState({ onprocess: true });
    alert("start");
    if (this.state.oocode === "") {
      alert("Invalid code");
      return;
    }
    //// email ///
    if (this.state.logtype === "email") {
      if (ocode === this.state.oocode) {
        //success
        //
        // console.log(ocode);
        const data = {
          type: "email",
          email: this.state.loginput,
          uid: this.props.match.params.userId,
        };
        // console.log(auth.currentUser.getIdToken());
        const res = await this.props.verifysec(data);

        if (res.status === "success") {
          alert("Successfull");
          // console.log(res);
          const v = auth.currentUser.getIdToken();
          // console.log(v);
          auth.currentUser
            .reauthenticateWithCredential(res.token)
            .catch((e) => {
              // console.log("reauthenticate::", e.message);
            });
          auth.currentUser.reload();
          alert("Re login => To View Changes ");
          this.props.history.push("/login");
        } else {
          alert(res.message);
        }
      }
    } else {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        this.state.oocode
      );
      await auth.currentUser.linkWithCredential(credential).then((user) => {
        const data = {
          type: "phone",
          phone: this.state.loginput,
          uid: this.props.match.params.userId,
        };
        const res = this.props.verifysec(data);
        if (res.status === "success") {
          alert("update successfull");
          // console.log(user);
          this.props.history.push("/profile/" + auth.currentUser.uid);
        } else {
          alert(res.message);
        }
      });
    }
  };

  render() {
    // console.log(this.state);
    return (
      <Fragment>
        <Navbar />
        <LinearProgress
          hidden={!this.state.onprocess}
          style={{
            position: "sticky",
            //top: "10vh",
            backgroundColor: "#ffde17",
            zIndex: "999",
          }}
        />{" "}
        <div style={{ display: "flex", justifyContent: "center",margin:"1rem" }}>
          {this.state.otpfield? (
            <div style={{ marginTop: "5vh" }}>
              <Typography
                style={{
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.5rem",
                  
                }}
              >
                Authentication required
              </Typography>
              <br />
              <b>{this.state.loginput}</b>
              <Button
                color="primary"
                disabled={this.state.onprocess}
                onClick={(e) => {
                  // console.log("clicked change");
                  this.setState({ otpfield: false });
                }}
              >
                change
              </Button>
              <br />
              <p style={{ fontWeight: "550", fontSize: "medium" }}>
                We have sent a One Time Password(OTP) to the <br />
                {this.state.logtype === "phone"
                  ? "mobile number"
                  : "email address"}{" "}
                above.
                <br />
                Please enter to complete verification
              </p>
              <form>
                <FormControl fullWidth={true}>
                  <OutlinedInput
                    placeholder="Enter OTP"
                    type="text"
                    margin="dense"
                    //styel={{ height: "30px" }}
                    disabled={this.state.onprocess}
                    onChange={(e) => this.setState({ oocode: e.target.value })}
                  ></OutlinedInput>
                </FormControl>
                <FormControl fullWidth={true} margin="normal">
                  <Button
                    onClick={(e) => this.verifyotp(e)}
                    disabled={this.state.onprocess}
                    variant="contained"
                    style={{
                      backgroundColor: "#FFDE17",
                      color: "black",
                      borderColor: "black",
                      borderWidth: "3px",
                      marginTop:".5rem"
                    }}
                  >
                    Verify OTP
                  </Button>
                </FormControl>
              </form>
              <FormControl fullWidth={true}>
                <Button
                  onClick={this.loginBegin}
                  color="primary"
                  disabled={this.state.onprocess}
                  style={{marginTop:".5rem"}}
                >
                  Resend OTP
                </Button>
              </FormControl>
              <FormControl fullWidth={true}>
                <Button
                  disabled={this.state.onprocess}
                  onClick={(e) => {
                    this.props.history.push("/profile")
                  }}
                  variant="contained"
                  color="primary"
                  style={{marginTop:".5rem"}}
                >
                  Go To Profile
                </Button>
              </FormControl>
            </div>
          ) : (
            <div style={{ marginTop: "5vh" }}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  margin:"2rem 0rem"
                }}
              >
                Verify Secondary Data
                <br />
               {this.state.logtype==="email"?"Email":"Phone"}
              </Typography>
              {this.state.editinput ? (
                <div>
                  {this.state.logtype!=="email"?<TextField
                    variant="outlined"
                    style={{
                      height:"35px"
                    }}
                    margin="dense"
                    size="small"
                    defaultValue={this.state.loginput}
                    onChange={(e) =>
                      this.setState({ loginput: e.target.value })
                    }
                  ></TextField>:
                  <PhoneInput   disabled={this.state.onprocess}
                  country="in"
                  disableDropdown="false"
                  value={this.state.loginput}
                  onChange={(loginput) => {
                    this.setState({ loginput});
                  }}
                  
                  inputProps={{
                    name: "tel",
                    required: true,
                    
                  }}
                  inputStyle={{
                    width: "100%",
                  }}>
                    </PhoneInput>}
                </div>
              ) : (
                <div >
                  {this.state.loginput}
                  <IconButton
                    onClick={(e) => this.setState({ editinput: true })}
                  >
                    <Edit />
                  </IconButton>
                </div>
              )}
              <Button onClick={this.sendotp} variant="contained" style={{marginTop:"1rem"}}>Send OTP</Button>
              <div id="captcha"></div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps, { sendemailotp, verifysec })(Authreq);
