const init = {
  autherror: null,
  logerror: null,
  user:null
};
export default function authreducer(state = init, action) {
  switch (action.type) {
    case "LOG_USER":
      // console.log("signup success");
      return {
        ...state,
        autherror: null,
        logerror: null,
        user:action.payload
      };
    case "LOG_ERROR":
      // console.log("login errror", action.payload);
      return {
        ...state,
        autherror: action.payload,
        logerror: null,
      };
    case "LOGIN_USER":
      // console.log("LOGINED success");
      return {
        ...state,
        logerror: null,
      };
    case "LOGIN_ERROR":
      // console.log("log error",action.payload);
      return {
        ...state,
        logerror: action.payload,
      };
    default:
      return state;
  }
}
