import _ from "lodash";

export default (state = null, action) => {
  switch (action.type) {
    case "USER_BOOKING":
      return {
        ..._.mapKeys(action.payload, "docId"),
      };
    default:
      return state;
  }
};
