import React from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "../../assets/css/test.css";
class CR extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div class="newSubHeaderDark">
          1.1. Booking
        </div>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    width: "33%",
                    fontWeight: "600",
                  }}
                >
                  <div class="newSubHeaderLight">Booking</div>
                </TableCell>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    width: "33%",
                    fontWeight: "600",
                  }}
                  align="left"
                >
                  Pay 100% to confirm
                </TableCell>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    fontWeight: "600",
                  }}
                  align="left"
                >
                  Pay 30% to confirm
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "33%" }}
                >
                  within 4 days of check-in
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "33%" }}
                  align="left"
                >
                  <CheckRoundedIcon />
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                  align="left"
                >
                  <CloseRoundedIcon />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "33%" }}
                >
                  beyond 4 days of check-in
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "33%" }}
                  align="left"
                >
                  <CheckRoundedIcon />
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                  align="left"
                >
                  <CloseRoundedIcon />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <span style={{ fontSize: "13px", fontWeight: "600" }}>Case 1: </span>
          The guest makes the booking on a date which is 4 days before the
          check-in date. S/He will get the option to confirm his/her booking by
          either paying 30% of the entire amount or by paying the entire amount.
          E.g. Guest is trying to make a booking on 3/4/2021 for check-in dates
          beyond 7/4/2021. S/He will have the option to either pay the full
          amount or 30% of the full amount for confirming his/her booking.
          <br />
          <span style={{ fontSize: "13px", fontWeight: "600" }}>Case 2: </span>
          The guest makes the booking on a date which is within 4 days of
          check-in. S/He will only get the option of making full payment to
          confirm his booking. E.g. Guest is trying to make a booking on
          3/4/2021 for check-in dates 4-5-6/4/2021. S/He will have to pay the
          entire amount to confirm his/her booking.
        </div>
        <div class="newHeader">
          1.2. Cancellation & Refund
        </div>
        <div>Cancellation & refund policy</div>
        <div>
          Reservations that are cancelled at least 4 days prior to arrival are
          not charged for
        </div>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    width: "33%",
                    fontWeight: "600",
                  }}
                >
                  Cancellation
                </TableCell>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    width: "33%",
                    fontWeight: "600",
                  }}
                  align="left"
                >
                  Paid 100%
                </TableCell>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    fontWeight: "600",
                  }}
                  align="left"
                >
                  Paid 30%
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "33%" }}
                >
                  Before 4 days of check-in
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "33%" }}
                  align="left"
                >
                  Full refund - Rs50 processing charge
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                  align="left"
                >
                  Full refund(30%) - Rs50 processing charge
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "33%" }}
                >
                  Within 4 days of check-in
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "33%" }}
                  align="left"
                >
                  30% deducted rest 70% refunded
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                  align="left"
                >
                  Entire 30% deducted no refunds to make
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          The Refund on cancellation will take approx 4 working days for INR and
          7 working days for USD and EURO.
        </div>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          1.3. No Show
        </div>
        <div>
          whereas no shows or failing to cancel will incur a charge as below.
        </div>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    width: "36%",
                    fontWeight: "600",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    width: "32%",
                    fontWeight: "600",
                  }}
                  align="left"
                >
                  Paid 100%
                </TableCell>
                <TableCell
                  style={{
                    borderTop: "none",
                    paddingBottom: "0",
                    fontWeight: "600",
                  }}
                  align="left"
                >
                  Paid 30%
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "40%" }}
                >
                  No check-in/No cancellation
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "30%" }}
                  align="left"
                >
                  No refund
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                  align="left"
                >
                  No refund
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "40%" }}
                >
                  No. of days booked is greater than 10 days. On no check-in/No
                  cancellation
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0", width: "30%" }}
                  align="left"
                >
                  50% refund
                </TableCell>
                <TableCell
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                  align="left"
                >
                  No refund
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ fontSize: "14px", fontWeight: "600", margin: "8px 0" }}>
          1.4. Now show protocols
        </div>
        <div>
          No show begins from the moment the guest fails to arrive on the
          check-in date till 11p.m. The property manager will try to contact the
          guest before the no show cutoff time(minimum 5 efforts to be made and
          recorded). If the guest notifies of late night or next day early
          morning arrival, his/her booking stands valid. If the guest is not
          contactable, neither do they notify about delayed arrival, then in
          that case the booking stands null & void and the inventory will be
          released after 24 hrs from the end of Check-in date. Any payment made
          either in full or 30% of total shall be totally deducted in case of a
          no show. For a fully paid booking of more than 10 days, 50% of the
          amount will be refunded to the guest account if he/she provides the
          account details or else in the form of loyalty coins of equal value,
          in case the guest is not contactable. Exceptions will be provided in
          case of medical emergencies, natural calamities.
        </div>
      </React.Fragment>
    );
  }
}

export default CR;
