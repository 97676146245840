import React from "react";
import { UserContext } from "../providers/UserProvider";
// import { Otp } from "react-otp-timer";
import OtpTimer from "otp-timer";
import {
  TextField,
  Typography,
  Button,
  Snackbar,
  LinearProgress,
  FormControl,
  FormGroup,
  Box,
  IconButton,
  Backdrop,
} from "@material-ui/core";
import { Phone, Mail, Close as CloseIcon } from "@material-ui/icons";
import { ToggleButton, Alert } from "@material-ui/lab";
//import GoogleIcon from "../assets/1004px-Google__G__Logo.svg.png";
import firebase from "firebase";
import { createaccountemail, sendemailotp, chckdatabse } from "../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import NavbarMui from "./static/navbar/navbarMui";
import Footer from "./static/footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailIcon from "@material-ui/icons/Mail";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TOU from "./static/termsofuse";
import PP from "./static/privacypolicy";
import CR from "./static/cancellationpolicy";
//import { ToggleButton } from "react-bootstrap";
let recaptcha;
let confirmationResult;
// let googleprovide;
const selccolor = "#ffde17";
const seltext = "#555";
const disbletext = "#555";
const disbalecolor = "rgb(236 236 236 / 66%)";
const _regexNumber = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";
const _regexEmail =
  "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
class Login2 extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      loginput: "",
      loginbutton: false,
      oocode: "",
      logtype: "email",
      captchasolved: false,
      opensnackbar: false,
      error_message: "",
      mailtogval: "60%",
      phonetogval: "35%",
      mailtogcol: selccolor,
      phonetogcol: disbalecolor,
      mailtextcolor: seltext,
      photextcolor: disbletext,
      TOUopen: false,
      PPopen: false,
      CRopen: false,
      authprovider: "google",
      onprocess: false,
      severity: "error",
    };
    // this.loginBegin = this.loginBegin.bind(this)
  }

  componentDidMount() {
    //console.log("Login Page",this.state);

    // console.log(this.props.location);
    //var parmas = this.props.match.parmas;
    // googleprovide = new firebase.auth.GoogleAuthProvider();
    // googleprovide.addScope("profile");
    // googleprovide.addScope("email");
    // googleprovide.addScope("phone");
    // console.log(this.props.location);
    /// auth google firebase

    // auth.settings.appVerificationDisabledForTesting = true;
    // if (this.state.startTimer) {
    //   this.timerf()
    // }
    recaptcha = new firebase.auth.RecaptchaVerifier("captcha", {
      size: "invisible",
      callback: (response) => {
        //this.state.captchasolved = true;
        this.setState({ captchasolved: true });
        // console.log("captcha solved", this.state.captchasolved);
      },
      "expired-callback": () => {
        // this.setState({  });
        // alert("CAptcha error.. reload the page");
        this.setState({
          captchasolved: false,
          opensnackbar: true,
          error_message: "Captcha Error : Reload the Page",
          onprocess: false,
        });
        // console.log("reverify");
      },
    });
    recaptcha.render().catch((e) => {
      // alert("Network Error", e);
      this.setState({
        opensnackbar: true,
        error_message: "Captcha Error : Reload the Page",
        onprocess: false,
      });
      // console.log(e);
    });
  }

  ///
  loginBegin = async (event) => {
    // console.log(event)
    if (event !== undefined) event.preventDefault();
    if (this.state.onprocess) {
      return;
    }
    this.setState({ onprocess: !this.state.onprocess });
    // if(this.state.captchasolved === false)return
    console.log("Proccedd==>", this.state);
    if (this.state.loginput === "") {
      this.setState({
        opensnackbar: true,
        error_message: "Empty " + this.state.logtype + " field !",
        onprocess: false,
      });
      return;
    }
    ///check with regex if email or not
    let emailregx = new RegExp(_regexEmail);
    if (this.state.logtype === "email") {
      if (emailregx.test(this.state.loginput)) {
        this.setState((state) => ({ logtype: "email" }));
        // console.log(this.state.loginput);
        //send otp to email using sendrid

        this.otpsender();
      } else {
        this.setState({
          opensnackbar: true,
          error_message: "Invalid Email",
          onprocess: false,
        });
        // alert("invalid Email", this.state.onprocess);
        return;
      }
    } else {
      // console.log("phone no");
      this.setState((state) => ({ logtype: "phone" }));
      //send otp to phone no and procceds
      let phoneregex = new RegExp(_regexNumber);
      if (!phoneregex.test(this.state.loginput)) {
        this.setState({ onprocess: false });

        this.setState({
          opensnackbar: true,
          error_message: "invalid number",
        });
        // alert("invalid number", this.state.onprocess);
        return;
      }
      let countrycode = "+";
      // console.log(this.state.loginput);
      let phone_no = this.state.loginput;
      // console.log(this.state.loginput[0]);
      if (this.state.loginput[0] !== "+") {
        // console.log("no +");
        phone_no = countrycode.concat(this.state.loginput);
      } else {
        // console.log("safe");
      }
      //  this.setState({loginput:phone_no})
      console.log(phone_no, this.state.loginput);
      this.setState({ loginput: phone_no });

      this.phoneauth(phone_no);
    }
  };

  verifyotp = async (e) => {
    this.setState({ onprocess: !this.state.onprocess });
    // console.log("HII", sessionStorage.getItem("emailotp"), this.state.logtype);
    e.preventDefault();
    // console.log(this.state.oocode === sessionStorage.getItem("emailotp"));
    if (
      this.state.logtype === "email" &&
      this.state.oocode === sessionStorage.getItem("emailotp")
    ) {
      // console.log("HUrray");
      // this.setState({
      //   opensnackbar: true,
      //   severity: "success",
      //   error_message: "Logging Succesfull",
      // });
      const res = await this.props.createaccountemail(this.state.loginput);
      if (res === "success") this.chkuser();
      // check for user data ifnull move to setup profile
    } else {
      // this.setState({loginput:"+91".concat(this.state.loginput)})
      if (confirmationResult) {
        confirmationResult
          .confirm(this.state.oocode)
          .then((result) => {
            // console.log(result);
            // this.setState({
            //   opensnackbar: true,
            //   severity: "success",
            //   error_message: "Logging Succesfull",
            // });
            this.chkuser();
          })
          .catch((e) => {
            this.setState({ onprocess: false });
            this.setState({
              opensnackbar: true,
              severity: "error",
              error_message: "Invalid Code !",
            });
          });
      } else {
        this.setState({ onprocess: false });
        this.setState({
          opensnackbar: true,
          severity: "error",
          error_message: "Invalid Code !",
        });
      }
    }
  };

  phoneauth = async (phone_no) => {
    // event.preventDefault()
    // console.log(this.state.logtype);

    await auth
      .signInWithPhoneNumber(phone_no, recaptcha)
      .then((confirmResult) => {
        // console.log(confirmResult);
        confirmationResult = confirmResult;
        this.setState({ loginbutton: true });
        this.setState({ onprocess: false });
        // this.timerf();
      })
      .catch((e) => {
        console.log("error", e);
        this.setState({
          opensnackbar: true,
          error_message: "Invalid OTP or Worng Credential",
          onprocess: !this.state.onprocess,
        });
      });
    // console.log("niceeeeeee.............");
  };
  ////=========
  handelsnackclose = () => {
    //event.preventDefault();
    // console.log("snackclose");
    this.setState({
      opensnackbar: false,
      error_message: "",
      severity: "error",
    });
  };
  ////=======
  //checkfor if profile is made or not and email or phone verified or not
  chkuser = async () => {
    //console.log("checking user:", auth.currentUser);
    /// chceck if database exists
    const res = await this.props.chckdatabse({
      type: this.state.logtype,
      data: this.state.loginput,
      uid: auth.currentUser.uid,
    });
    // console.log(res);
    // this.setState({
    //   opensnackbar: true,
    //   severity: "success",
    //   error_message: "Logging Succesfull",
    // });
    setTimeout(() => {
      if (res.status === "exist") {
        // console.log(this.props.location);
        if (
          this.props.location.state !== undefined &&
          this.props.location.state.hasOwnProperty("next") &&
          typeof this.props.location.state.next === "string"
        ) {
          if (auth.currentUser.displayName === null) {
            //setupprofile
            this.props.history.push({
              pathname: "/setupprofile/" + auth.currentUser.uid,
              state: {
                primary: this.state.logtype,
                next: this.props.location.state.next,
                prev: "login",
              },
            });
            return;
          } else {
            this.setState({ onprocess: !this.state.onprocess });
            this.props.history.push({
              pathname: "/" + this.props.location.state.next,
              state: {
                proccedBook: true,
                prev: "login",
              },
            });
            return;
          }
        } else {
          this.setState({ onprocess: !this.state.onprocess });
          this.props.history.push({
            pathname: "/",
            state: {
              // primary: this.state.logtype,
              next: "landing",
              prev: "login",
            },
          });
          return;
        }
      }
      if (res.status === "not exist") {
        //console.log("location:",this.props.location.state.onBooking)

        if (
          this.props.location.state !== undefined &&
          this.props.location.state.hasOwnProperty("next") &&
          typeof this.props.location.state.next === "string"
        ) {
          //booking true
          // console.log("Hurray");
          //  alert("not exist booking true: fromlogin true");
          this.setState({ onprocess: !this.state.onprocess });
          this.props.history.push({
            pathname: "/setupprofile/" + auth.currentUser.uid,
            state: {
              primary: this.state.logtype,
              next: this.props.location.state.next,
              prev: "login",
            },
          });
          return;
        } else {
          //  alert("not exist state defined: fromlogin true");
          this.setState({ onprocess: !this.state.onprocess });
          this.props.history.push({
            pathname: "/setupprofile/" + auth.currentUser.uid,
            state: {
              primary: this.state.logtype,
              prev: "login",
            },
          });
          return;
        }
      } else {
        this.setState({ onprocess: !this.state.onprocess });
        this.props.history.push({
          pathname: "/",
          state: {
            // primary: this.state.logtype,
            next: "landing",
            prev: "login",
          },
        });
        return;
      }
    }, 3000);
  };
  ///
  ///otp sender
  otpsender = async () => {
    // event.preventDefault()
    //email
    // console.log("otpsender");
    const res = await this.props.sendemailotp(this.state.loginput);
    if (res.status === "message delivered") {
      //check if otp is correct
      //send to backend and create account and verify it
      // console.log(res.ocode);
      sessionStorage.setItem("emailotp", res.ocode);
      // window.emailotp = res.ocode;
      // console.log(typeof sessionStorage.getItem("emailotp"));
      this.setState({ onprocess: !this.state.onprocess, startTimer: true });
      // this.timerf();
      this.setState({ loginbutton: true });
      return;
    }
  };

  changeinputstate = async (state, tochange) => {
    if (tochange === state) {
      return;
    }
    if (state === "email") {
      this.setState({
        logtype: "phone",
        mailtogval: "35%",
        phonetogval: "60%",
        mailtogcol: disbalecolor,
        phonetogcol: selccolor,
        mailtextcolor: disbletext,
        photextcolor: seltext,
      });
    } else {
      this.setState({
        logtype: "email",
        mailtogval: "60%",
        phonetogval: "35%",
        mailtogcol: selccolor,
        phonetogcol: disbalecolor,
        mailtextcolor: seltext,
        photextcolor: disbletext,
      });
    }
  };

  handleTOU = () => {
    this.setState({ TOUopen: true });
  };

  handleTOUClose = () => {
    this.setState({ TOUopen: false });
  };

  handlePP = () => {
    this.setState({ PPopen: true });
  };

  handlePPClose = () => {
    this.setState({ PPopen: false });
  };

  handleCR = () => {
    this.setState({ CRopen: true });
  };

  handleCRClose = () => {
    this.setState({ CRopen: false });
  };
  render() {
    const user = this.context;
    // console.log(this.state);
    // if(user !== null && user.displayName === null)alert("no name")
    // console.log(this.props,this.props.location.state)

    return (
      <React.Fragment>
        <NavbarMui />

        <LinearProgress
          hidden={!this.state.onprocess}
          style={{
            position: "sticky",
            // top: "10vh",
            backgroundColor: "#ffde17",
            zIndex: "999",
          }}
        />

        <div
          style={{
            display: "block",
            marginLeft: "10px",
            marginRight: "10px",
            transition: ".5s all ease-out",
          }}
        >
          <Box
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
            // width={1 / 1.5}
          >
            <FormGroup
              style={{
                display: "inline-block",
                textAlign: "left",
                top: "10rem",
                display: "none !important",
              }}
            >
              <Snackbar
                autoHideDuration={4000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                // severity="danger"
                open={this.state.opensnackbar}
                onClose={this.handelsnackclose}
                //TransitionComponent="Grow"
                style={{
                  //display: "none",
                  marginBottom: "4rem",
                }}
              >
                <Alert
                  variant="filled"
                  elevation={6}
                  style={{ width: "90vw" }}
                  severity={this.state.severity}
                  action={
                    <IconButton
                      aria-label="close"
                      //color="inherit"
                      size="small"
                      onClick={this.handelsnackclose}
                    >
                      <CloseIcon
                        fontSize="inherit"
                        style={{ color: "white" }}
                      />
                    </IconButton>
                  }
                >
                  {this.state.error_message}
                </Alert>
              </Snackbar>

              {this.state.loginbutton === false ? (
                <div style={{ marginTop: "5vh" }}>
                  <Typography variant="h5">
                    <p style={{ fontWeight: "600", fontFamily: "poppins" }}>Login</p>
                  </Typography>
                  {/* <FormGroup>
                    <Collapse in={this.state.opensnackbar}>
                      <Alert
                        variant="filled"
                        elevation={6}
                        severity="info"
                        style={{ margin: "1rem 0rem 1rem 0rem" }}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={this.handelsnackclose}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.state.error_message}
                      </Alert>
                    </Collapse>
                  </FormGroup> */}
                  <form onSubmit={this.loginBegin}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        justifyContent: "space-between",
                        marginBottom: "1em",
                      }}
                    >
                      {/* <img src=""
                    style={{
                      height:"100px",
                      width:"100px"
                    }}
                    ></img>
                    <Button
                    style={{
                      height:"100px",
                      width:"100px"
                    }}
                    >Phone</Button> */}
                      <ToggleButton
                        value={this.state.logtype}
                        disabled={this.state.onprocess}
                        style={{
                          outline: "none",
                          width: this.state.mailtogval,
                          transition: "all .5s ease-out",
                          backgroundColor: this.state.mailtogcol,
                          height: "6rem",
                          minHeight: "7rem",
                          color: this.state.mailtextcolor,
                          fontSize: "small",

                          boxShadow:
                            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                          borderRadius: "5px",
                        }}
                        onClick={(e) => {
                          //this.setState({
                          //   logtype: "email",
                          //   mailtogval: "60%",
                          //   phonetogval: "35%",
                          // })
                          this.changeinputstate(this.state.logtype, "email");
                        }}
                      >
                        <center>
                          <Mail />
                          <br />
                          Email
                        </center>
                      </ToggleButton>
                      <ToggleButton
                        value={this.state.logtype}
                        disabled={this.state.onprocess}
                        style={{
                          outline: "none",
                          width: this.state.phonetogval,
                          transition: "all .5s ease-out",
                          backgroundColor: this.state.phonetogcol,
                          height: "6rem",
                          minHeight: "7rem",
                          color: this.state.photextcolor,
                          fontSize: "small",
                          boxShadow:
                            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                          borderRadius: "5px",
                        }}
                        onClick={(e) => {
                          this.changeinputstate(this.state.logtype, "phone");
                        }}
                      >
                        <center>
                          <Phone />
                          <br />
                          Phone
                        </center>
                      </ToggleButton>
                    </div>
                    {this.state.logtype === "email" ? (
                      <TextField
                        // label="Email"
                        className="userEmail"
                        variant="outlined"
                        disabled={this.state.onprocess}
                        name="email"
                        size="small"
                        style={{
                          // height: "5vh",
                          borderRadius: "5px",
                          //padding:"1px 0px 1px",
                          width: "100%",
                          // lineHeight:"25px !important"
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailIcon
                                style={{
                                  color: "gray",
                                  borderRight: "1px solid gray",
                                  paddingRight: "14px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        autoFocus={false}
                        onChange={(e) =>
                          this.setState({ loginput: e.target.value })
                        }
                      ></TextField>
                    ) : (
                      <FormControl fullWidth={true}>
                        <PhoneInput
                          country="in"
                          disabled={this.state.onprocess}
                          placeholder="Mobile Number"
                          // disableDropdown={false}
                          inputStyle={{
                            width: "100%",
                            borderRadius: "5px",
                            fontFamily: "Poppins",
                            // height:"5vh"
                          }}
                          inputProps={{
                            name: "phone",
                            // autoFocus: true,
                            placeholder: "Phone Number",
                          }}
                          onChange={(value) =>
                            this.setState({ loginput: value })
                          }
                        ></PhoneInput>
                      </FormControl>
                    )}

                    <br />
                    <FormControl fullWidth={true}>
                      <Button
                        variant="contained"
                        disabled={this.state.onprocess}
                        style={{
                          backgroundColor: "#FFDE17",
                          color: "black",
                          borderColor: "black",
                          borderWidth: "3px",
                          marginTop: "1rem",
                          borderRadius: "5px",
                        }}
                        type="submit"
                      >
                        Continue
                      </Button>
                    </FormControl>
                  </form>
                </div>
              ) : (
                <div>
                  <p
                    style={{
                      fontWeight: "100",
                      fontFamily: "Poppins",
                      fontSize: "x-large",
                    }}
                  >
                    Authentication Required
                  </p>
                  <b style={{ fontFamily: "Poppins" }}>{this.state.loginput}</b>
                  <Button
                    color="primary"
                    disabled={this.state.onprocess}
                    onClick={(e) => {
                      // console.log("clicked change");
                      this.setState({ loginbutton: false });
                    }}
                    style={{
                      fontFamily: "Poppins",
                    }}
                  >
                    change
                  </Button>
                  <br />
                  <p
                    style={{
                      fontWeight: "550",
                      fontSize: "12px",
                      fontFamily: "Poppins",
                    }}
                  >
                    One Time Password(OTP) sent to the&nbsp;
                    {this.state.logtype === "phone"
                      ? "mobile number"
                      : "email address"}
                    &nbsp;above. 
                    {/* <br /> Enter OTP to complete verification and login */}
                  </p>
                  {/* <FormGroup>
                    <Collapse in={this.state.opensnackbar}>
                      <Alert
                        variant="filled"
                        elevation={6}
                        severity="info"
                        style={{ margin: "1rem 0rem 1rem 0rem" }}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={this.handelsnackclose}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.state.error_message}
                      </Alert>
                    </Collapse>
                  </FormGroup> */}
                  <form>
                    <FormControl fullWidth={true}>
                      <TextField
                        variant="outlined"
                        label="Enter OTP"
                        size="small"
                        type="text"
                        style={{ height: "30px" }}
                        disabled={this.state.onprocess}
                        onChange={(e) =>
                          this.setState({ oocode: e.target.value })
                        }
                      ></TextField>
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={this.verifyotp}
                        disabled={this.state.onprocess}
                        style={{
                          backgroundColor: "#FFDE17",
                          color: "black",
                          borderColor: "black",
                          borderWidth: "3px",
                          marginTop: "16px",
                        }}
                      >
                        Verify OTP
                      </Button>
                    </FormControl>
                  </form>
                  <FormControl
                    fullWidth={true}
                    style={{
                      textAlign: "center",
                      margin: "0.5rem",
                      color: "#3f51b5",
                    }}
                  >
                    {/* <Button
                      onClick={this.loginBegin}
                      color="primary"
                      disabled={this.state.onprocess}
                    >
                     REsend
                    </Button> */}
                    {/* <Otp
                      style={{
                        otpTimer: {
                          margin: "10px",
                          color: "blue",
                        },
                        resendBtn: {
                          backgroundColor: "#5cb85c",
                          color: "white",
                          border: "1 px solid #ccc",
                        },
                      }}
                      minutes={0.5}
                      resendEvent={e=>this.loginBegin()}
                    ></Otp> */}
                    <OtpTimer
                      seconds={30}
                      ButtonText={"RESEND OTP"}
                      // minutes={0}
                      buttonColor="#3f51b5"
                      background="none"
                      text={"wait for.."}
                      resend={this.loginBegin.bind(this)}
                    ></OtpTimer>
                  </FormControl>
                  <FormControl
                    fullWidth={true}
                    style={{ padding: "15px 0 30px" }}
                  >
                    <Button
                      style={{ backgroundColor: "#FFDE17", color: "black" }}
                      disabled={this.state.onprocess}
                      onClick={(e) => {
                        this.setState({
                          loginbutton: false,
                        });
                        this.changeinputstate(this.state.logtype);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {this.state.logtype === "email"
                        ? "Login with Phone no"
                        : "Login with Email"}
                    </Button>
                  </FormControl>
                </div>
              )}
              {/* <div
                style={{
                  display: "flex",
                  marginTop: "1.5rem",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  fontSize: "smaller",
                }}
              >
                <Button
                  disabled={this.state.onprocess}
                  color="default"
                  variant="contained"
                  style={{ marginRight: ".5em", fontSize: "smaller" }}
                  onClick={this.signGoogle}
                >
                  <GooglePlus />
                  Sign&nbsp;In
                </Button>
                <Button
                  disabled={this.state.onprocess}
                  color="default"
                  variant="contained"
                  style={{ marginLeft: ".5em", fontSize: "smaller" }}
                >
                  <Facebook />
                  Sign&nbsp;In
                </Button>
              </div> */}
              <p style={{ fontSize: "12px", marginTop: "1.5rem" }}>
                By continuing you agree to bunkbread's
                <Button
                  style={{ fontSize: "10px", padding: "0 4px" }}
                  size="small"
                  color="primary"
                  onClick={this.handleTOU}
                >
                  TERMS & CONDITIONS
                </Button>
                ,
                <Button
                  style={{ fontSize: "10px", padding: "0 0px" }}
                  size="small"
                  color="primary"
                  onClick={this.handlePP}
                >
                  PRIVACY POLICY
                </Button>
                and
                <Button
                  style={{ fontSize: "10px", padding: "0 4px" }}
                  size="small"
                  color="primary"
                  onClick={this.handleCR}
                >
                  CANCELLATION & REFUND POLICY
                </Button>
              </p>
            </FormGroup>
          </Box>
        </div>
        <div id="captcha"></div>
        {/* <Footer  Workpad_none /> */}
        {/* <Footer bottom /> */}
        <Dialog
          className="GuestDetails"
          open={this.state.TOUopen}
          onClose={this.handleTOUClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => this.setState({ TOUopen: false })}
          />
          <DialogTitle
            style={{ padding: "20px 8px 0" }}
            id="alert-dialog-title"
          >
            <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
              TERMS & CONDITIONS
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "8px" }} component="div">
            <DialogContentText
              style={{ fontSize: "12px" }}
              component="div"
              id="alert-dialog-description"
            >
              <TOU />
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          className="GuestDetails"
          open={this.state.PPopen}
          onClose={this.handlePPClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => this.setState({ PPopen: false })}
          />
          <DialogTitle
            style={{ padding: "20px 8px 0" }}
            id="alert-dialog-title"
          >
            <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
              PRIVACY POLICY
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "8px" }} component="div">
            <DialogContentText
              style={{ fontSize: "12px" }}
              component="div"
              id="alert-dialog-description"
            >
              <PP />
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          className="GuestDetails"
          open={this.state.CRopen}
          onClose={this.handleCRClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => this.setState({ CRopen: false })}
          />
          <DialogTitle
            style={{ padding: "20px 8px 0" }}
            id="alert-dialog-title"
          >
            <Typography style={{ fontFamily: "Poppins", fontWeight: "600" }}>
              CANCELLATION & REFUND POLICY
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "8px" }} component="div">
            <DialogContentText
              style={{ fontSize: "12px" }}
              component="div"
              id="alert-dialog-description"
            >
              <CR />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/* </Backdrop> */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {
  sendemailotp,
  createaccountemail,
  chckdatabse,
})(Login2);
